import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}

// Filter Partner and Dropdown Partner
export function setFilterPartnersInProgress(state) {
    state.filterPartners = {
        __loading__: true,
        __error__: null
    };
}
export function setFilterPartners(state, filterPartners) {
    state.filterPartners = {
        filterPartners,
        __loading__: false,
        __error__: null
    };
}
export function setFilterPartnersFailure(state, error) {
    state.filterPartners = {
        __loading__: false,
        __error__: error
    };
}

// Filter DeliveryMethods
export function setFilterDeliveryMethodsInProgress(state) {
    state.filterDeliveryMethods = {
        __loading__: true,
        __error__: null
    };
}
export function setFilterDeliveryMethods(state, filterDeliveryMethods) {
    state.filterDeliveryMethods = {
        filterDeliveryMethods,
        __loading__: false,
        __error__: null
    };
}
export function setFilterDeliveryMethodsFailure(state, error) {
    state.filterDeliveryMethods = {
        __loading__: false,
        __error__: error
    };
}

// Dropdown DeliveryMethods
export function setDropDownDeliveryMethodsInProgress(state) {
    state.dropDownDeliveryMethods = {
        __loading__: true,
        __error__: null
    };
}
export function setDropDownDeliveryMethods(state, dropDownDeliveryMethods) {
    state.dropDownDeliveryMethods = {
        dropDownDeliveryMethods,
        __loading__: false,
        __error__: null
    };
}
export function setDropDownDeliveryMethodsFailure(state, error) {
    state.dropDownDeliveryMethods = {
        __loading__: false,
        __error__: error
    };
}

// schedule list
export function setSchedulesInProgress(state) {
    state.schedules = {
        __loading__: true,
        __error__: null
    };
}
export function setSchedules(state, schedules) {
    state.schedules = {
        schedules,
        __loading__: false,
        __error__: null
    };
}
export function setSchedulesFailure(state, error) {
    state.schedules = {
        __loading__: false,
        __error__: error
    };
}

// schedule file list
export function setScheduleFilesInProgress(state) {
    state.scheduleFiles = {
        __loading__: true,
        __error__: null
    };
}
export function setScheduleFiles(state, scheduleFiles) {
    state.scheduleFiles = {
        scheduleFiles,
        __loading__: false,
        __error__: null
    };
}
export function setScheduleFilesFailure(state, error) {
    state.scheduleFiles = {
        __loading__: false,
        __error__: error
    };
}


// edit schedule 
export function setEditSchedulesInProgress(state) {
    state.editSchedules = {
        __loading__: true,
        __error__: null
    };
}
export function setEditSchedules(state, editSchedules) {
    state.editSchedules = {
        editSchedules,
        __loading__: false,
        __error__: null
    };
}
export function setEditSchedulesFailure(state, error) {
    state.editSchedules = {
        __loading__: false,
        __error__: error
    };
}

// Dropdown DataFeeds
export function setDropDownDataFeedsInProgress(state) {
    state.dropDownDataFeeds = {
        __loading__: true,
        __error__: null
    };
}
export function setDropDownDataFeeds(state, dropDownDataFeeds) {
    state.dropDownDataFeeds = {
        dropDownDataFeeds,
        __loading__: false,
        __error__: null
    };
}
export function setDropDownDataFeedsFailure(state, error) {
    state.dropDownDataFeeds = {
        __loading__: false,
        __error__: error
    };
}

// enable schedule 
export function setEnableSchedulesInProgress(state) {
    state.enableSchedules = {
        __loading__: true,
        __error__: null
    };
}
export function setEnableSchedules(state, enableSchedules) {
    state.enableSchedules = {
        enableSchedules,
        __loading__: false,
        __error__: null
    };
}
export function setEnableSchedulesFailure(state, error) {
    state.enableSchedules = {
        __loading__: false,
        __error__: error
    };
}

// save schedule 
export function setSaveSchedulesInProgress(state) {
    state.saveSchedules = {
        __loading__: true,
        __error__: null
    };
}
export function setSaveSchedules(state, saveSchedules) {
    state.saveSchedules = {
        saveSchedules,
        __loading__: false,
        __error__: null
    };
}
export function setSaveSchedulesFailure(state, error) {
    state.saveSchedules = {
        __loading__: false,
        __error__: error
    };
}

// delete schedule 
export function setDeleteSchedulesInProgress(state) {
    state.deleteSchedules = {
        __loading__: true,
        __error__: null
    };
}
export function setDeleteSchedules(state, deleteSchedules) {
    state.deleteSchedules = {
        deleteSchedules,
        __loading__: false,
        __error__: null
    };
}
export function setDeleteSchedulesFailure(state, error) {
    state.deleteSchedules = {
        __loading__: false,
        __error__: error
    };
}

// Run Schedule
export function setRunSchedulesInProgress(state) {
    state.runSchedule = {
        __loading__: true,
        __error__: null
    };
}
export function setRunSchedules(state, runSchedule) {
    state.runSchedule = {
        runSchedule,
        __loading__: false,
        __error__: null
    };
}
export function setRunSchedulesFailure(state, error) {
    state.runSchedule = {
        __loading__: false,
        __error__: error
    };
}

// Download Schedule
export function setDownloadSchedulesInProgress(state) {
    state.downloadSchedule = {
        __loading__: true,
        __error__: null
    };
}
export function setDownloadSchedules(state, downloadSchedule) {
    state.downloadSchedule = {
        downloadSchedule,
        __loading__: false,
        __error__: null
    };
}
export function setDownloadSchedulesFailure(state, error) {
    state.downloadSchedule = {
        __loading__: false,
        __error__: error
    };
}

