import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}
export function setCompletedTaskListInProgress(state) {
    state.completedTaskDetails = {
        __loading__: true,
        __error__: null
    };
}
export function setCompletedTaskList(state, completedTaskDetails) {
    state.completedTaskDetails = {
        completedTaskDetails,
        __loading__: false,
        __error__: null
    };
}
export function setCompletedTaskListFailure(state, error) {
    state.completedTaskDetails = {
        __loading__: false,
        __error__: error
    };
}

export function setCompletedTaskWorkFlowsInProgress(state) {
    state.completedTaskWorkFlows = {
        __loading__: true,
        __error__: null
    };
}
export function setCompletedTaskWorkFlows(state, completedTaskWorkFlows) {
    state.completedTaskWorkFlows = {
        completedTaskWorkFlows,
        __loading__: false,
        __error__: null
    };
}
export function setCompletedTaskWorkFlowsFailure(state, error) {
    state.completedTaskWorkFlows = {
        __loading__: false,
        __error__: error
    };
}

export function setCompletedTaskWorkFlowStepsInProgress(state) {
    state.completedTaskWorkFlowSteps = {
        __loading__: true,
        __error__: null
    };
}
export function setCompletedTaskWorkFlowSteps(state, completedTaskWorkFlowSteps) {
    state.completedTaskWorkFlowSteps = {
        completedTaskWorkFlowSteps,
        __loading__: false,
        __error__: null
    };
}
export function setCompletedTaskWorkFlowStepsFailure(state, error) {
    state.completedTaskWorkFlowSteps = {
        __loading__: false,
        __error__: error
    };
}

// filter workflow list
export function setFilterWorkflowInProgress(state) {
    state.filterWorkflow = {
        __loading__: true,
        __error__: null
    };
}
export function setFilterWorkflow(state, filterWorkflow) {
    state.filterWorkflow = {
        filterWorkflow,
        __loading__: false,
        __error__: null
    };
}
export function setFilterWorkflowFailure(state, error) {
    state.filterWorkflow = {
        __loading__: false,
        __error__: error
    };
}
