import config from "@/config/config.api";
import { USER_MUTATIONS } from './constants';
import axiosCall from "@/api/httpClient.js";
const httpClientCore = axiosCall(process.env.VUE_APP_BASE_URL);
const httpClientCoreFileDownload = axiosCall(process.env.VUE_APP_BASE_URL, 'blob');

// fetch user list
export async function fetchUsersList({ commit }, {
    data,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(USER_MUTATIONS.SET_USERS_IN_PROGRESS);
        }
        const response = await httpClientCore
        .get(config.USERS_getUser,{
            params: {
                page: data.page,
                pageSize: data.pageSize,
                searchByUser: data.searchByUser,
                fromAccountCreationDate: data.fromAccountCreationDate,
                toAccountCreationDate: data.toAccountCreationDate,
                roles: data.roles,
                status: data.status,
                orderByColumn: data.orderByColumn,
                isAscending: data.isAscending,
             },
        })
        commit(USER_MUTATIONS.SET_USERS_COMPLETE, response.data);
    } catch (error) {
        commit(USER_MUTATIONS.SET_USERS_ERROR, error.response.data);
    }
}


export async function fetchUserRoles({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(USER_MUTATIONS.SET_USER_ROLES_IN_PROGRESS);
        }
        const response = await httpClientCore
        .get(config.USERS_getUserRoles)
        commit(USER_MUTATIONS.SET_USER_ROLES_COMPLETE, response.data);
    } catch (error) {
        commit(USER_MUTATIONS.SET_USER_ROLES_ERROR, error.response.data);
    }
}

// for edit user
export async function fetchEditUser({ commit }, {
    userId,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(USER_MUTATIONS.SET_EDIT_USERS_IN_PROGRESS);
        }
        const response = await httpClientCore
        .get(config.USERS_editUser, {
            params: {
                userId : userId,
             },
        })
        commit(USER_MUTATIONS.SET_EDIT_USERS_COMPLETE, response.data);
    } catch (error) {
        commit(USER_MUTATIONS.SET_EDIT_USERS_ERROR, error.response.data);
    }
}

// for create user
export async function fetchSaveUser({ commit }, {
    data,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(USER_MUTATIONS.SET_SAVE_USERS_IN_PROGRESS);
        }
        const response = await httpClientCore
        .post(config.USERS_createUser,data)
        commit(USER_MUTATIONS.SET_SAVE_USERS_COMPLETE, response.data);
    } catch (error) {
        commit(USER_MUTATIONS.SET_SAVE_USERS_ERROR, error.response.data);
    }
}

// for update user
export async function fetchUpdateUser({ commit }, {
    data,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(USER_MUTATIONS.SET_UPDATE_USERS_IN_PROGRESS);
        }
        const response = await httpClientCore
        .post(config.USERS_updateUser,data)
        commit(USER_MUTATIONS.SET_UPDATE_USERS_COMPLETE, response.data);
    } catch (error) {
        commit(USER_MUTATIONS.SET_UPDATE_USERS_ERROR, error.response.data);
    }
}

// exportUserList
export async function fetchExportUsersList({ commit }, {
    data,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(USER_MUTATIONS.SET_EXPORT_USERS_IN_PROGRESS);
        }
        const response = await httpClientCoreFileDownload
        .get(config.USERS_exportUser,{
            params: {
                page: data.page,
                pageSize: data.pageSize,
                searchByUser: data.searchByUser,
                fromAccountCreationDate: data.fromAccountCreationDate,
                toAccountCreationDate: data.toAccountCreationDate,
                roles: data.roles,
                status: data.status,
                orderByColumn: data.orderByColumn,
                isAscending: data.isAscending,
             },
        })
        commit(USER_MUTATIONS.SET_EXPORT_USERS_COMPLETE, response);
    } catch (error) {
        commit(USER_MUTATIONS.SET_EXPORT_USERS_ERROR, error.response.data);
    }
}

// for sendInvite
export async function sendMailInvite({ commit }, {
    email, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(USER_MUTATIONS.SEND_MAIL_INVITE_IN_PROGRESS);
        }
        const response = await httpClientCore.get(config.sendInvite, {
            params: { email }
        })
        commit(USER_MUTATIONS.SEND_MAIL_INVITE_COMPLETE, response.data);
    } catch (error) {
        const err = error.response.data.errors ? error.response.data.errors.email[0] : error.response.data;
        console.log(err)
        commit(USER_MUTATIONS.SEND_MAIL_INVITE_ERROR, err);
    }
}

