// import request from '../../api/httpRequest';
// import configuration from '../../config/config.api';
import { getDatapoints } from "../../services/bulk_edit_service.js";
import {
  getUniverseData,
  getSaveUniverse,
  getEntityData,
  getReportData,
  getTempDataPointListData,
  getDataSetData,
  getSaveDataSet,
  getEditUniverseData,
  getDataSetDataPointListData,
  getOwnerDataSets,
  shareDataSetInformation,
  getOwnerUniverse,
  getStatuses,
  getReportOwner,
  shareTemplateInformation,
  shareUniverseInformation,
} from "../../services/reporting_service";
import { getLanguagesDetails } from "../../services/add_investment_service.js";

const namespaced = true;
const state = {
  universes: [],
  entity: [],
  DynamicDataPoint: [],
  SaveUniverse: [],
  templates: [],
  reports: [],
  tempDataPointList: [],
  dataSets: [],
  languages: [],
  SaveDataSet: [],
  editGetUniverse:[],
  dataSetDataPointList:[],
  EditDataSet:[],
  datasetOwnerList:[],
  universeOwnerList:[],
  deleteSelectedItems:[],
  selectedEntity: [],
  setSelectedDataSetId: 0,
  sharedDataSetInformation:[],
  statusList:[],
  reportOwner:[],
  sharedTemplateInformation:[],
  sharedUniverseInformation:[],
  finalSelectedEntity: {
    "version": 2.0,
    "vendor_name": "",
    "template_name": "",
    "file": {
      "name": "",
      "type": "excel",
      "delimeter": ",",
      "replace_delimeter": ",",
      "multivalue_delimeter": "|",
      "multi_headers": false,
      "encoding": "UTF-8",
      "export_path": ""
    },
    "universe": {
      "test": "",
      "final": ""
    },
    "format": {
      "blank_na": true,
      "decimal_roundoff": 9,
      "date_format": "dd/MM/yyyy"
    },
    "data":[],
    "template_datalist": []
  },
};

const getters = {
  getUniverses(stateData) {
    return stateData.universes;
  },
  getEntity(stateData) {
    return stateData.entity;
  },

  // dynamic universe

  getDynamicDataPoint(stateData) {
    return stateData.DynamicDataPoint;
  },
  getSaveUniverse(stateData) {
    return stateData.SaveUniverse;
  },
  getReport(stateData) {
    return stateData.reports;
  },
  getTempDataPointList(stateData) {
    return stateData.tempDataPointList;
  },
  // data-set
  getDataSets(stateData) {
    return stateData.dataSets;
  },
  getLanguages(stateData) {
    return stateData.languages;
  },
  getSaveDataSet(stateData) {
    return stateData.SaveDataSet;
  },
  getEditUniverseList(stateData) {
    return stateData.editGetUniverse;
  },
  getDataSetDataPointList(stateData) {
    return stateData.dataSetDataPointList;
  },
  getOwnerDataSet(stateData){
    return stateData.datasetOwnerList;
  },
  getShareDataSetInformation(stateData){
    return stateData.sharedDataSetInformation;
  },
  getOwnersUniverse(stateData){
    return stateData.universeOwnerList;
  },
  getStatusList(stateData){
    return stateData.statusList;
  },
  getOwnerReport(stateData){
    return stateData.reportOwner;
  },
  getShareUniverseInformation(stateData){
    return stateData.sharedUniverseInformation;
  },
  getShareTemplateInformation(stateData){
    return stateData.sharedTemplateInformation;
  },

};

const mutations = {
  setUniverses(state, data) {
    state.universes = data.data;
  },
  setEntity(state, data) {
    state.entity = data.data;
  },
  setSelectedEntity(state, data) {
    state.selectedEntity = data;
  },
  setSelectedDataSetId(state, data) {
    state.setSelectedDataSetId = data;
  },
  saveSelectedEntity(state, data, template_datalist) {
    const finalObj = state.finalSelectedEntity;
    state.finalSelectedEntity = {
      ...finalObj,
      ...data,
      ...template_datalist
    }
  },

  // dynamic Universe

  setDynamicDataPoint(state, data) {
    state.DynamicDataPoint = data;
  },
  setSaveUniverse(state, data) {
    state.SaveUniverse = data;
  },
  setShareUniverseInformation(state, data) {
    state.sharedUniverseInformation = data;
  },
  setReport(state, data) {
    state.reports = data;
  },
  setTempDataPointList(state, data) {
    state.tempDataPointList = data;
  },
  // data-set
  setDataSets(state, data) {
    state.dataSets = data;
  },
  setLanguages(state, data) {
    state.languages = data;
  },
  setSaveDataSet(state, data) {
    state.SaveDataSet = data;
  },
  setShareDataSetInformation(state, data) {
    state.sharedDataSetInformation = data;
  },

  setEditUniverseList(state, data) {
    state.editGetUniverse = data;
  },
  setDataSetDataPointList(state, data) {
    state.dataSetDataPointList = data;
  },
  setOwnerDataSet(state, data) {
    state.datasetOwnerList = data;
  },
  setOwnersUniverse(state, data) {
    state.universeOwnerList = data;
  },
  setSelectedItemstoDelete(state, data) {
    state.deleteSelectedItems = data;
  },
  setStatusList(state, data) {
    state.statusList = data;
  },
  setOwnerReport(state, data) {
    state.reportOwner = data;
  },
  
  setShareTemplateInformation(state, data) {
    state.sharedTemplateInformation = data;
  },

};

const actions = {
  async fetchUniversesList(context, param) {
    try {
      let response = await getUniverseData(param);
      context.commit("setUniverses", response);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchEntityList(context, param) {
    try {
      let response = await getEntityData(param);
      context.commit("setEntity", response);
    } catch (error) {
      console.log(error);
    }
  },

  // Dynamic

  async fetchDynamicDataPoint({ commit }, param) {
    try {
      let DynamicDataPoint = await getDatapoints(param);
      commit("setDynamicDataPoint", DynamicDataPoint.data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchSaveUniverse({ commit }, param) {
    try {
      let SaveUniverse = await getSaveUniverse(param);
      commit("setSaveUniverse", SaveUniverse);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchReportList(context, param) {
    try {
      let response = await getReportData(param);
      context.commit("setReport", response);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchTemplateDataPonitList(context, param) {
    try {
      let response = await getTempDataPointListData(param);
      context.commit("setTempDataPointList", response);
    } catch (error) {
      console.log(error);
    }
  },

  // data-set
  async fetchDataSetsList(context, param) {
    try {
      let response = await getDataSetData(param);
      context.commit("setDataSets", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchLanguageDetails({ commit }) {
    try {
      const response = await getLanguagesDetails();
      commit("setLanguages", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  
  async fetchSaveDataSet({ commit }, param) {
    try {
      let EditDataSet = await getSaveDataSet(param);
      commit("setSaveDataSet", EditDataSet);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchDataSetDataPonitList(context, param) {
    try {
      let dataSetDataPointList = await getDataSetDataPointListData(param);
      context.commit("setDataSetDataPointList", dataSetDataPointList);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOwnerDataSet({ commit }) {
    try {
      let datasetOwnerList = await getOwnerDataSets();
      commit("setOwnerDataSet", datasetOwnerList);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchEditUniverseList(context, param) {
    try {
      let response = await getEditUniverseData(param);
      context.commit("setEditUniverseList", response);
    } catch (error) {
      console.log(error);
    }
  },



  async fetchOwnerUniverse({ commit }) {
    try {
      let universeOwnerList = await getOwnerUniverse();
      commit("setOwnersUniverse", universeOwnerList);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchSharedDataSetInformation(context, param) {
    try {
      let sharedDataSetInformation = await shareDataSetInformation(param);
      context.commit("setShareDataSetInformation", sharedDataSetInformation);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSharedTemplateInformation(context, param) {
    try {
      let sharedTemplateInformation = await shareTemplateInformation(param);
      context.commit("setShareTemplateInformation", sharedTemplateInformation);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSharedUniverseInformation(context, param) {
    try {
      let sharedUniverseInformation = await shareUniverseInformation(param);
      context.commit("setShareUniverseInformation", sharedUniverseInformation);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchStatusList({ commit }) {
    try {
      const response = await getStatuses();
      commit("setStatusList", response);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchOwnerReport({ commit }) {
    try {
      let reportOwnerList = await getReportOwner();
      commit("setOwnerReport", reportOwnerList);
    } catch (error) {
      console.log(error);
    }
  },

};

export default {
  namespace: "reporting",
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
