export default function initialState() {
    return {
        fundUniverseMatrix: {
            __loading__: false,
            __error__: null
        },
        workflowHealth: {
            __loading__: false,
            __error__: null
        },
        event: {
            __loading__: false,
            __error__: null
        },
    };
}
