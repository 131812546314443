import { getImportHistory, getFile, getListOfTemplates, getTemplate, getDatapoints, getEntityListData, getDatapointValues,getChildEntityListData } from '../../services/bulk_edit_service.js';
import request from '../../api/httpRequest';
import config from '../../config/config.api';

const namespaced = true
const state = {
  selectedEntityForFilter:'',
  entityList: [],
  childEntityList:[],
  importHistory: [],
  firstItem:1,
  lastItem:10,
  pageNumber: 0,
  pageSize: 0,
  orderby: '',
  isAscending: false,
  file: [],
  fileId: 0,
  showCustomTemplate: false,
  templateList: [],
  template: [],
  templateId: 0,
  entityTypeId: null,
  selectedEntities: [],
  checkedEntities:[],

  // data points modal
  listOfDatapoints: [],
  addedToGrid: [],
  selectedTemplateId: 0,
  selectedDatapoints: [],
  modifiedDataPoints: [],
  modifiedGroupDatapoints: [],
  selectedGroupDatapointId: 0,
  addedToGridGroupDatapointId: 0,

  // DataPoint Values For Grid
  datapointValuesForGrid: [],
}

const getters = {
  getCheckedEntites(state){
    return state.checkedEntities
  },
  getFirstItem(state){
    return state.firstItem
  },
  getLastItem(state){
    return state.lastItem
  },
  getSelectedFilterEntity(state){
    return state.selectedEntityForFilter
  },
  getEntities(state) {
    return state.entityList
  },
  getChildEntities(state){
    return state.childEntityList
  },
  getPageNumber(state) {
    return state.pageNumber;
  },

  getPageSize(state) {
    return state.pageSize;
  },

  getOrderby(state) {
    return state.orderby;
  },

  getIsAscending(state) {
    return state.isAscending;
  },

  getImportHistory(state) {
    return state.importHistory;
  },

  getFileId(state) {
    return state.fileId;
  },

  getFile(state) {
    return state.file;
  },

  getShowCustomTemplateOption(state) {
    return state.showCustomTemplate;
  },

  getTemplatesList(state) {
    return state.templateList;
  },

  getTemplateId(state) {
    return state.templateId;
  },

  getTemplate(state) {
    return state.template;
  },

  // data points modal
  getEntityTypeId(state) {
    return state.entityTypeId;
  },

  getListOfDatapoints(state) {
    return state.listOfDatapoints;
  },

  getDatapointsValuesForGrid(state) {
    return state.datapointValuesForGrid
  },

  getSelectedDatapoints(state) {
    return state.selectedDatapoints;
  },

  getAddedToGridDatapoints(state) {
    return state.addedToGrid;
  },

  getModifiedDataPoints(state) {
    return state.modifiedDataPoints;
  },

  getModifiedGroupDatapoints(state) {
    return state.modifiedGroupDatapoints;
  },

  getSelectedGroupDatapointId(state) {
    return state.selectedGroupDatapointId;
  },

  getAddedToGridGroupDatapointId(state) {
    return state.addedToGridGroupDatapointId;
  },
  getSelectedEntities(state){
    return state.selectedEntities
  },
  getSelectedEntityType(state){
    return state.entityTypeId
  },
  getSelectedTemplateId(state){
    return state.selectedTemplateId
  }
}

const mutations = {
  setCheckedEntites(state,payload){
   state.checkedEntities.push(payload)
  },
  removeCheckedEntites(state,payload){
    state.checkedEntities.splice(payload,1)
   },
   emptyCheckedEntites(state,payload){
    state.checkedEntities=payload
   },
  setFirstItem(state,value){
   state.firstItem = value
  },
  setLastItem(state,value){
   state.lastItem = value
  },
  setSelectedFilterEntity(state,value){
     state.selectedEntityForFilter=value
  },
  setSelectedEntity(state, payload) {
    state.selectedEntities = payload;
  },
  setSelectedEntityType(state, payload) {
    state.entityTypeId = payload;
  },
  setEntityData(state, payload) {
    state.entityList = payload;
  },
  setChildEntityData(state, payload) {
    state.childEntityList = payload;
  },
  setPageNumber(state, pageNumber) {
    state.pageNumber = pageNumber;
  },

  setPageSize(state, pageSize) {
    state.pageSize = pageSize;
  },

  setOrderby(state, orderby) {
    state.orderby = orderby;
  },

  setIsAscending(state, isAscending) {
    state.isAscending = isAscending;
  },

  setImportHistory(state, data) {
    state.importHistory = data;
  },

  setFileId(state, fileId) {
    state.fileId = fileId;
  },

  setFileToNull(state) {
    state.file = [];
  },

  setFileDownload(state, data) {
    state.file = data;
  },

  setShowCustomTemplateOption(state, data) {
    state.showCustomTemplate = data;
  },

  setListOfTemplates(state, data) {
    state.templateList = data;
  },

  setTemplateId(state, templateId) {
    state.templateId = templateId;
  },

  setTemplateDownload(state, data) {
    state.template = data;
  },

  setTemplateToNull(state) {
    state.template = [];
  },

  // data points modal
  setListOfDatapoints(state, data) {
    state.listOfDatapoints = data;
  },

  setSelectedTemplateId(state, data) {
    state.selectedTemplateId = data;
  },

  setSelectedDatapoints(state, data) {
    state.selectedDatapoints = data;
  },

  setAddedToGridDatapoints(state, data) {
    state.addedToGrid = data;
  },

  setModifiedDataPoints(state, data) {
    state.modifiedDataPoints = data;
  },

  setModifiedGroupDatapoints(state, data) {
    state.modifiedGroupDatapoints = data;
  },

  setSelectedGroupDatapointId(state, data) {
    state.selectedGroupDatapointId = data;
  },

  setAddedToGridGroupDatapointId(state, data) {
    state.addedToGridGroupDatapointId = data;
  },

  // Datapoint Values For Grid
  setDatapointsValuesForGrid(state, data) {
    state.datapointValuesForGrid = data;
  },
}

function getEntityList({ VUE_APP_BASE_URL_LOCAL }, commit, param) {
  let query = param ?`?entitytypeid=${param}` : '';
  return request(VUE_APP_BASE_URL_LOCAL + config.Bulk_Edit_getEntityList + query)
    .then(list => {
      commit('setEntityData', list);
      return list;
    })
    .catch(console.error);
}

const actions = {
  setSelectedEntityData({ commit }, { entities, type }) {
    commit('setSelectedEntityType', type);
    commit('setSelectedEntity', entities);
    return;
  },
  getEntityList({ rootState: { config/*, bulkEdit*/ }, commit }, param) {
    // if (bulkEdit.entityList.length !== 0 && !param) {
    //   return bulkEdit.entityList;
    // }
    return getEntityList(config, commit, param);
  },
  async fetchEntityList(context, param) {
    // if (bulkEdit.entityList.length !== 0 && !param) {
    //   return bulkEdit.entityList;
    // }
    try {
      let response = await getEntityListData(
        context.getters.getPageNumber,
        context.getters.getPageSize,
        context.getters.getOrderby,
        context.getters.getIsAscending,
        param);
      context.commit('setEntityData', response);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchChildEntityList({commit}, param) {
    // if (bulkEdit.entityList.length !== 0 && !param) {
    //   return bulkEdit.entityList;
    // }
    try {
      let response = await getChildEntityListData(param);
      commit('setChildEntityData', response);
    } catch (error) {
      console.log(error);
    }
  },
  async setImportHistory(context) {
    try {
      let response = await getImportHistory(
        context.getters.getPageNumber,
        context.getters.getPageSize,
        context.getters.getOrderby,
        context.getters.getIsAscending,
      );
      context.commit('setImportHistory', response);
    } catch (error) {
      console.log(error);
    }
  },

  setPageNumber(context, page) {
    context.commit('setPageNumber', page);
  },

  setPageSize(context, pageSize) {
    context.commit('setPageSize', pageSize);
  },

  async setFileDownload(context) {
    try {
      let response = await getFile(context.getters.getFileId);
      context.commit('setFileDownload', response);
    } catch (error) {
      console.log(error);
    }
  },

  setFileId(context, fileId) {
    context.commit('setFileId', fileId);
  },

  async setListOfTemplates(context) {
    try {
      let response = await getListOfTemplates(context.getters.getShowCustomTemplateOption);
      context.commit('setListOfTemplates', response.data)
    } catch (error) {
      console.log(error);
    }
  },

  async setTemplateDownload(context) {
    try {
      let response = await getTemplate(context.getters.getTemplateId);
      context.commit('setTemplateDownload', response);
    } catch (error) {
      console.log(error);
    }
  },

  // data points modal
  async setListOfDatapoints(context) {
    try {
      let response = await getDatapoints(context.getters.getEntityTypeId);
      context.commit("setListOfDatapoints", response.data);
    } catch (error) {
      console.log(error);
    }
  },


  // data points values For Grid Table
  async fetchDatapointsValuesForGrid(context, payload) {
    try {
      let response = await getDatapointValues(payload);
      context.commit("setDatapointsValuesForGrid", response.data);
    } catch (error) {
      console.log(error);
    }
  }

}

export default {
  namespace: 'bulkEdit',
  namespaced,
  state,
  getters,
  mutations,
  actions
}