import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}

// users List
export function setUsersInProgress(state) {
    state.users = {
        __loading__: true,
        __error__: null
    };
}
export function setUsers(state, users) {
    state.users = {
        users,
        __loading__: false,
        __error__: null
    };
}
export function setUsersFailure(state, error) {
    state.users = {
        __loading__: false,
        __error__: error
    };
}

// user roles 
export function setUserRolesInProgress(state) {
    state.userRoles = {
        __loading__: true,
        __error__: null
    };
}
export function setUserRoles(state, roles) {
    state.userRoles = {
        roles,
        __loading__: false,
        __error__: null
    };
}
export function setUserRolesFailure(state, error) {
    state.userRoles = {
        __loading__: false,
        __error__: error
    };
}


// Edit User
export function setEditUsersInProgress(state) {
    state.editUsers = {
        __loading__: true,
        __error__: null
    };
}
export function setEditUsers(state, editUsers) {
    state.editUsers = {
        editUsers,
        __loading__: false,
        __error__: null
    };
}
export function setEditUsersFailure(state, error) {
    state.editUsers = {
        __loading__: false,
        __error__: error
    };
}


// Save User
export function setSaveUsersInProgress(state) {
    state.saveUsers = {
        __loading__: true,
        __error__: null
    };
}
export function setSaveUsers(state, saveUsers) {
    state.saveUsers = {
        saveUsers,
        __loading__: false,
        __error__: null
    };
}
export function setSaveUsersFailure(state, error) {
    state.saveUsers = {
        __loading__: false,
        __error__: error
    };
}


// update user
export function setUpdateUsersInProgress(state) {
    state.updateUsers = {
        __loading__: true,
        __error__: null
    };
}
export function setUpdateUsers(state, updateUsers) {
    state.updateUsers = {
        updateUsers,
        __loading__: false,
        __error__: null
    };
}
export function setUpdateUsersFailure(state, error) {
    state.updateUsers = {
        __loading__: false,
        __error__: error
    };
}

// export users List
export function setExportUsersInProgress(state) {
    state.ExportUsers = {
        __loading__: true,
        __error__: null
    };
}
export function setExportUsers(state, ExportUsers) {
    state.ExportUsers = {
        ExportUsers,
        __loading__: false,
        __error__: null
    };
}
export function setExportUsersFailure(state, error) {
    state.ExportUsers = {
        __loading__: false,
        __error__: error
    };
}

// export sendMailInvite
export function sendMailInviteInProgress(state) {
    state.sendMailInvite = {
        __loading__: true,
        __error__: null
    };
}
export function sendMailInviteComplete(state, sendMailInvite) {
    state.sendMailInvite = {
        sendMailInvite,
        __loading__: false,
        __error__: null
    };
}
export function sendMailInviteFailure(state, error) {
    state.sendMailInvite = {
        __loading__: false,
        __error__: error
    };
}


