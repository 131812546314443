import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}
export function setDataModelDatapointsListInProgress(state) {
    state.dataModelDatapointList = {
        __loading__: true,
        __error__: null
    };
}
export function setDataModelDatapointsList(state, dataModelDatapointList) {
    state.dataModelDatapointList = {
        dataModelDatapointList,
        __loading__: false,
        __error__: null
    };
}
export function setDataModelDatapointsListFailure(state, error) {
    state.dataModelDatapointList = {
        __loading__: false,
        __error__: error
    };
}

//Language
export function setDataModelLanguagesListInProgress(state) {
    state.dataModelLanguageList = {
        __loading__: true,
        __error__: null
    };
}
export function setDataModelLanguagesList(state, dataModelLanguageList) {
    state.dataModelLanguageList = {
        dataModelLanguageList,
        __loading__: false,
        __error__: null
    };
}
export function setDataModelLanguagesListFailure(state, error) {
    state.dataModelLanguageList = {
        __loading__: false,
        __error__: error
    };
}

//Data Type
export function setDataModelDataTypesListInProgress(state) {
    state.dataModelDataTypeList = {
        __loading__: true,
        __error__: null
    };
}
export function setDataModelDataTypesList(state, dataModelDataTypeList) {
    state.dataModelDataTypeList = {
        dataModelDataTypeList,
        __loading__: false,
        __error__: null
    };
}
export function setDataModelDataTypesListFailure(state, error) {
    state.dataModelDataTypeList = {
        __loading__: false,
        __error__: error
    };
}

//Datapoint Details
export function setDataModelDatapointDetailsInProgress(state) {
    state.dataModelDatapointDetails = {
        __loading__: true,
        __error__: null
    };
}
export function setDataModelDatapointDetails(state, dataModelDatapointDetails) {
    state.dataModelDatapointDetails = {
        dataModelDatapointDetails,
        __loading__: false,
        __error__: null
    };
}
export function setDataModelDatapointDetailsFailure(state, error) {
    state.dataModelDatapointDetails = {
        __loading__: false,
        __error__: error
    };
}


//Datapoint Available Groups
export function setDataModelAvailableGroupsDetailsInProgress(state) {
    state.dataModelAvailableGroupsList = {
        __loading__: false,
        __error__: null
    };
}

export function setDataModelAvailableGroupsDetails(state, dataModelAvailableGroupsList) {
    state.dataModelAvailableGroupsList = {
        dataModelAvailableGroupsList,
        __loading__: false,
        __error__: null
    };
}

export function setDataModelAvailableGroupsDetailsFailure(state, error) {
    state.dataModelAvailableGroupsList = {
        __loading__: false,
        __error__: error
    };
}

//Enum Types
export function setDataModelEnumTypesListInProgress(state) {
    state.dataModelEnumTypeList = {
        __loading__: true,
        __error__: null
    };
}
export function setDataModelEnumTypesList(state, dataModelEnumTypeList) {
    state.dataModelEnumTypeList = {
        dataModelEnumTypeList,
        __loading__: false,
        __error__: null
    };
}
export function setDataModelEnumTypesListFailure(state, error) {
    state.dataModelEnumTypeList = {
        __loading__: false,
        __error__: error
    };
}

//Reference Types
export function setDataModelReferenceTypesListInProgress(state) {
    state.dataModelReferenceTypeList = {
        __loading__: true,
        __error__: null
    };
}
export function setDataModelReferenceTypesList(state, dataModelReferenceTypeList) {
    state.dataModelReferenceTypeList = {
        dataModelReferenceTypeList,
        __loading__: false,
        __error__: null
    };
}
export function setDataModelReferenceTypesListFailure(state, error) {
    state.dataModelReferenceTypeList = {
        __loading__: false,
        __error__: error
    };
}

//Enum Values
export function setDataModelEnumValuesListInProgress(state) {
    state.dataModelEnumValueList = {
        __loading__: true,
        __error__: null
    };
}

export function setDataModelEnumValuesList(state, dataModelEnumValueList) {
    state.dataModelEnumValueList = {
        dataModelEnumValueList,
        __loading__: false,
        __error__: null
    };
}

export function setDataModelEnumValuesListFailure(state, error) {
    state.dataModelEnumValueList = {
        __loading__: false,
        __error__: error
    };
}


//Datapoints From selected Group
export function setDataModelDataPointsFromSelectedGroupDetailsInProgress(state) {
    state.dataModelDataPointListFromSelectedGroup = {
        __loading__: true,
        __error__: null
    };
}

export function setDataModelDataPointsFromSelectedGroupDetails(state, dataModelDataPointListFromSelectedGroup) {
    state.dataModelDataPointListFromSelectedGroup = {
        dataModelDataPointListFromSelectedGroup,
        __loading__: false,
        __error__: null
    };
}

export function setDataModelDataPointsFromSelectedGroupFailure(state, error) {
    state.dataModelDataPointListFromSelectedGroup = {
        __loading__: false,
        __error__: error
    };
}

//Reference Values
export function setDataModelReferenceValuesListInProgress(state) {
    state.dataModelReferenceValueList = {
        __loading__: true,
        __error__: null
    };
}
export function setDataModelReferenceValuesList(state, dataModelReferenceValueList) {
    state.dataModelReferenceValueList = {
        dataModelReferenceValueList,
        __loading__: false,
        __error__: null
    };
}
export function setDataModelReferenceValuesListFailure(state, error) {
    state.dataModelReferenceValueList = {
        __loading__: false,
        __error__: error
    };
}

//Validations Values
export function setDataModelValidationsListInProgress(state) {
    state.dataModelValidationsList = {
        __loading__: true,
        __error__: null
    };
}

export function setDataModelValidationsList(state, dataModelValidationsList) {
    state.dataModelValidationsList = {
        dataModelValidationsList,
        __loading__: false,
        __error__: null
    };
}

export function setDataModelValidationsListFailure(state, error) {
    state.dataModelValidationsList = {
        __loading__: false,
        __error__: error
    };
}

//Save Data Point
export function setDataModelSaveDataPointsInProgress(state) {
    state.dataModelSaveDataPoint = {
        __loading__: true,
        __error__: null
    };
}

export function setDataModelSaveDataPoints(state, dataModelSaveDataPoint) {
    state.dataModelSaveDataPoint = {
        dataModelSaveDataPoint,
        __loading__: false,
        __error__: null
    };
}

export function setDataModelSaveDataPointsFailure(state, error) {
    state.dataModelSaveDataPoint = {
        __loading__: false,
        __error__: error
    };
}

//Delete Data Point
export function setDataModelDeleteDataPointsInProgress(state) {
    state.dataModelDeleteDataPoint = {
        __loading__: true,
        __error__: null
    };
}

export function setDataModelDeleteDataPoints(state, dataModelDeleteDataPoint) {
    state.dataModelDeleteDataPoint = {
        dataModelDeleteDataPoint,
        __loading__: false,
        __error__: null
    };
}

export function setDataModelDeleteDataPointsFailure(state, error) {
    state.dataModelDeleteDataPoint = {
        __loading__: false,
        __error__: error
    };
}

//Search Data Point
export function setDataModelSearchDataPointsInProgress(state) {
    state.dataModelSearchDataPoint = {
        __loading__: true,
        __error__: null
    };
}

export function setDataModelSearchDataPoints(state, dataModelSearchDataPoint) {
    state.dataModelSearchDataPoint = {
        dataModelSearchDataPoint,
        __loading__: false,
        __error__: null
    };
}

export function setDataModelSearchDataPointsFailure(state, error) {
    state.dataModelSearchDataPoint = {
        __loading__: false,
        __error__: error
    };
}
