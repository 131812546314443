export default function initialState() {
    return {
        externalCodesListDetails: {
            __loading__: false,
            __error__: null
        },
        updateExternalCodes:{
            __loading__: false,
            __error__: null
        },
    };
}
