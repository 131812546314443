import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
Vue.use(VueRouter, store);
store.dispatch('setPermissions');

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: () => import('../components/Login.vue')
    },
    {
      path: '/layout',
      name: 'layout',
      component: () => import('../components/Layout.vue'),
      meta: {requiresAuth: true},
      children: [
        {
          path: '/data-management/add-investment',
          name: 'Add Investment',
          component: () => import('../components/standalone_components/AddInvestment.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-management/add-investment/edit-external-codes',
          name: 'Edit External Codes',
          component: () => import('../components/common_components/EditExternalCodes.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-management/entities',
          name: 'Entity List',
          component: () => import('../components/standalone_components/BulkEditEntityList.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/home',
          name: 'Home',
          component: () => import('../components/modules/home/components/Home.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/approve',
          name: 'Approve',
          component: () => import('../components/common_components/ApproveScreen.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/tasks/assigned-to-you',
          name: 'Assigned To You',
          component: () => import('../components/standalone_components/AssignedToYou.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-management/import-data',
          name: 'Bulk Edit',
          component: () => import('../components/standalone_components/BulkEdit.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/universes',
          name: 'Reporting Universe',
          component: () => import('../components/standalone_components/ReportingUniverses.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/universes/create',
          name: 'Reporting Create Universe',
          component: () => import('../components/standalone_components/ReportingCreateUniverse.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/data-sets',
          name: 'Reporting Data Sets',
          component: () => import('../components/standalone_components/ReportingDataSets.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/data-sets/create',
          name: 'Reporting Create Data Set',
          component: () => import('../components/standalone_components/ReportingCreateDataSet.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/templates',
          name: 'Reporting Templates',
          component: () => import('../components/standalone_components/ReportingTemplates.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/reporting/reports',
          name: 'Reporting Reports',
          component: () => import('../components/standalone_components/ReportingReports.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/templates/create',
          name: 'Reporting Create Template',
          component: () => import('../components/standalone_components/ReportingCreateTemplate.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/reporting/reports/run-new-report',
          name: 'Reporting Run Report',
          component: () => import('../components/standalone_components/ReportingCreateReport.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/data-sets/view',
          name: 'Reporting View Data Sets',
          component: () => import('../components/standalone_components/ReportingViewDataSet.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/universes/view',
          name: 'Reporting View Universe',
          component: () => import('../components/standalone_components/ReportingViewUniverse.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/templates/view',
          name: 'Reporting View Template',
          component: () => import('../components/standalone_components/ReportingViewTemplate.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-dissemination/schedules',
          name: 'Data Dissemination Schedule',
          component: () => import('../components/modules/data-dissemination/container/SchedulesContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-dissemination/data-feeds',
          name: 'Data Dissemination Data Feed',
          component: () => import('../components/modules/data-dissemination/container/DataFeedContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-dissemination/data-feeds/create',
          name: 'Data Dissemination Create Data Feed',
          component: () => import('../components/modules/data-dissemination/container/CreateDataFeedContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-dissemination/schedules/create',
          name: 'Data Dissemination Create Schedule',
          component: () => import('../components/modules/data-dissemination/container/CreateSchedulesContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-dissemination/partners',
          name: 'Data Dissemination Partner',
          component: () => import('../components/modules/data-dissemination/container/PartnersContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-dissemination/partners/create',
          name: 'Data Dissemination Create Partner',
          component: () => import('../components/modules/data-dissemination/container/CreatePartnersContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/data-dissemination/schedules/detail',
          name: 'Data Dissemination Create Schedule Detail',
          component: () => import('../components/modules/data-dissemination/container/CreateSchedulesDetailContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/alerts',
          name: 'Settings Alert',
          component: () => import('../components/modules/alerts/container/AlertsContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/alerts/create',
          name: 'Settings Create Alert',
          component: () => import('../components/modules/alerts/container/CreateAlertsContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/tasks/completed',
          name: 'Data Management Tasks',
          component: () => import('../components/modules/tasks/container/TaskCompletedContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/audit/activity-history',
          name: 'Activity History',
          component: () => import('../components/modules/activity-history/container/ActivityHistoryContainer.vue'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/user-management',
          name: 'user management',
          component: () => import('../components/modules/user-management/container/UsersContainer'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/user-management/add-user',
          name: 'user management add user',
          component: () => import('../components/modules/user-management/container/AddNewUserContainer'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/fog/admin',
          name: 'fog-admin',
          component: () => import('../components/modules/fog/admin'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/fog/bulk-edit',
          name: 'fog-bulk-edit',
          component: () => import('../components/modules/fog/bulk-edit'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/fog/data',
          name: 'fog-data',
          component: () => import('../components/modules/fog/data'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/fog/reporting',
          name: 'fog-reporting',
          component: () => import('../components/modules/fog/reporting'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/document-management',
          name: 'document management',
          component: () => import('../components/modules/document-management/container/DocumentManagementContainer'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/settings/data-model',
          name: 'data model',
          component: () => import('../components/modules/data-model/container/DataModelContainer'),
          async beforeEnter(to, from, next) {
              try {
                if(store.state.auth.permissions.includes(spiltURL(to.fullPath)) || store.state.auth.permissions.includes('*')) {
                  next();
                } else {
                  next("unauthorized")
                }
              } catch (err) {
                next(false);
            }
          }
        },
        {
          path: '/unauthorized',
          name: 'unauthorized',
          component: () => import('../components/Unauthorized.vue')
        },
        {
          path: '/:catchAll(.*)',
          name: 'pageNotFound',
          component: () => import('../components/404page.vue')
        },
      ]
    }
    

  ],
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});

// router.beforeEach((to, from, next) => {
//   const loggedIn = window.sessionStorage.getItem('expiry');

//   if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
//     next('/')
//   }
//   next()
// });

router.beforeEach((to, _, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const expiry = window.sessionStorage.getItem('expiry');
    if (expiry && (new Date(expiry) - Date.now()) > 60000) {
      next();
      return;
    }
  } else {
    next();
  }
});

// common function to split the URL as we need only the query part & not query params to compare.
const spiltURL = (queryString) => {
  return queryString.split("?")[0];
}
export default router;
