export default function initialState() {
    return {
        users: {
            __loading__: false,
            __error__: null
        },
        userRoles: {
            __loading__: false,
            __error__: null,
        },
        editUsers: {
            __loading__: false,
            __error__: null
        },
        saveUsers: {
            __loading__: false,
            __error__: null
        },
        updateUsers: {
            __loading__: false,
            __error__: null
        },
        ExportUsers: {
            __loading__: false,
            __error__: null
        },
        sendMailInvite: {
            __loading__: false,
            __error__: null
        },
    };
}
