import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}
// Universe
export function setUniversesInProgress(state) {
    state.universes = {
        __loading__: true,
        __error__: null
    };
}
export function setUniverses(state, universes) {
    state.universes = {
        universes,
        __loading__: false,
        __error__: null
    };
}
export function setUniversesFailure(state, error) {
    state.universes = {
        __loading__: false,
        __error__: error
    };
}
// Templates
export function setTemplatesInProgress(state) {
    state.templates = {
        __loading__: true,
        __error__: null
    };
}
export function setTemplates(state, templates) {
    state.templates = {
        templates,
        __loading__: false,
        __error__: null
    };
}
export function setTemplatesFailure(state, error) {
    state.templates = {
        __loading__: false,
        __error__: error
    };
}
// Templates OwnerList
export function setTemplatesOwnerInProgress(state) {
    state.templateOwnerList = {
        __loading__: true,
        __error__: null
    };
}
export function setTemplatesOwner(state, templateOwnerList) {
    state.templateOwnerList = {
        templateOwnerList,
        __loading__: false,
        __error__: null
    };
}
export function setTemplatesOwnerFailure(state, error) {
    state.templateOwnerList = {
        __loading__: false,
        __error__: error
    };
}
// DataSets
export function setDataSetsInProgress(state) {
    state.dataSets = {
        __loading__: true,
        __error__: null
    };
}
export function setDataSets(state, dataSets) {
    state.dataSets = {
        dataSets,
        __loading__: false,
        __error__: null
    };
}
export function setDataSetsFailure(state, error) {
    state.dataSets = {
        __loading__: false,
        __error__: error
    };
}

// all user list
export function setUserListsInProgress(state) {
    state.userList = {
        __loading__: true,
        __error__: null
    };
}
export function setUserLists(state, userList) {
    state.userList = {
        userList,
        __loading__: false,
        __error__: null
    };
}
export function setUserListsFailure(state, error) {
    state.userList = {
        __loading__: false,
        __error__: error
    };
}

// fetch user status
export function setReportStatusInProgress(state) {
    state.reportStatus = {
        __loading__: true,
        __error__: null
    };
}
export function setReportStatus(state, reportStatus) {
    state.reportStatus = {
        reportStatus,
        __loading__: false,
        __error__: null
    };
}
export function setReportStatusFailure(state, error) {
    state.reportStatus = {
        __loading__: false,
        __error__: error
    };
}