import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}

// fund universe matrix list
export function setFundUniverseMatrixInProgress(state) {
    state.fundUniverseMatrix = {
        __loading__: true,
        __error__: null
    };
}
export function setFundUniverseMatrix(state, fundUniverseMatrix) {
    state.fundUniverseMatrix = {
        fundUniverseMatrix,
        __loading__: false,
        __error__: null
    };
}
export function setFundUniverseMatrixFailure(state, error) {
    state.fundUniverseMatrix = {
        __loading__: false,
        __error__: error
    };
}


// workflow health list
export function setWorkflowHealthInProgress(state) {
    state.workflowHealth = {
        __loading__: true,
        __error__: null
    };
}
export function setWorkflowHealth(state, workflowHealth) {
    state.workflowHealth = {
        workflowHealth,
        __loading__: false,
        __error__: null
    };
}
export function setWorkflowHealthFailure(state, error) {
    state.workflowHealth = {
        __loading__: false,
        __error__: error
    };
}


// event list
export function setEventInProgress(state) {
    state.event = {
        __loading__: true,
        __error__: null
    };
}
export function setEvent(state, event) {
    state.event = {
        event,
        __loading__: false,
        __error__: null
    };
}
export function setEventFailure(state, error) {
    state.event = {
        __loading__: false,
        __error__: error
    };
}
