export const SCHEDULE_ACTIONS = {
    FETCH_FILTER_PARTNER_LIST: 'fetchFilterPartnersList',
    FETCH_FILTER_DELIVERYMETHOD_LIST: 'fetchFilterDeliveryMethodsList',
    FETCH_DROPDOWN_DELIVERYMETHOD_LIST: 'fetchDropDownDeliveryMethodsList',
    FETCH_SCHEDULE_LIST: 'fetchScheduleList',
    FETCH_SCHEDULE_FILE_LIST:'fetchScheduleFileList',
    FETCH_EDIT_SCHEDULE_LIST: 'fetchEditScheduleList',
    FETCH_DROPDOWN_DATAFEED_LIST: 'fetchDropDownDataFeedsList',
    FETCH_ENABLE_SCHEDULE_LIST: 'fetchEnableScheduleList',
    FETCH_SAVE_SCHEDULE_LIST: 'fetchSaveScheduleList',
    FETCH_DELETE_SCHEDULE_LIST: 'fetchDeleteScheduleList',
    RUN_SCHEDULE_LIST: 'runSchedule',
    DOWNLOAD_SCHEDULE_LIST: 'downloadSchedule'

};

export const SCHEDULE_MUTATIONS = {
    SET_FILTER_PARTNERS_IN_PROGRESS: 'setFilterPartnersInProgress',
    SET_FILTER_PARTNERS_ERROR: 'setFilterPartnersFailure',
    SET_FILTER_PARTNERS_COMPLETE: 'setFilterPartners',

    SET_FILTER_DELIVERYMETHODS_IN_PROGRESS: 'setFilterDeliveryMethodsInProgress',
    SET_FILTER_DELIVERYMETHODS_ERROR: 'setFilterDeliveryMethodsFailure',
    SET_FILTER_DELIVERYMETHODS_COMPLETE: 'setFilterDeliveryMethods',

    SET_DROPDOWN_DELIVERYMETHODS_IN_PROGRESS: 'setDropDownDeliveryMethodsInProgress',
    SET_DROPDOWN_DELIVERYMETHODS_ERROR: 'setDropDownDeliveryMethodsFailure',
    SET_DROPDOWN_DELIVERYMETHODS_COMPLETE: 'setDropDownDeliveryMethods',

    SET_SCHEDULES_IN_PROGRESS: 'setSchedulesInProgress',
    SET_SCHEDULES_ERROR: 'setSchedulesFailure',
    SET_SCHEDULES_COMPLETE: 'setSchedules',
    
    SET_SCHEDULEFILES_IN_PROGRESS: 'setScheduleFilesInProgress',
    SET_SCHEDULEFILES_ERROR: 'setScheduleFilesFailure',
    SET_SCHEDULEFILES_COMPLETE: 'setScheduleFiles',

    SET_EDITSCHEDULES_IN_PROGRESS: 'setEditSchedulesInProgress',
    SET_EDITSCHEDULES_ERROR: 'setEditSchedulesFailure',
    SET_EDITSCHEDULES_COMPLETE: 'setEditSchedules',

    SET_DROPDOWN_DATAFEEDS_IN_PROGRESS: 'setDropDownDataFeedsInProgress',
    SET_DROPDOWN_DATAFEEDS_ERROR: 'setDropDownDataFeedsFailure',
    SET_DROPDOWN_DATAFEEDS_COMPLETE: 'setDropDownDataFeeds',
    
    SET_ENABLESCHEDULES_IN_PROGRESS: 'setEnableSchedulesInProgress',
    SET_ENABLESCHEDULES_ERROR: 'setEnableSchedulesFailure',
    SET_ENABLESCHEDULES_COMPLETE: 'setEnableSchedules',

    SET_SAVESCHEDULES_IN_PROGRESS: 'setSaveSchedulesInProgress',
    SET_SAVESCHEDULES_ERROR: 'setSaveSchedulesFailure',
    SET_SAVESCHEDULES_COMPLETE: 'setSaveSchedules',

    SET_DELETESCHEDULES_IN_PROGRESS: 'setDeleteSchedulesInProgress',
    SET_DELETESCHEDULES_ERROR: 'setDeleteSchedulesFailure',
    SET_DELETESCHEDULES_COMPLETE: 'setDeleteSchedules',

    SET_RUNSCHEDULE_IN_PROGRESS: 'setRunSchedulesInProgress',
    SET_RUNSCHEDULE_ERROR: 'setRunSchedulesFailure',
    SET_RUNSCHEDULE_COMPLETE: 'setRunSchedules',

    SET_DOWNLOADSCHEDULE_IN_PROGRESS: 'setDownloadSchedulesInProgress',
    SET_DOWNLOADSCHEDULE_ERROR: 'setDownloadSchedulesFailure',
    SET_DOWNLOADSCHEDULE_COMPLETE: 'setDownloadSchedules',
};
