import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { DATADISSEMINATION_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

export async function fetchFilterUniversesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_FILTER_UNIVERSES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_filterUniverse)
        commit(DATADISSEMINATION_MUTATIONS.SET_FILTER_UNIVERSES_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_FILTER_UNIVERSES_ERROR, error.response.data.message);
    }
}

export async function fetchFilterTemplatesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_FILTER_TEMPLATES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_filterTemplate)
        commit(DATADISSEMINATION_MUTATIONS.SET_FILTER_TEMPLATES_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_FILTER_TEMPLATES_ERROR, error.response.data.message);
    }
}

export async function fetchDropDownUniversesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_DROPDOWN_UNIVERSES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_dropDownUniverse)
        commit(DATADISSEMINATION_MUTATIONS.SET_DROPDOWN_UNIVERSES_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_DROPDOWN_UNIVERSES_ERROR, error.response.data.message);
    }
}

export async function fetchDropDownTemplatesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_DROPDOWN_TEMPLATES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_dropDownTemplate)
        commit(DATADISSEMINATION_MUTATIONS.SET_DROPDOWN_TEMPLATES_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_DROPDOWN_TEMPLATES_ERROR, error.response.data.message);
    }
}

export async function fetchDataFeedsList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_DATAFEEDS_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DATADISSEMINATION_getDataFeed, {
                params: {
                    page: data.page,
                    pageSize: data.pageSize,
                    searchFeed: data.searchFeed,
                    searchUniverse: data.searchUniverse,
                    searchTemplate: data.searchTemplate,
                    orderByColumn: data.orderByColumn,
                    isAscending: data.isAscending,
                },
            });
        commit(DATADISSEMINATION_MUTATIONS.SET_DATAFEEDS_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_DATAFEEDS_ERROR, error.response.data.message);
    }
}

export async function saveDataFeed({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_SAVEDATAFEEDS_IN_PROGRESS);
        }
        const response = await httpClient.post(config.DATADISSEMINATION_saveDataFeed, data);
        commit(DATADISSEMINATION_MUTATIONS.SET_SAVEDATAFEEDS_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_SAVEDATAFEEDS_ERROR, error.response.data.message);
    }
}

export async function updateDataFeed({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_UPDATEDATAFEEDS_IN_PROGRESS);
        }
        const response = await httpClient.put(config.DATADISSEMINATION_updateDataFeed, data);
        commit(DATADISSEMINATION_MUTATIONS.SET_UPDATEDATAFEEDS_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_UPDATEDATAFEEDS_ERROR, error.response.data.message);
    }
}

export async function deleteDataFeed({ commit }, {
    feedIds, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_DELETEDATAFEEDS_IN_PROGRESS);
        }
        const response = await httpClient.delete(config.DATADISSEMINATION_deleteDataFeed,{
            params: {
                feedIds: feedIds 
            },
        });
        commit(DATADISSEMINATION_MUTATIONS.SET_DELETEDATAFEEDS_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_DELETEDATAFEEDS_ERROR, error.response.data.message);
    }
}

export async function getUpdateDataFeed({ commit }, {
    feedId, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATADISSEMINATION_MUTATIONS.SET_GETUPDATEDATAFEEDS_IN_PROGRESS);
        }
        const response = await httpClient.get(config.DATADISSEMINATION_getUpdateDataFeed,{
            params: {
                feedId: feedId 
            },
        });
        commit(DATADISSEMINATION_MUTATIONS.SET_GETUPDATEDATAFEEDS_COMPLETE, response.data);
    } catch (error) {
        commit(DATADISSEMINATION_MUTATIONS.SET_GETUPDATEDATAFEEDS_ERROR, error.response.data.message);
    }
}
