export const PARTNER_ACTIONS = {
    FETCH_PARTNER_LIST: 'fetchPartnerList',
    FETCH_DELETE_PARTNER_LIST: 'fetchDeletePartnerList',
    FETCH_DROPDOWN_DELIVERYTYPE_LIST: 'fetchDropDownDeliveryTypesList',
    FETCH_SAVE_PARTNER_LIST: 'fetchSavePartnerList',
    FETCH_EDIT_PARTNER_LIST: 'fetchEditPartnerList',
    FETCH_UPDATE_PARTNER_LIST: 'fetchUpdatePartnerList',
    FETCH_DROPDOWN_FTPSERVER_LIST: 'fetchDropDownFTPServerList',
};

export const PARTNER_MUTATIONS = {

    SET_PARTNERS_IN_PROGRESS: 'setPartnersInProgress',
    SET_PARTNERS_ERROR: 'setPartnersFailure',
    SET_PARTNERS_COMPLETE: 'setPartners',

    SET_DELETEPARTNERS_IN_PROGRESS: 'setDeletePartnersInProgress',
    SET_DELETEPARTNERS_ERROR: 'setDeletePartnersFailure',
    SET_DELETEPARTNERS_COMPLETE: 'setDeletePartners',

    SET_DROPDOWN_DELIVERYTYPES_IN_PROGRESS: 'setDropDownDeliveryTypesInProgress',
    SET_DROPDOWN_DELIVERYTYPES_ERROR: 'setDropDownDeliveryTypesFailure',
    SET_DROPDOWN_DELIVERYTYPES_COMPLETE: 'setDropDownDeliveryTypes',

    SET_SAVEPARTNERS_IN_PROGRESS: 'setSavePartnersInProgress',
    SET_SAVEPARTNERS_ERROR: 'setSavePartnersFailure',
    SET_SAVEPARTNERS_COMPLETE: 'setSavePartners',

    SET_EDITPARTNERS_IN_PROGRESS: 'setEditPartnersInProgress',
    SET_EDITPARTNERS_ERROR: 'setEditPartnersFailure',
    SET_EDITPARTNERS_COMPLETE: 'setEditPartners',

    SET_UPDATEPARTNERS_IN_PROGRESS: 'setUpdatePartnersInProgress',
    SET_UPDATEPARTNERS_ERROR: 'setUpdatePartnersFailure',
    SET_UPDATEPARTNERS_COMPLETE: 'setUpdatePartners',

    SET_DROPDOWN_FTPSERVERS_IN_PROGRESS: 'setDropDownFTPServersInProgress',
    SET_DROPDOWN_FTPSERVERS_ERROR: 'setDropDownFTPServersFailure',
    SET_DROPDOWN_FTPSERVERS_COMPLETE: 'setDropDownFTPServers',
};


