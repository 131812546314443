import format from 'date-fns/format';
import subDays from 'date-fns/subDays';

export const dateFormatterMixin = {
  methods: {
    convertDate(date) {
      try {
        return format(new Date(date), 'dd-MM-yyyy HH:mm:ss');
      } catch (e) {
        return '\u2014';
      }
    },

    // convert this date (2021-12-03T09:00:00Z) format
    convertUTCDate(date) {
      try {
        let temp = date.split('Z');
        return format(new Date(temp[0]), 'dd-MM-yyyy HH:mm:ss');
      } catch (e) {
        this.convertDate(date);
      }
    },

    convertAdditionalSearchDate(date) {
      try {
        return format(new Date(date), 'dd-MM-yyyy');
      } catch (e) {
        return '\u2014';
      }
    },

    convertReportingParameterDate(date) {
      try {
        return format(new Date(date), 'yyyy-MM-dd');
      } catch (e) {
        return '\u2014';
      }
    },

      // convert dd-MM-yyyy to yyyy-MM-dd 
      reverseTheDate(date) {
        try {
          let [dd, MM, yyyy] = date.split("-");
          return `${yyyy}-${MM}-${dd}`;
        } catch(e) {
          return '\u2014';
        }
      },

    // use this if you want to convert from "2021-04-27T00:20:12.903" to "27-Apr-2021 12:20 AM"
    dateFormWithCharacterMonth(date) {
      let monthsArray = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
      ];
      if (date !== undefined) {
        let splittedDate = date.split('T');
        let splittedDateData = splittedDate[0].split('-');
        let splittedTimeData = splittedDate[1].split(':');

        let hours = splittedTimeData[0] % 12 || 12;
        let minutes = splittedTimeData[1];
        let daylight = splittedTimeData[0] < 12 ? 'AM' : 'PM';
        return `${splittedDateData[2]}-${
          monthsArray[splittedDateData[1] - 1]
        }-${splittedDateData[0]} ${hours}:${minutes} ${daylight}`;
      }
    },

    dateToUTCWithZeroHours(date) {
      let addZeros = date + ' 00:00 UTC';
      let utcDate = new Date(addZeros);
      return utcDate.toISOString().replace('.000Z', '');
    },

    // use this if you want to convert from "2022-01-10T11:50:37.011Z" to "10-01-2022 11:50:37"
    getUTCFormatForCurrentDate() {
      const date = new Date();
      const utcDate = date.toISOString();
      let [splittedDate, splittedTime] = utcDate.split('T');
      let dd = splittedDate.substring(8, 10);
      let mm = splittedDate.substring(5, 7);
      let yyyy = splittedDate.substring(0, 4);
      let time = splittedTime.substring(0, 8);
      return `${dd}-${mm}-${yyyy} ${time}`;
    },

    // use this if you want to get a utc date in ISO string format eg: "2022-01-11T08:54:45.380Z"
    getSubtractedDate(currentDate = new Date(), numberOfDaysToSubtract) {
      return subDays(currentDate, numberOfDaysToSubtract).toISOString();
    },
  },
};
