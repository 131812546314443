// Add Investment
// const ADD_INVESTMENT_getAllEntitiesEndPoint = '/data/index?CreateEntitiesMode=True&LanguageId=en&webapi=1';
// const ADD_INVESTMENT_getEntityByEntityIdEndPoint='/data/index?&expandedTree=True&CreateEntitiesMode=True&LanguageId=en&webapi=1&EntityId=';
const ADD_INVESTMENT_getAllEntitiesEndPoint_check = 'nexusapp/api/v1/investments/entitytreedatapoints';
const ADD_INVESTMENT_getEntityTypes='nexusapp/api/v1/investments/entities/types/';
// ADD_INVESTMENT_Create is used for get and post for parentEntities
const ADD_INVESTMENT_Create='nexusapp/api/v1/investments/entities/';
const ADD_INVESTMENT_Save_Datapoint='/nexusapp/api/v1/investments/datapoints'
const ADD_INVESTMENT_ENTITY_DROPDOWNS='/nexusapp/api/v1/investments/entitycollection'
const ADD_INVESTMENT_getselectforenumid="/nexusapp/api/v1/investments/entities/enumvalues"
const ADD_INVESTMENT_getselectforreferencetypeid="/nexusapp/api/v1/investments/entities/referencevalues"
const ADD_INVESTMENT_getselectforMultipleEnumid="/nexusapp/api/v1/investments/entities/multienumvalues"
const ADD_INVESTMENT_getselectforMultipleReferenceTypeId="/nexusapp/api/v1/investments/entities/multireferencetypevalues"
const ADD_INVESTMENT_DATAPOINT_HISTORY='/nexusapp/api/v1/investments/datapointhistory'
const ADD_INVESTMENT_downloadDatapointHistory='/nexusapp/api/v1/investments/downloaddatappointhistory'
const ADD_INVESTMENT_autoMapping='/nexusapp/api/v1/investments/auto-mappings'

// Edit External Codes
const EXTERNAL_CODES_getAndUpdate="/nexusapp/api/v1/investments/external-code"

// Get Language Details
const GET_LANGUAGES="nexusapp/api/v1/investments/languages"

// Get Country Value text 
const GET_COUNTRY_TEXT="nexusapp/api/v1/investments/country"

// Get Country Value text
const GET_LANGUAGE_TEXT="/nexusapp/api/v1/investments/language"

//AssignedToYou
const ASSIGNED_TO_YOU_getAssignedItemsEndPont = '/nexuscore/api/v1/workflow/wfexecutions/workflow-open-tasks';
const ASSIGNED_TO_YOU_getAssignedTaskDetails='/nexuscore/api/v1/workflow/wfexecutions/workflow-open-tasksdetail';
const ASSIGNED_TO_YOU_submitUserVerdict = '/nexuscore/api/v1/workflow/wfexecutions/submit-user-verdict';
const ASSIGNED_TO_YOU_getTaskDetailsComments='/nexuscore/api/v1/workflow/wfexecutions/step-comments';
const ASSIGNED_TO_YOU_getWorkflowFilterList = '/nexuscore/api/v1/workflow/metadata/application-objects';

//Bulk edit
const Bulk_Edit_getImportHistory = 'nexusapp/api/v1/bulk-operations/imports/histories'
const Bulk_Edit_getFile = 'nexusapp/api/v1/bulk-operations/imports/download'
const Bulk_Edit_getListOFTemplates = '/nexusapp/api/v1/bulk-operations/imports/templates'
const Bulk_Edit_getTemplate = '/nexusapp/api/v1/bulk-operations/imports/download-template'
const Bulk_Edit_getDatapoints = '/nexusapp/api/v1/bulk-operations/imports/datapoints'
const Bulk_Edit_getEntityList = 'nexusapp/api/v1/bulk-operations/imports/entitylist'
const Bulk_Edit_datapoint_values='/nexusapp/api/v1/bulk-operations/imports/datapoint-values'
const Bulk_Edit_saveTemplate = '/nexusapp/api/v1/bulk-operations/imports/save-template'
const Bulk_Edit_importData='/nexusapp/api/v1/bulk-operations/imports/save'
const Bulk_Edit_downloadCustomTemplate='/nexusapp/api/v1/bulk-operations/imports/download-custom-template'

// Bulk Upload
const Bulk_Upload_checkUnmatachedItems = '/nexusapp/api/v1/bulk-operations/imports/unmatch-entities'
const Bulk_Upload_getTemplates='nexusapp/api/v1/bulk-operations/imports/templates'
const Bulk_Upload_getHeaders='/nexusapp/api/v1/bulk-operations/imports/headers'
const Bulk_Upload_importData='/nexusapp/api/v1/bulk-operations/imports'
const Bulk_Upload_staging='/nexusapp/api/v1/bulk-operations/imports/staging'

//Login
const AUTHENTICATE = '/nexuscore/api/v1/Security/user/authenticate';
const LOGOUT = '/nexuscore/api/v1/Security/user/logout';

// Enum,ReferenceType,MultiSelect Drop downs
const Enum_DropDowns="/nexusapp/api/v1/bulk-operations/imports/enum-values"
const Reference_DropDowns="/nexusapp/api/v1/bulk-operations/imports/refrence-values"

// sendInvite
const sendInvite="/nexuscore/api/v1/security/accounts/SendUserInvite";


// Search
const SEARCH_entities= 'nexusapp/api/v1/search';

//Reporting 
// 1.Universe
const REPORTING_universes='nexusapp/api/v1/universes';
const REPORTING_editGetUniverses='nexusapp/api/v1/universes/filters';
const REPORTING_deleteUniverse='nexusapp/api/v1/universes/delete';
const REPORTING_duplicateUniverse='nexusapp/api/v1/universes/duplicate'
const REPORTING_saveUniverse = '/nexusapp/api/v1/universes/save';
const REPORTING_listDataPointTemplates = 'nexusapp/api/v1/data-sets'
const REPORTING_universeOwner = 'nexusapp/api/v1/universes/Owner'
const REPORTING_uploadEntity = '/nexusapp/api/v1/universes/import-entities'
const REPORTING_additionalSearchApply = '/nexusapp/api/v1/universes/search-criteria'
const REPORTING_shareUniverse = '/nexusapp/api/v1/universes/share';
const REPORTING_shareUniverseInformation = '/nexusapp/api/v1/universes/shared-with';
const REPORTING_publishUniverse = '/nexusapp/api/v1/universes/publish';
//2.Template
const REPORTING_templates='nexusapp/api/v1/templates/all'
const REPORTING_saveTemplate = 'nexusapp/api/v1/templates';
const REPORTING_templateOwner = 'nexusapp/api/v1/templates/owners';
const REPORTING_deleteTemplates='nexusapp/api/v1/templates';
const REPORTING_duplicateTemplates='nexusapp/api/v1/templates';
const REPORTING_getTemplates='nexusapp/api/v1/templates';
const REPORTING_shareTemplate = '/nexusapp/api/v1/templates/share';
const REPORTING_shareTemplateInformation = '/nexusapp/api/v1/templates/shared-user';
const REPORTING_publishTemplate = '/nexusapp/api/v1/templates/publish';
// Data Set
const REPORTING_dataSets = '/nexusapp/api/v1/data-sets/all';
const REPORTING_saveDataSets= '/nexusapp/api/v1/data-sets';
const REPORTING_dataset = '/nexusapp/api/v1/data-sets';
const REPORTING_editDataSet = '/nexusapp/api/v1/data-sets';
const REPORTING_deleteDataSet='/nexusapp/api/v1/data-sets';
const REPORTING_ownerList='/nexusapp/api/v1/data-sets/owners';
const REPORTING_shareDataSet ='nexusapp/api/v1/data-sets/share';
const REPORTING_publishDataSet = '/nexusapp/api/v1/data-sets/publish';
const REPORTING_getAllUsers = '/nexusapp/api/v1/security/users-cache';
const REPORTING_shareDataSetInformation = '/nexusapp/api/v1/data-sets/shared-user';

//4.Reports
const REPORTING_reports = 'nexusapp/api/v1/reports';
const REPORTING_singleDownloadReport = 'nexusapp/api/v1/reports/report/download';
const REPORTING_multiDownloadReport = 'nexusapp/api/v1/reports/report/downloads';
const REPORTING_runReport = '/nexusapp/api/v1/reports/report/start';
const REPORTING_deleteReport = '/nexusapp/api/v1/reports/delete';
const REPORTING_status = '/nexusapp/api/v1/reports/statuses';
const REPORTING_multiReRunReport = 'nexusapp/api/v1/reports/report/re-run-report';
const REPORTING_reportOwner = '/nexusapp/api/v1/reports/owners';
const REPORTING_sendGuide = '/nexusapp/api/v1/reports/is_not_in_process';

//Data Dissemination
// 1.Data Feed
const DATADISSEMINATION_filterUniverse = '/nexusapp/api/v1/dissemination/feeds/universes';
const DATADISSEMINATION_filterTemplate = '/nexusapp/api/v1/dissemination/feeds/templates';
const DATADISSEMINATION_dropDownUniverse = '/nexusapp/api/v1/universes/names';
const DATADISSEMINATION_dropDownTemplate = '/nexusapp/api/v1/templates/names';
const DATADISSEMINATION_saveDataFeed = '/nexusapp/api/v1/dissemination/feeds';
const DATADISSEMINATION_updateDataFeed= '/nexusapp/api/v1/dissemination/feeds';
const DATADISSEMINATION_deleteDataFeed = '/nexusapp/api/v1/dissemination/feeds';
const DATADISSEMINATION_getDataFeed = '/nexusapp/api/v1/dissemination/feeds/all';
const DATADISSEMINATION_getUpdateDataFeed = '/nexusapp/api/v1/dissemination/feeds/edit';

// 2.Schedule
const DATADISSEMINATION_filterPartner = '/nexusapp/api/v1/dissemination/schedules/partners';
const DATADISSEMINATION_filterDeliveryMethod = '/nexusapp/api/v1/dissemination/schedules/delivery-methods';
const DATADISSEMINATION_dropDownDeliveryMethod = '/nexusapp/api/v1/dissemination/schedules/vendor-delivery-methods';
const DATADISSEMINATION_getSchedule = '/nexusapp/api/v1/dissemination/schedules/all';
const DATADISSEMINATION_getScheduleFile = '/nexusapp/api/v1/dissemination/schedules/files';
const DATADISSEMINATION_editSchedule = '/nexusapp/api/v1/dissemination/schedules/edit';
const DATADISSEMINATION_dropDownDataFeed = '/nexusapp/api/v1/dissemination/schedules/Feeds/names';
const DATADISSEMINATION_enableSchedule ="/nexusapp/api/v1/dissemination/schedules/enable";
const DATADISSEMINATION_saveSchedule = '/nexusapp/api/v1/dissemination/schedules';
const DATADISSEMINATION_deleteSchedule = '/nexusapp/api/v1/dissemination/schedules';
const DATADISSEMINATION_runSchedule = '/nexusapp/api/v1/dissemination/schedules/run';
const DATADISSEMINATION_downloadSchedule = '/nexusapp/api/v1/dissemination/schedules/download';
const DATADISSEMINATION_downloadScheduleVelo = '/nexusapp/api/v1/dissemination/schedules/data-services/download';

// 3.Partner
const DATADISSEMINATION_getPartner = '/nexusapp/api/v1/dissemination/partners/all';
const DATADISSEMINATION_deletePartner = '/nexusapp/api/v1/dissemination/partners';
const DATADISSEMINATION_dropDownDeliveryTypes = '/nexusapp/api/v1/dissemination/partners/delivery-types';
const DATADISSEMINATION_savePartner = '/nexusapp/api/v1/dissemination/partners';
const DATADISSEMINATION_editPartner = '/nexusapp/api/v1/dissemination/partners';
const DATADISSEMINATION_updatePartner= '/nexusapp/api/v1/dissemination/partners/edit';
const DATADISSEMINATION_ftpServer = '/nexusapp/api/v1/dissemination/partners/ftp-configs';



// Alerts
const ALERT_getAlert = "/nexusapp/api/v1/settings/alerts";
const ALERT_saveAlert = "/nexusapp/api/v1/settings/alerts";
const ALERT_editAlert = '/nexusapp/api/v1/settings/alerts/alert';
const ALERT_deleteAlert= '/nexusapp/api/v1/settings/alerts';
const ALERT_updateAlert = '/nexusapp/api/v1/settings/alerts/alert';


// Security
// User Management
const USERS_getUserRoles = '/nexuscore/api/v1/security/accounts/roles';
const USERS_editUser = '/nexuscore/api/v1/security/accounts/GetUsers';
const USERS_getUser = '/nexuscore/api/v1/security/accounts/all-users';
const USERS_createUser = '/nexuscore/api/v1/security/accounts/CreateNexusUser';
const USERS_updateUser = '/nexuscore/api/v1/security/accounts/UpdateNexusUser';
const USERS_exportUser = '/nexuscore/api/v1/security/accounts/export';

// completed task
const COMPELETED_TASK_getcompletedTaskList='/nexuscore/api/v1/workflow/wfexecutions/workflow-completed-tasks';
const COMPELETED_TASK_getcompletedTaskDetails='/nexuscore/api/v1/workflow/wfexecutions/workflow-completed-tasksdetail';
const COMPELETED_TASK_getWorkFlows="/nexuscore/api/v1/workflow/wfdefinitions";
const COMPELETED_TASK_getWorkFlowsSteps="/nexuscore/api/v1/workflow/wfdefinitions/steps-distinct"
//

// document management
const DOCUMENTMANAGEMENT_getDocumentManagementList= '/nexusapp/api/v1/documents';
const DOCUMENTMANAGEMENT_downloadDocumentManagement = '/nexusapp/api/v1/documents/download-documents/';
const DOCUMENTMANAGEMENT_uploadDocumentManagement = '/nexusapp/api/v1/documents/upload-documents';

// dashboard
const DASHBOARD_fundUniverseMatrix  = '/api/v1/dashboard/fund-universe-matrix';
const DASHBOARD_workflowHealth = '/nexuscore/api/v1/workflow/wfexecutions/workflow-health';
const DASHBOARD_event= '/api/v1/dashboard/events';

// ACTIVITY History
const ACTIVITY_HISTORY_getActivityHistoryList='/nexusapp/api/v1/audit/activity-history';

// DATA MODEL
const DATA_MODEL_DATAPOINTS='/nexusapp/api/v1/data-model/datapoints'
const DATA_MODEL_LANGUAGES='/nexusapp/api/v1/data-model/languages'
const DATA_MODEL_DATA_TYPES='/nexusapp/api/v1/data-model/dataTypes'
const DATA_MODEL_DATAPOINT_DETAILS='/nexusapp/api/v1/data-model/edit-view'
const DATA_MODEL_AVAILABLE_GROUPS_DETAILS='/nexusapp/api/v1/data-model/dataPointGroups'
const DATA_MODEL_DATAPOINTSSORTORDER='/nexusapp/api/v1/data-model/dataPointSortOrder'
const DATA_MODEL_ENUM_TYPES='/nexusapp/api/v1/data-model/enum-types'
const DATA_MODEL_REFERENCE_TYPES='/nexusapp/api/v1/data-model/reference-types'
const DATA_MODEL_ENUM_VALUES='/nexusapp/api/v1/data-model/enum-values'
const DATA_MODEL_REFERENCE_VALUES='/nexusapp/api/v1/data-model/reference-values'
const DATA_MODEL_VALIDATIONS='/nexusapp/api/v1/data-model/validations'
const DATA_MODEL_SAVEDATAPOINT='/nexusapp/api/v1/data-model/save'
const DATA_MODEL_DELETEDATAPOINT='/nexusapp/api/v1/data-model/delete'
const DATA_MODEL_SEARCH_DATAPOINT='/nexusapp/api/v1/data-model/search'
export default {
    AUTHENTICATE,
    LOGOUT,
    sendInvite,
    // ADD_INVESTMENT_getAllEntitiesEndPoint,
    // ADD_INVESTMENT_getEntityByEntityIdEndPoint,
    ADD_INVESTMENT_getAllEntitiesEndPoint_check,
    ADD_INVESTMENT_getEntityTypes,
    ADD_INVESTMENT_Create,
    ADD_INVESTMENT_Save_Datapoint,
    ADD_INVESTMENT_ENTITY_DROPDOWNS,
    ASSIGNED_TO_YOU_getAssignedItemsEndPont,
    ADD_INVESTMENT_getselectforenumid,
    ADD_INVESTMENT_getselectforreferencetypeid,
    ADD_INVESTMENT_getselectforMultipleEnumid,
    ADD_INVESTMENT_getselectforMultipleReferenceTypeId,
    GET_LANGUAGES,
    ADD_INVESTMENT_DATAPOINT_HISTORY,
    GET_COUNTRY_TEXT,
    GET_LANGUAGE_TEXT,
    ASSIGNED_TO_YOU_getAssignedTaskDetails,
    ADD_INVESTMENT_downloadDatapointHistory,
    ASSIGNED_TO_YOU_submitUserVerdict,
    Bulk_Edit_getImportHistory,
    Bulk_Edit_getFile,
    Bulk_Upload_checkUnmatachedItems,
    Bulk_Upload_getTemplates,
    Bulk_Upload_getHeaders,
    Bulk_Edit_getEntityList,
    Bulk_Edit_getListOFTemplates,
    Bulk_Edit_getTemplate,
    Bulk_Edit_getDatapoints,
    Bulk_Upload_importData,
    Bulk_Edit_datapoint_values,
    Bulk_Edit_saveTemplate,
    Bulk_Edit_importData,
    Bulk_Edit_downloadCustomTemplate,
    Bulk_Upload_staging,
    Enum_DropDowns,
    Reference_DropDowns,
    SEARCH_entities,
    REPORTING_universes,
    ADD_INVESTMENT_autoMapping,
    REPORTING_saveUniverse,
    REPORTING_templates,
    REPORTING_runReport,
    REPORTING_saveTemplate,
    REPORTING_listDataPointTemplates,
    REPORTING_saveDataSets,
    REPORTING_reports,
    REPORTING_singleDownloadReport,
    REPORTING_multiDownloadReport,
    REPORTING_dataSets,
    REPORTING_editGetUniverses,
    REPORTING_deleteUniverse,
    REPORTING_duplicateUniverse,
    REPORTING_dataset,
    REPORTING_editDataSet,
    REPORTING_deleteDataSet,
    REPORTING_ownerList,
    REPORTING_universeOwner,
    REPORTING_templateOwner,
    REPORTING_deleteTemplates,
    REPORTING_getTemplates,
    REPORTING_shareDataSet,
    REPORTING_publishDataSet,
    REPORTING_getAllUsers,
    REPORTING_shareDataSetInformation,
    REPORTING_duplicateTemplates,
    REPORTING_uploadEntity,
    REPORTING_deleteReport,
    REPORTING_additionalSearchApply,
    REPORTING_status,
    REPORTING_multiReRunReport,
    REPORTING_reportOwner,
    REPORTING_shareUniverse,
    REPORTING_shareUniverseInformation,
    REPORTING_publishUniverse,
    REPORTING_shareTemplate,
    REPORTING_shareTemplateInformation,
    REPORTING_publishTemplate,
    REPORTING_sendGuide,
    ASSIGNED_TO_YOU_getTaskDetailsComments,
    DATADISSEMINATION_filterUniverse,
    DATADISSEMINATION_filterTemplate,
    DATADISSEMINATION_dropDownUniverse,
    DATADISSEMINATION_dropDownTemplate,
    DATADISSEMINATION_saveDataFeed,
    DATADISSEMINATION_updateDataFeed,
    DATADISSEMINATION_deleteDataFeed,
    DATADISSEMINATION_getDataFeed,
    DATADISSEMINATION_filterPartner,
    DATADISSEMINATION_filterDeliveryMethod,
    DATADISSEMINATION_dropDownDeliveryMethod,
    DATADISSEMINATION_getSchedule,
    DATADISSEMINATION_getScheduleFile,
    DATADISSEMINATION_editSchedule,
    DATADISSEMINATION_getUpdateDataFeed,
    DATADISSEMINATION_dropDownDataFeed,
    DATADISSEMINATION_enableSchedule,
    DATADISSEMINATION_saveSchedule,
    DATADISSEMINATION_deleteSchedule,
    DATADISSEMINATION_runSchedule,
    DATADISSEMINATION_downloadSchedule,
    DATADISSEMINATION_downloadScheduleVelo,
    DATADISSEMINATION_getPartner,
    DATADISSEMINATION_deletePartner,
    DATADISSEMINATION_dropDownDeliveryTypes,
    DATADISSEMINATION_savePartner,
    DATADISSEMINATION_editPartner,
    DATADISSEMINATION_updatePartner,
    ALERT_getAlert,
    ALERT_saveAlert,
    ALERT_editAlert,
    ALERT_deleteAlert,
    ALERT_updateAlert,
    COMPELETED_TASK_getcompletedTaskList,
    COMPELETED_TASK_getcompletedTaskDetails,
    COMPELETED_TASK_getWorkFlows,
    COMPELETED_TASK_getWorkFlowsSteps,
    USERS_getUserRoles,
    USERS_editUser,
    USERS_getUser,
    USERS_createUser,
    USERS_updateUser,
    USERS_exportUser,
    DOCUMENTMANAGEMENT_getDocumentManagementList,
    DOCUMENTMANAGEMENT_downloadDocumentManagement,
    DOCUMENTMANAGEMENT_uploadDocumentManagement,
    ACTIVITY_HISTORY_getActivityHistoryList,
    EXTERNAL_CODES_getAndUpdate,
    DATA_MODEL_DATAPOINTS,
    DATA_MODEL_LANGUAGES,
    DATA_MODEL_DATA_TYPES,
    DATA_MODEL_DATAPOINT_DETAILS,
    DATA_MODEL_ENUM_TYPES,
    DATA_MODEL_REFERENCE_TYPES,
    DATA_MODEL_ENUM_VALUES,
    DATA_MODEL_REFERENCE_VALUES,
    DATA_MODEL_VALIDATIONS,
    DATA_MODEL_SAVEDATAPOINT,
    DATA_MODEL_DELETEDATAPOINT,
    DASHBOARD_fundUniverseMatrix,
    DASHBOARD_workflowHealth,
    DASHBOARD_event,
    ASSIGNED_TO_YOU_getWorkflowFilterList,
    DATADISSEMINATION_ftpServer,
    DATA_MODEL_AVAILABLE_GROUPS_DETAILS,
    DATA_MODEL_DATAPOINTSSORTORDER,
    DATA_MODEL_SEARCH_DATAPOINT
}