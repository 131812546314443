import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}
export function setActivityHistoryListInProgress(state) {
    state.activityHistoryList = {
        __loading__: true,
        __error__: null
    };
}
export function setActivityHistoryList(state, activityHistoryList) {
    state.activityHistoryList = {
        activityHistoryList,
        __loading__: false,
        __error__: null
    };
}
export function setActivityHistoryListFailure(state, error) {
    state.activityHistoryList = {
        __loading__: false,
        __error__: error
    };
}
