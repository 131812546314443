export default {
    state: {
        loaded: true
    },
    actions: {
        getEnvConfig ({ commit }) {
            // eslint-disable-next-line
            return fetch(__webpack_public_path__ + "config.json")
                .then((data) => {
                    // console.log(data);
                    return Promise.resolve(data.json());
                })
                .then(data => commit("setEnvConfig", data))
                .catch(console.error); // eslint-disable-line no-console
        },
        updateNewConfig ({ commit }, config) {
            return Promise.resolve(commit("updateConfig", config));
        }
    },
    mutations: {
        setEnvConfig (state, config) {
            for (const key in config) {
                try {
                    this._vm.$set(state, key, config[key]);
                } catch (e) {
                    Promise.reject(e);
                    break;
                }
            }
        }
    }
}