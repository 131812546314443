export default function initialState() {
    return {
        filterUniverses: {
            __loading__: false,
            __error__: null
        },
        filterTemplates: {
            __loading__: false,
            __error__: null
        },
        dropDownUniverses: {
            __loading__: false,
            __error__: null
        },
        dropDownTemplates: {
            __loading__: false,
            __error__: null
        },
        dataFeeds: {
            __loading__: false,
            __error__: null
        },
        saveDataFeeds: {
            __loading__: false,
            __error__: null
        },
        updateDataFeeds: {
            __loading__: false,
            __error__: null
        },
        deleteDataFeeds: {
            __loading__: false,
            __error__: null
        },
        getUpdateDataFeeds: {
            __loading__: false,
            __error__: null
        },
        setCronExpression: null
    };
}
