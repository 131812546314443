
import axiosCall from '../api/httpClient';
import config from '../config/config.api'
import { dateFormatterMixin } from "../mixins/date.js";
import Vue from 'vue';
import store from '../store/index.js'
import { getAllEnumDropDowns,getAllReferenceDropDowns} from './get_default_values.service.js';
import axios from 'axios';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);
const httpClientFileDownload = axiosCall(process.env.VUE_APP_BASE_URL, 'blob');

// const getAllEntities = () => httpClient.get(config.ADD_INVESTMENT_getAllEntitiesEndPoint);

// const getEntityByEntityId = (entityId) => httpClient.get(config.ADD_INVESTMENT_getEntityByEntityIdEndPoint+entityId);
var enumIds=[]
var referencedentitytypeids=[]
async function getAllEntities (data){
  return await httpClient.get(config.ADD_INVESTMENT_getAllEntitiesEndPoint_check,{params:data}).then(async (response)=>{
        if(response.data.datapointgroups != null){
          await convertDataPointJson([],response.data);
      }
      response["isError"]=false
      return response 
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
} 
async function getEntityTypes(){
  return await httpClient.get(config.ADD_INVESTMENT_getEntityTypes).then((response)=>{
    response["isError"]=false
    return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
}
// const getEntityTypes=()=>httpClient.get(config.ADD_INVESTMENT_getEntityTypes);

async function getReferenceEntityTypesDropdown(paramsData){
  return await httpClient.get(config.ADD_INVESTMENT_getEntityTypes,{params:paramsData}).then((response)=>{
    response["isError"]=false
    return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
}

async function getParentEntities(paramsData){
  return await httpClient.get(config.ADD_INVESTMENT_Create,{params:paramsData}).then((response)=>{
    response["isError"]=false
    return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
}
// const getParentEntities=(paramsData)=>httpClient.get(config.ADD_INVESTMENT_Create,{params:paramsData});

async function insertNewEntity(data){
    return await  httpClient.post(config.ADD_INVESTMENT_Create,data).then((response)=>{
      response["isError"]=false
      return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
}
async function saveDatapointAsDraft(data){
  return await  httpClient.post(config.ADD_INVESTMENT_Save_Datapoint,data).then(async (response)=>{
    
    response["isError"]=false
    if (response.data.status == -1) {
      if(response.data.datapointstatuses != null){
          var errorDetails = await response.data.datapointstatuses.filter(
            (list) => list.status == -1
          );
        
          await convertDataPointJson(errorDetails,null);
      }
      }
    return response

  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
    
}

async function getEntityDropDowns(data){
    return await httpClient.get(config.ADD_INVESTMENT_ENTITY_DROPDOWNS,{params:data}).then((response)=>{
      response["isError"]=false
      return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
}
async function getSelectForEnum(enumId,value){
    return await  httpClient.get(config.ADD_INVESTMENT_getselectforenumid,{
        params:{
            enumId:enumId,
            selectedValue:value
        }
    }).then((response)=>{
      response["isError"]=false
      return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
}
async function getAutoMappingDetails(sourceDataPointId,sourceValue){
  return await  httpClient.get(config.ADD_INVESTMENT_autoMapping,{
      params:{
        sourceDataPointId:sourceDataPointId,
        sourceValue:sourceValue
      }
  }).then((response)=>{
    response["isError"]=false
    return response
  }).catch(error=>{
    error.response["isError"]=true
    return error.response
  })
}
async function getSelectForReferenceTypeId(referenceTypeId,value){
    return await  httpClient.get(config.ADD_INVESTMENT_getselectforreferencetypeid,{
        params:{
            referenceTypeId:referenceTypeId,
            selectedValue:value
        }
    }).then((response)=>{
      response["isError"]=false
      return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
}
async function getSelectForMultipleEnum(enumId,dataArray){
    return await  httpClient.post(config.ADD_INVESTMENT_getselectforMultipleEnumid,dataArray,{
        params:{
            enumId:enumId
        }
    }).then((response)=>{
      response["isError"]=false
      return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
}
async function getSelectForMultipleReferenceTypeId(referenceTypeId,dataArray){
    return await  httpClient.post(config.ADD_INVESTMENT_getselectforMultipleReferenceTypeId,dataArray,{
        params:{
            referenceTypeId:referenceTypeId
        }
    }).then((response)=>{
      response["isError"]=false
      return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
}

async function getDataPointHistory(entityId,dataPointId,arrayIndex){
    return await  httpClient.get(config.ADD_INVESTMENT_DATAPOINT_HISTORY,{
        params:{
            entityId:entityId,
            dataPointId:dataPointId,
            arrayIndex:arrayIndex
        }
    }).then((response)=>{
      response["isError"]=false
      return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
}
// async function downloadDatapointHistoryAsCsv(entityId,dataPointId,arrayIndex){
//   return await httpClientFileDownload.get(config.ADD_INVESTMENT_downloadDatapointHistory,{
//     params:{
//         entityId:entityId,
//         dataPointId:dataPointId,
//         arrayIndex:arrayIndex
//     }
// }).then(async (response) => {
//     response["isError"] = false
//     if(response.data !== undefined) {
//       const url = window.URL.createObjectURL(new Blob([response.data], {
//         type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
//       }))
//       const link = document.createElement('a');
//       link.href = url;
//       link.setAttribute('download', "CustomTemplate");
//       document.body.appendChild(link);
//       link.click();
//       URL.revokeObjectURL(link.href);
//     }
//     return response
//   }).catch(error => {
//     error.response["isError"] = true
//     console.log(error);
//     console.log(error.response);
//     return error.response
//   })
// }
async function downloadDatapointHistoryAsCsv(entityId,dataPointId,arrayIndex){
    // window.open()
   var response = await httpClientFileDownload.get(config.ADD_INVESTMENT_downloadDatapointHistory,{
    params:{
        entityId:entityId,
        dataPointId:dataPointId,
        arrayIndex:arrayIndex
    }
})

        let blob = new Blob([response.data], {type: 'text/csv'})
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'DataPointHistory.csv'
        link.click()
}
// const getLanguagesDetails=()=>httpClient.get(config.GET_LANGUAGES);
async function getLanguagesDetails(){
  return await httpClient.get(config.GET_LANGUAGES).then((response)=>{
    return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
}


async function convertDataPointJson(errorList,entities) {
    let datapointEntitiesConvertedJson =[]
    if(entities == null){
     
         datapointEntitiesConvertedJson = await store.getters['entity/getEntities']
        //  console.log(await store.getters['entity/getEntities']);
    }else{
        datapointEntitiesConvertedJson = entities
    }
   
    await setJsonForEntites(
      datapointEntitiesConvertedJson.datapointgroups,
      errorList
    );
    enumIds = [...new Set(enumIds)]
    referencedentitytypeids=[...new Set(referencedentitytypeids)]
    // await store.commit('dropDowns/setEnumIdList',enumIds)
    // await store.commit('dropDowns/setReferenceIdList',referencedentitytypeids)
    await axios.all([getenumIdDetails(),getReferenceIdDetails()]).then(axios.spread((first_response, second_response)=>{
      console.log(first_response);
      console.log(second_response);
    }))
  }

  async function setJsonForEntites(dataGroup, errorList) {
    for (let singleGroup of dataGroup) {
      var list
      if (errorList.length == 0){ 
        list =  await groupBy(singleGroup.datapointslist,'datapointid',singleGroup.type);
        list = list.sort((a, b) => a.datapointname.localeCompare(b.datapointname))
        list = list.sort((a, b) => a.sortorder - b.sortorder);
        if(singleGroup.type == 1){
          singleGroup["groupAction"]="update"
        }else{
          singleGroup["groupAction"]="NoAction"
        }
      }else{
        list = singleGroup.datapointslist
      }
      for (let i = 0; i < list.length; i++) {
        if(list[i].enumid != null){
          enumIds.push(list[i].enumid)
        }
        if(list[i].referencedentitytypeid != null){
          referencedentitytypeids.push(list[i].referencedentitytypeid)
        }
        if (errorList.length == 0){
          list[i]["oldvalue"] = list[i].value;
          list[i]["oldeffectivedate"] = list[i].effectivedate;
          list[i]["olddisseminationdate"] = list[i].disseminationdate;
          list[i]["oldnote"] = list[i].note;
          list[i]["oldna"] = list[i].isna;
          list[i]["warning"] = false;
          if(list[i].datatypeid == 3 && (list[i].value != null && list[i].value.length > 0) ){
            // var localformat = dateConvert.dateFromUTCTOLocal(list[i].value).split(',')
            list[i].value = dateFormatterMixin.methods.convertReportingParameterDate(list[i].value)
            list[i].oldvalue=dateFormatterMixin.methods.convertReportingParameterDate(list[i].value)
          }
         
        Vue.set(list[i], "shownote", false);
          // Converting the dates format from "DD-MM-YYYY" to "yyyy-MM-DD" for the input type date 
          if( list[i].datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE' || list[i].datapointname.toString().toUpperCase() == 'KEY DATE'){
            if(list[i].value){
              list[i].value = dateFormatterMixin.methods.convertReportingParameterDate(list[i].value)
              list[i].valuetext= dateFormatterMixin.methods.convertAdditionalSearchDate(list[i].valuetext)
              list[i].oldvalue= dateFormatterMixin.methods.convertReportingParameterDate(list[i].value)
            }
          }
        if(list[i].effectivedate){
          // var effectivedate = dateConvert.dateFromUTCTOLocal(list[i].effectivedate).split(',')

          list[i].effectivedatestring = dateFormatterMixin.methods.convertAdditionalSearchDate(list[i].effectivedate)
          list[i].effectivedate = dateFormatterMixin.methods.convertReportingParameterDate(list[i].effectivedate)
          list[i].oldeffectivedate=dateFormatterMixin.methods.convertAdditionalSearchDate(list[i].effectivedate)
        }
        if(list[i].disseminationdate){
          // var disseminationdate = dateConvert.dateFromUTCTOLocal(list[i].disseminationdate).split(',')
          list[i].disseminationdatestring = dateFormatterMixin.methods.convertAdditionalSearchDate(list[i].disseminationdate)
          list[i].disseminationdate = dateFormatterMixin.methods.convertReportingParameterDate(list[i].disseminationdate)
          list[i].olddisseminationdate=dateFormatterMixin.methods.convertAdditionalSearchDate(list[i].disseminationdate)
        }
        
      }
        // Clearing the errors 
        if(list[i].errormessage){
          list[i].errormessage=""
        }
        if( list[i].isvalidationerror){
          list[i].isvalidationerror= false
        }

        // adding the validation errors
        if (errorList.length > 0) {
          list[i]["errormessage"]=[]
          for (var j = 0; j < errorList.length; j++) {
            if (errorList[j].datapointid == list[i].datapointid && errorList[j].arrayindex == list[i].arrayindex) {
              console.log(list[i]);
              if(errorList[j].duplicablegroup == true){
                if(errorList[j].arrayindex == list[i].arrayindex){
                  list[i].errormessage.push(errorList[j].message);
                  list[i]["isvalidationerror"] = true;
                  if( list[i].datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE' || list[i].datapointname.toString().toUpperCase() == 'KEY DATE'){
                    if(list[i].value){
                      list[i].value = dateFormatterMixin.methods.convertReportingParameterDate(list[i].value);
                      list[i].valuetext= dateFormatterMixin.methods.convertAdditionalSearchDate(list[i].valuetext)
                    }
                  }else if(list[i].datatypeid == 3 && list[i].value != null){
                    if(errorList[j].value != null){
                      list[i].value = dateFormatterMixin.methods.convertReportingParameterDate(errorList[j].value)
                   }else{
                     list[i].value = errorList[j].value
                   }
                  }else{
                    list[i].value = errorList[j].value
                  }
                  
                  if(errorList[j].warning){
                    list[i].warning = true;
                  }
                }
              }else{
                list[i].errormessage.push(errorList[j].message);
                list[i]["isvalidationerror"] = true;
                if( list[i].datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE'|| list[i].datapointname.toString().toUpperCase() == 'KEY DATE'){
                  if(list[i].value){
                    list[i].value = dateFormatterMixin.methods.convertReportingParameterDate(list[i].value)
                    list[i].valuetext= dateFormatterMixin.methods.convertAdditionalSearchDate(list[i].valuetext)
                  }
                }else if(list[i].datatypeid == 3 && list[i].value != null){
                   if(errorList[j].value != null){
                      // var localformatError = dateConvert.dateFromUTCTOLocal(errorList[j].value).split(',')
                      list[i].value = dateFormatterMixin.methods.convertReportingParameterDate(errorList[j].value)
                   }else{
                     list[i].value = errorList[j].value
                   }
                }else{
                  list[i].value = errorList[j].value
                }
                
                if(errorList[j].warning){
                  list[i].warning = true;
                }
              }
              
            }
          }
        }

      }
      singleGroup.datapointslist = list;
      if (singleGroup.datapointgroups.length > 0) {
       await setJsonForEntites(singleGroup.datapointgroups,errorList);
      }
    }
  }
  
async function getenumIdDetails(){
  if(enumIds.length > 0){
    let getEnumIdList=await store.getters['dropDowns/getEnumDropDowns']
    if(getEnumIdList.length == 0){
      const response = await getAllEnumDropDowns(enumIds)
      for(let i=0;i<response.data.length;i++){
       await store.commit('dropDowns/setEnumDropDowns',response.data[i])
      }
      return true
    }else{
      var getEnums=[]
            for(let j=0;j<enumIds.length;j++){
              var checkEnum = await store.getters['dropDowns/getEnumDropDowns'].filter((enumdetails) => enumIds[j]== enumdetails.enumid)
              if(checkEnum.length == 0){
                 getEnums.push(enumIds[j])
              }
              if(j==enumIds.length-1){
                if(getEnums.length !=0){
                  // await this.fetchEnumDropDownDetails(getEnums)
                  const response = await getAllEnumDropDowns(getEnums)
                  for(let i=0;i<response.data.length;i++){
                    await store.commit('dropDowns/setEnumDropDowns',response.data[i])
                     
                  }
                  return true
                }
              }
            }
            return true
    }
  }
}
async function getReferenceIdDetails(){
  if(referencedentitytypeids.length > 0){
    let getReferenceIdList=await store.getters['dropDowns/getReferenceDropDowns'] 
    if(getReferenceIdList.length == 0){
      const response = await getAllReferenceDropDowns(referencedentitytypeids)
      for(let i=0;i<response.data.length;i++){
       await store.commit('dropDowns/setReferenceDropDowns',response.data[i])
      }
      return true
    }else{
      var getReferences=[]
            for(let r=0;r<referencedentitytypeids.length;r++){
              var checkReference = await store.getters['dropDowns/getReferenceDropDowns'].filter((referencedetails) => referencedentitytypeids[r]== referencedetails.refrenceid)
              if(checkReference.length == 0){
                 getReferences.push(referencedentitytypeids[r])
              }
              if(r==referencedentitytypeids.length-1){
                if(getReferences.length !=0){
                    const response = await getAllReferenceDropDowns(getReferences)
                    for(let i=0;i<response.data.length;i++){
                    await store.commit('dropDowns/setReferenceDropDowns',response.data[i])
                    }
                    return true
                }
              }
            }
            return true
    }
  }
}
  async function groupBy(array, key,groupType){
    const result = {}
    var ObjectToArrayResult = []
    var finalList=[]
    array.forEach(item => {
      if (!result[item[key]]){
        result[item[key]] = []
      }
      result[item[key]].push(item)
    })
    ObjectToArrayResult = await Object.keys(result).map((key) => result[key]);
    await ObjectToArrayResult.forEach(element => {
      if(element.length > 1){
        let values=[]
        let oldValues=[]
        let valuesText=""
        for(let el=0;el<element.length;el++){
          if(element[el].value){
            values.push(element[el].value)
            oldValues.push(element[el].value)
            if(valuesText.trim().length == 0){
              valuesText=element[el].valuetext
            }else{
              valuesText=valuesText+", "+element[el].valuetext
            }
          }
        }
        element[0]["values"]=values
        element[0]["oldValues"]=oldValues
        element[0]["valuetext"]=valuesText
        finalList.push(element[0])
      }else if(element[0].datatypeenum == 7 || element[0].enumid != null){
        if(groupType != 1 && element[0].arraylength != 1){
          if(element[0].value != null){
             let singleValue = []
               singleValue.push(element[0].value)
                element[0]["values"]=singleValue
            let singleOldValue =[]
            singleOldValue.push(element[0].value)
            element[0]["oldValues"]=singleOldValue
          }else{
            element[0]["values"] = null
            element[0]["oldValues"]=null
          }
           
            finalList.push(element[0])

            }else{
               finalList.push(element[0])
               }
      }
      else{
        finalList.push(element[0])
      }
    })
    
    // function compare(a, b) {
    //     if (a.datapointname < b.datapointname)
    //       return -1;
    //     if (a.datapointname > b.datapointname)
    //       return 1;
    //     return 0;
    //   }
      // finalList.sort(compare)
    return finalList
}

export {
    getAllEntities,
    // getEntityByEntityId,
    getEntityTypes,
    getReferenceEntityTypesDropdown,
    getParentEntities,
    insertNewEntity,
    saveDatapointAsDraft,
    getEntityDropDowns,
    getSelectForEnum,
    getSelectForReferenceTypeId,
    getSelectForMultipleEnum,
    getSelectForMultipleReferenceTypeId,
    getLanguagesDetails,
    getDataPointHistory,
    downloadDatapointHistoryAsCsv,
    setJsonForEntites,
    getenumIdDetails,
    getReferenceIdDetails,
    getAutoMappingDetails
   
}