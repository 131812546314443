import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { ALERTS_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

// fetch alert list
export async function fetchAlertList({ commit }, {
    data,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(ALERTS_MUTATIONS.SET_ALERTS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.ALERT_getAlert, {
            params: {
                page : data.page,
                pageSize : data.pageSize,
                searchAlert : data.searchAlert,
                searchType : data.searchType,
                orderByColumn : data.orderByColumn,
                isAscending : data.isAscending,
            },
        })
        commit(ALERTS_MUTATIONS.SET_ALERTS_COMPLETE, response.data);
    } catch (error) {
        commit(ALERTS_MUTATIONS.SET_ALERTS_ERROR, error.response.data.message);
    }
}

// for create alert
export async function fetchSaveAlert({ commit }, {
    data,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(ALERTS_MUTATIONS.SET_SAVE_ALERTS_IN_PROGRESS);
        }
        const response = await httpClient
        .post(config.ALERT_saveAlert,data)
        commit(ALERTS_MUTATIONS.SET_SAVE_ALERTS_COMPLETE, response.data);
    } catch (error) {
        commit(ALERTS_MUTATIONS.SET_SAVE_ALERTS_ERROR, error.response.data.message);
    }
}

// for edit alert
export async function fetchEditAlert({ commit }, {
    notificationRuleId,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(ALERTS_MUTATIONS.SET_EDIT_ALERTS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.ALERT_editAlert, {
            params: {
                notificationRuleId : notificationRuleId,
             },
        })
        commit(ALERTS_MUTATIONS.SET_EDIT_ALERTS_COMPLETE, response.data);
    } catch (error) {
        commit(ALERTS_MUTATIONS.SET_EDIT_ALERTS_ERROR, error.response.data.message);
    }
}

// for delete alert
export async function fetchDeleteAlert({ commit }, {
    notificationRuleId,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(ALERTS_MUTATIONS.SET_DELETE_ALERTS_IN_PROGRESS);
        }
        const response = await httpClient
        .delete(config.ALERT_deleteAlert, {
            params: {
                notificationRuleId : notificationRuleId,
             },
        })
        commit(ALERTS_MUTATIONS.SET_DELETE_ALERTS_COMPLETE, response.data);
    } catch (error) {
        commit(ALERTS_MUTATIONS.SET_DELETE_ALERTS_ERROR, error.response.data.message);
    }
}

// for update alert
export async function fetchUpdateAlert({ commit }, {
    data,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(ALERTS_MUTATIONS.SET_UPDATE_ALERTS_IN_PROGRESS);
        }
        const response = await httpClient
        .put(config.ALERT_updateAlert,data)
        commit(ALERTS_MUTATIONS.SET_UPDATE_ALERTS_COMPLETE, response.data);
    } catch (error) {
        commit(ALERTS_MUTATIONS.SET_UPDATE_ALERTS_ERROR, error.response.data.message);
    }
}
