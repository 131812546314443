
import { 
    getAllEntities,
    // getEntityByEntityId,
    getEntityTypes,
    getParentEntities,
    getEntityDropDowns,
    getSelectForEnum,
    getReferenceEntityTypesDropdown
} from "../../services/add_investment_service.js"
// import entity from './entity.json'
// import entityWithChildAsFund from './entityWithChildAsFunds.json'
const state = {
    entities:[],
    selectedEntity:{},
    entityTypes:[],
    parentEntityDetails:[],
    selectedEntitydataPoints:[],
    dropdownsForEnumIds:[],
    entityListForDropDowns:[],
    ReferenceEntityTypesDropdown:[],
}

const getters = {
    getEntities(state1) {
        return state1.entities;
    },
    getSelectedEntity(state1){
        return state1.selectedEntity;
    },
    getEntityTypes(typeState){
        return typeState.entityTypes
    },
    getParentEntityDetails(parentState){
        return parentState.parentEntityDetails
    },
    getDatapointJson(jsonstate){
        return jsonstate.selectedEntitydataPoints
    },
    getDropDownsForEnumIds(state){
        return state.dropdownsForEnumIds
    },
    getEntityListForDropDowns(state){
        return state.entityListForDropDowns
    },
    getReferenceEntityTypesDropdown(typeState){
        return typeState.ReferenceEntityTypesDropdown
    },
}

const mutations = {
    SET_ENTITIES(state1, data) {
        state1.entities = data;
    },
    setSelectedEntity(state1, data) {
        state1.selectedEntity = data;
    },
    setEntityTypes(typeState,data){
        typeState.entityTypes=data
    },
    setParentEntityDetails(parentState,data){
        parentState.parentEntityDetails=data
    },
    setDataPointJson(jsonState,data){
        jsonState.selectedEntitydataPoints=data
    },
    setDropDownsForEnums(state,data){
        state.dropdownsForEnumIds.push(data)
    },
    setEntityListForDropDowns(state,data){
        state.entityListForDropDowns=data
    },
    setReferenceEntityTypesDropdown(typeState,data){
        typeState.ReferenceEntityTypesDropdown=data
    },
}

/** action method to load entity data */
const actions = {
    async fetchEntities({ commit },payload) {
            try {
                const response = await getAllEntities(payload);
                if(response.status == 200){
                    commit('SET_ENTITIES',  response.data);
                }
                return response
            } catch (error) {
                console.log(error);
                return error
            }    
        },
 
    // async fetchEntityById({commit},{entityId}){
    //     try{
    //         console.log(entityId);
    //         // console.log(entityWithChildAsFund)
    //         const response=await getEntityByEntityId(entityId);
    //         console.log(response.data);
    //         commit('SET_ENTITIES', response.data)
    //     }catch(error){
    //        //handle the error
    //     }
    // },
    async fetchEntityTypes({commit}){
        try{
            const response = await getEntityTypes();
            if(response.status == 200){
                commit('setEntityTypes',response.data)
            }
        }catch(error){
            //handle the error
        }
    },

    async fetchReferenceEntityTypesDropdown({commit},paramsData){
        try{
            const response = await getReferenceEntityTypesDropdown(paramsData);
            commit('setReferenceEntityTypesDropdown',response.data)
        }catch(error){
            //handle the error
        }
    },

    async fetchParentEntityDetails({commit},paramsData){
        try{
            const response = await getParentEntities(paramsData);
            commit('setParentEntityDetails',response.data)
        }catch(error){
            //handle the error
        }
    },

    async fetchDropdownListEntity({commit},data){
        try{
            const response = await getEntityDropDowns(data)
            commit('setEntityListForDropDowns',response.data)
            return response
        }catch(error){
            // handle the error
        }
    },
    async fetchSelectDropDownForEnumId({commit},enumId,valueDetails){
        try{ 
            const response = await getSelectForEnum(enumId,valueDetails)
            console.log(response);
            // return response.data
            var dropdownobject={
                enumId:enumId,
                enumDropDowns:response.data
            }
            commit('setDropDownsForEnums',dropdownobject)
            return response
        }catch(error){
            // 
        }
    }
    }


export default {
    namespace:'entity',
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}