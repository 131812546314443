import config from '../config/config.api';
import axiosCall from "@/api/httpClient.js";
const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);
// import items from '../assignedItems.json';

async function getAssignedItems(data){
  return await httpClient.post(config.ASSIGNED_TO_YOU_getAssignedItemsEndPont, data).then((response)=>{
    response["isError"]=false
    return response
  }).catch(error=>{
    console.log(data);
     error.response["isError"]=true
    return error.response
  })
}

// const getAssignedItems = () => items;
const getAssignedTaskDetail = (taskId) => httpClient.get(config.ASSIGNED_TO_YOU_getAssignedTaskDetails+"/"+taskId);
const getCompletedTaskDetail = (taskId) => httpClient.get(config.COMPELETED_TASK_getcompletedTaskDetails+"/"+taskId);
// 
async function submitUserVerdict(data){
  console.log(data);
  return await httpClient.post(config.ASSIGNED_TO_YOU_submitUserVerdict,data).then((response)=>{
    response["isError"]=false
    return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
}

async function getWorkflowComments(workflowTaskId){
  console.log(workflowTaskId);
  return await httpClient.get(config.ASSIGNED_TO_YOU_getTaskDetailsComments+"/"+workflowTaskId).then((response)=>{
    response["isError"]=false
    return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
}
export {
  getAssignedItems,
  getAssignedTaskDetail,
  submitUserVerdict,
  getWorkflowComments,
  getCompletedTaskDetail
}
