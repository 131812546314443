<template>
  <div class="app">
    
    
    <router-view/>
   
  </div>
</template>
<script>
export default {
  name: "App",
  async created() {
    await this.$store.dispatch("getEnvConfig");
    const isLocalUrl = "https://nexus-ui.morningstar.com";
    const redirectUrl = window.location.origin === isLocalUrl ? "https://nexus-dev.dmu70c89.easn.morningstar.com" : window.location.origin;
    const logoutUrl = `https://uim-session-manager-awsstg.morningstar.com/sso/json/logout-browser?clientId=FFeJCcqi1Dvq1nSTJBQxotQXMOPYB9bH&&returnTo=${redirectUrl}`;
    const expiry = window.sessionStorage.getItem('expiry');
    //IF condition to check token is not present or about to expire then generate new token
    if (!expiry || (expiry && (new Date(expiry) - Date.now()) < 60000)) {
      try {
        // axios.defaults.xsrfCookieName = 'csrftoken'
        this.$store.dispatch('generateToken')
          .then(() => {
            this.$store.dispatch('setPermissions');
          })
          .then(() => {
            const token = window.sessionStorage.getItem('token');
            if (token !== 'undefined') {
              if (this.$router.history._startLocation === 'undefined' || this.$router.history._startLocation === '/') {
                this.$router.push({path: "home"});
              } else {
                this.$router.push({path: this.$router.history._startLocation });
              }
              } else {
                window.location.href = logoutUrl;
              }
          })
        } catch(error) {
        if (error.response.status === 401) {
          window.location.href= '/';
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@mds/constants";
@import "@/style/main";
body{
  margin: 0;
   scroll-behavior: smooth;
}
.app{
  margin: 0;
  padding: 0;
  // background-color:$mds-background-color-light-gray;
  background-color:white;
  word-break: break-word;
}
/* scrollBar design starts   */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background: #f8f8f8;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #C2C2C2;
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: #C2C2C2;
  }

</style>