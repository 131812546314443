import axiosCall from '../api/httpClient';
import config from '../config/config.api';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);


async function getCountry(country2ISO){
    return await  httpClient.get(config.GET_COUNTRY_TEXT,{
        params:{
            country2ISO:country2ISO
        }
    }).then((response)=>{
        return response
      }).catch(error=>{
        return error.response
      })
}
async function getLanguage(ietfLanguageTag){
    return await  httpClient.get(config.GET_LANGUAGE_TEXT,{
        params:{
            ietfLanguageTag:ietfLanguageTag
        }
    }).then((response)=>{
        return response
      }).catch(error=>{
        return error.response
      })
}
async function getAllEnumDropDowns(data){
    return await  httpClient.post(config.Enum_DropDowns,data).then((response)=>{
        return response
      }).catch(error=>{
        return error.response
      })
}
async function getAllReferenceDropDowns(data){
  return await  httpClient.post(config.Reference_DropDowns,data).then((response)=>{
      return response
    }).catch(error=>{
      return error.response
    })
}
// const getAssignedItems = () => items;

export {
    getCountry,
    getLanguage,
    getAllEnumDropDowns,
    getAllReferenceDropDowns
}
