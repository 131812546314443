export default function initialState() {
    return {
        dataModelDatapointList: {
            __loading__: false,
            __error__: null
        },
        dataModelLanguageList: {
            __loading__: false,
            __error__: null
        },
        dataModelDataTypeList: {
            __loading__: false,
            __error__: null
        },
        dataModelDatapointDetails: {
            __loading__: false,
            __error__: null
        },
        dataModelAvailableGroupsList: {
            __loading__: false,
            __error__: null
        },
        dataModelDataPointListFromSelectedGroup: {
            __loading__: false,
            __error__: null
        },
        dataModelEnumTypeList: {
            __loading__: false,
            __error__: null
        },
        dataModelReferenceTypeList: {
            __loading__: false,
            __error__: null
        },
        dataModelEnumValueList: {
            __loading__: false,
            __error__: null
        },
        dataModelReferenceValueList: {
            __loading__: false,
            __error__: null
        },
        dataModelValidationsList: {
            __loading__: false,
            __error__: null
        },
        dataModelSaveDataPoint: {
            __loading__: false,
            __error__: null
        },
        dataModelDeleteDataPoint: {
            __loading__: false,
            __error__: null
        }, 
        dataModelSearchDataPoint: {
            __loading__: false,
            __error__: null
        },
    };
}
