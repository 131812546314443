import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { DATA_MODEL_MUTTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

export async function fetchDataModelDataPointsList({ commit }, {
   data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINTS_LIST_IN_PROGRESS);
        }
        const response = await httpClient.get(config.DATA_MODEL_DATAPOINTS,{
            params: {
                    entityTypeId : data.entityTypeId
                },

            })
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINTS_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINTS_LIST_ERROR, error.response);
    }
}

export async function fetchDataModelLanguagesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_LANGUAGES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATA_MODEL_LANGUAGES)
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_LANGUAGES_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_LANGUAGES_LIST_ERROR, error.response.data.message);
    }
}

export async function fetchDataModelDataTypesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATA_TYPES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATA_MODEL_DATA_TYPES)
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATA_TYPES_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATA_TYPES_LIST_ERROR, error.response.data.message);
    }
}

export async function fetchDataModelDataPointDetails({ commit }, {
    data, loadingProgress = false
 } = {}) {
     try { 
         if (!loadingProgress) {
             commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINT_DETAILS_IN_PROGRESS);
         }
         const response = await httpClient.get(config.DATA_MODEL_DATAPOINT_DETAILS,{
             params: {
                    dataPointId : data.dataPointId
                 },
 
             })
         commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINT_DETAILS_COMPLETE, response.data);
     } catch (error) {
         commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINT_DETAILS_ERROR, error.response);
     }
 }

 export async function fetchDataModelAvailableGroups({ commit }, {
    data, loadingProgress = false
 } = {}) {
     try {
         if (!loadingProgress) {
             commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_AVAILABLE_GROUPS_DETAILS_IN_PROGRESS);
         }
         const response = await httpClient.get(config.DATA_MODEL_AVAILABLE_GROUPS_DETAILS,{
             params: {
                    isRepeatingGroup:data.isRepeatingGroup
                 },
 
             })
         commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_AVAILABLE_GROUPS_DETAILS_COMPLETE, response.data);
     } catch (error) {
         commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_AVAILABLE_GROUPS_DETAILS_ERROR, error.response);
     }
 }
 
 export async function fetchDataModelDataPointsFromSelectedGroup({ commit }, {
    data, loadingProgress = false
 } = {}) {
     try {
         if (!loadingProgress) {
             commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINTS_FROM_SELECTED_GROUP_DETAILS_IN_PROGRESS);
         }
         const response = await httpClient.get(config.DATA_MODEL_DATAPOINTSSORTORDER,{
             params: {
                    groupId:data.groupId,
                    entityTypeId:data.entityTypeId
                 },
 
             })
         commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINTS_FROM_SELECTED_GROUP_DETAILS_COMPLETE, response.data);
     } catch (error) {
         commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_DATAPOINTS_FROM_SELECTED_GROUP_DETAILS_ERROR, error.response);
     }
 }

 
export async function fetchDataModelEnumTypesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_ENUM_TYPES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATA_MODEL_ENUM_TYPES)
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_ENUM_TYPES_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_ENUM_TYPES_LIST_ERROR, error.response.data.message);
    }
}

export async function fetchDataModelReferenceTypesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_REFERENCE_TYPES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATA_MODEL_REFERENCE_TYPES)
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_REFERENCE_TYPES_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_REFERENCE_TYPES_LIST_ERROR, error.response.data.message);
    }
}

export async function fetchDataModelEnumValuesList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_ENUM_VALUES_IN_PROGRESS);
        }   
        const response = await httpClient.post(config.DATA_MODEL_ENUM_VALUES, data);
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_ENUM_VALUES_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_ENUM_VALUES_LIST_ERROR, error.response.data.message);
    }
}

export async function fetchDataModelReferenceValuesList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_REFERENCE_VALUES_IN_PROGRESS);
        }
        const response = await httpClient.post(config.DATA_MODEL_REFERENCE_VALUES, data);
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_REFERENCE_VALUES_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_REFERENCE_VALUES_LIST_ERROR, error.response.data.message);
    }
}

export async function fetchDataModelValidationsList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_VALIDATIONS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATA_MODEL_VALIDATIONS)
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_VALIDATIONS_LIST_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DATA_MODEL_VALIDATIONS_LIST_ERROR, error.response.data.message);
    }
}

export async function saveDataPoint({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_SAVEDATAPOINTS_IN_PROGRESS);
        }
        const response = await httpClient.post(config.DATA_MODEL_SAVEDATAPOINT, data);
        commit(DATA_MODEL_MUTTATIONS.SET_SAVEDATAPOINTS_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_SAVEDATAPOINTS_ERROR, error.response.data.message);
    }
}

export async function deleteDataPoint({ commit }, {
    dataPointId , loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_DELETEDATAPOINTS_IN_PROGRESS);
        }
        const response = await httpClient.delete(config.DATA_MODEL_DELETEDATAPOINT,{
            params: {
                dataPointId : dataPointId  
            },
        });
        commit(DATA_MODEL_MUTTATIONS.SET_DELETEDATAPOINTS_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_DELETEDATAPOINTS_ERROR, error.response.data.message);
    }
}

export async function fetchDataModelDatapointsOnSearch({ commit }, {
    data , loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DATA_MODEL_MUTTATIONS.SET_SEARCHDATAPOINTS_IN_PROGRESS);
        }
        const response = await httpClient.get(config.DATA_MODEL_SEARCH_DATAPOINT,{
            params: {
                keyword : data.searchKeyWord  
            },
        });
        commit(DATA_MODEL_MUTTATIONS.SET_SEARCHDATAPOINTS_COMPLETE, response.data);
    } catch (error) {
        commit(DATA_MODEL_MUTTATIONS.SET_SEARCHDATAPOINTS_ERROR, error.response.data.message);
    }
}


 
