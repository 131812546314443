export default function initialState() {
    return {
        alerts: {
            __loading__: false,
            __error__: null
        },
        saveAlerts: {
            __loading__: false,
            __error__: null
        },
        editAlerts: {
            __loading__: false,
            __error__: null
        },
        deleteAlerts: {
            __loading__: false,
            __error__: null
        },
        updateAlerts: {
            __loading__: false,
            __error__: null
        },
    };
}
