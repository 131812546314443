export const DATADISSEMINATION_ACTIONS = {
    FETCH_FILTER_UNIVERSE_LIST: 'fetchFilterUniversesList',
    FETCH_FILTER_TEMPLATE_LIST: 'fetchFilterTemplatesList',
    FETCH_DROPDOWN_UNIVERSE_LIST: 'fetchDropDownUniversesList',
    FETCH_DROPDOWN_TEMPLATE_LIST: 'fetchDropDownTemplatesList',
    FETCH_DATAFEED_LIST: 'fetchDataFeedsList',
    SAVE_DATAFEED_LIST: 'saveDataFeed',
    UPDATE_DATAFEED_LIST: 'updateDataFeed',
    DELETE_DATAFEED_LIST: 'deleteDataFeed',
    GET_UPDATE_DATAFEED_LIST: 'getUpdateDataFeed',
};

export const DATADISSEMINATION_MUTATIONS = {
    SET_CRON_EXPRESSION: 'setCronExpression',

    SET_FILTER_UNIVERSES_IN_PROGRESS: 'setFilterUniversesInProgress',
    SET_FILTER_UNIVERSES_ERROR: 'setFilterUniversesFailure',
    SET_FILTER_UNIVERSES_COMPLETE: 'setFilterUniverses',

    SET_FILTER_TEMPLATES_IN_PROGRESS: 'setFilterTemplatesInProgress',
    SET_FILTER_TEMPLATES_ERROR: 'setFilterTemplatesFailure',
    SET_FILTER_TEMPLATES_COMPLETE: 'setFilterTemplates',

    SET_DROPDOWN_UNIVERSES_IN_PROGRESS: 'setDropDownUniversesInProgress',
    SET_DROPDOWN_UNIVERSES_ERROR: 'setDropDownUniversesFailure',
    SET_DROPDOWN_UNIVERSES_COMPLETE: 'setDropDownUniverses',

    SET_DROPDOWN_TEMPLATES_IN_PROGRESS: 'setDropDownTemplatesInProgress',
    SET_DROPDOWN_TEMPLATES_ERROR: 'setDropDownTemplatesFailure',
    SET_DROPDOWN_TEMPLATES_COMPLETE: 'setDropDownTemplates',

    SET_DATAFEEDS_IN_PROGRESS: 'setDataFeedsInProgress',
    SET_DATAFEEDS_ERROR: 'setDataFeedsFailure',
    SET_DATAFEEDS_COMPLETE: 'setDataFeeds',

    SET_SAVEDATAFEEDS_IN_PROGRESS: 'setSaveDataFeedsInProgress',
    SET_SAVEDATAFEEDS_ERROR: 'setSaveDataFeedsFailure',
    SET_SAVEDATAFEEDS_COMPLETE: 'setSaveDataFeeds',

    SET_UPDATEDATAFEEDS_IN_PROGRESS: 'setUpdateDataFeedsInProgress',
    SET_UPDATEDATAFEEDS_ERROR: 'setUpdateDataFeedsFailure',
    SET_UPDATEDATAFEEDS_COMPLETE: 'setUpdateDataFeeds',

    SET_DELETEDATAFEEDS_IN_PROGRESS: 'setDeleteDataFeedsInProgress',
    SET_DELETEDATAFEEDS_ERROR: 'setDeleteDataFeedsFailure',
    SET_DELETEDATAFEEDS_COMPLETE: 'setDeleteDataFeeds',

    SET_GETUPDATEDATAFEEDS_IN_PROGRESS: 'setGetUpdateDataFeedsInProgress',
    SET_GETUPDATEDATAFEEDS_ERROR: 'setGetUpdateDataFeedsFailure',
    SET_GETUPDATEDATAFEEDS_COMPLETE: 'setGetUpdateDataFeeds'
};
