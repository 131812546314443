import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}
export function setExternalCodesListInProgress(state) {
    state.externalCodesListDetails = {
        __loading__: true,
        __error__: null
    };
}
export function setExternalCodesList(state, externalCodesListDetails) {
    state.externalCodesListDetails = {
        externalCodesListDetails,
        __loading__: false,
        __error__: null
    };
}
export function setExternalCodesListFailure(state, error) {
    state.externalCodesListDetails = {
        __loading__: false,
        __error__: error
    };
}



export function setupdateExternalCodesInProgress(state) {
    state.updateExternalCodes = {
        __loading__: true,
        __error__: null
    };
}
export function setupdateExternalCodes(state, updateExternalCodes) {
    state.updateExternalCodes = {
        updateExternalCodes,
        __loading__: false,
        __error__: null
    };
}
export function setupdateExternalCodesFailure(state, error) {
    state.updateExternalCodes = {
        __loading__: false,
        __error__: error
    };
}
