import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}

// Document Management
export function setDocumentManagementInProgress(state) {
    state.documentManagement = {
        __loading__: true,
        __error__: null
    };
}
export function setDocumentManagement(state, documentManagement) {
    state.documentManagement = {
        documentManagement,
        __loading__: false,
        __error__: null
    };
}
export function setDocumentManagementFailure(state, error) {
    state.documentManagement = {
        __loading__: false,
        __error__: error
    };
}

// Download Document Management
export function setDownloadDocumentManagementInProgress(state) {
    state.downloadDocumentManagement = {
        __loading__: true,
        __error__: null
    };
}
export function setDownloadDocumentManagement(state, downloadDocumentManagement) {
    state.downloadDocumentManagement = {
        downloadDocumentManagement,
        __loading__: false,
        __error__: null
    };
}
export function setDownloadDocumentManagementFailure(state, error) {
    state.downloadDocumentManagement = {
        __loading__: false,
        __error__: error
    };
}

// Upload Document Management
export function setUploadDocumentManagementInProgress(state) {
    state.uploadDocumentManagement = {
        __loading__: true,
        __error__: null
    };
}
export function setUploadDocumentManagement(state, uploadDocumentManagement) {
    state.uploadDocumentManagement = {
        uploadDocumentManagement,
        __loading__: false,
        __error__: null
    };
}
export function setUploadDocumentManagementFailure(state, error) {
    state.uploadDocumentManagement = {
        __loading__: false,
        __error__: error
    };
}

