export const TASK_ACTIONS = {
    FETCH_COMPLETEDTASK_LIST: 'fetchCompletedTaskList',
    FETCH_COMPLETEDTASK_WORKFLOWS:'fetchCompletedWorkFlow',
    FETCH_COMPLETEDTASK_WORKFLOW_STEP:'fetchCompletedWorkFlowSteps',
    FETCH_FILTER_WORKFLOW_LIST: 'fetchFilterWorkflowList',
};

export const TASK_MUTATIONS = {
    SET_COMPLETEDTASK_LIST_IN_PROGRESS: 'setCompletedTaskListInProgress',
    SET_COMPLETEDTASK_LIST_ERROR: 'setCompletedTaskListFailure',
    SET_COMPLETEDTASK_LIST_COMPLETE: 'setCompletedTaskList',

    SET_COMPLETEDTASK_WORKFLOW_IN_PROGRESS: 'setCompletedTaskWorkFlowsInProgress',
    SET_COMPLETEDTASK_WORKFLOW_ERROR: 'setCompletedTaskWorkFlowsFailure',
    SET_COMPLETEDTASK_WORKFLOW_COMPLETE: 'setCompletedTaskWorkFlows',

    SET_COMPLETEDTASK_WORKFLOW_STEPS_IN_PROGRESS: 'setCompletedTaskWorkFlowStepsInProgress',
    SET_COMPLETEDTASK_WORKFLOW_STEPS_ERROR: 'setCompletedTaskWorkFlowStepsFailure',
    SET_COMPLETEDTASK_WORKFLOW_STEPS_COMPLETE: 'setCompletedTaskWorkFlowSteps',

    SET_FILTER_WORKFLOW_IN_PROGRESS: 'setFilterWorkflowInProgress',
    SET_FILTER_WORKFLOW_ERROR: 'setFilterWorkflowFailure',
    SET_FILTER_WORKFLOW_COMPLETE: 'setFilterWorkflow',
};
