export const USER_ACTIONS = {
    FETCH_USER_LIST: 'fetchUsersList',
    FETCH_USER_ROLES: 'fetchUserRoles',
    FETCH_EDIT_USER: 'fetchEditUser',
    FETCH_SAVE_USER: 'fetchSaveUser',
    FETCH_UPDATE_USER: 'fetchUpdateUser',
    FETCH_EXPORT_USER_LIST: 'fetchExportUsersList',
    SEND_MAIL_INVITE: 'sendMailInvite'
};

export const USER_MUTATIONS = {
    SET_USERS_IN_PROGRESS: 'setUsersInProgress',
    SET_USERS_ERROR: 'setUsersFailure',
    SET_USERS_COMPLETE: 'setUsers',

    SET_USER_ROLES_IN_PROGRESS: 'setUserRolesInProgress',
    SET_USER_ROLES_ERROR: 'setUserRolesFailure',
    SET_USER_ROLES_COMPLETE: 'setUserRoles',

    SET_EDIT_USERS_IN_PROGRESS: 'setEditUsersInProgress',
    SET_EDIT_USERS_ERROR: 'setEditUsersFailure',
    SET_EDIT_USERS_COMPLETE: 'setEditUsers',

    SET_SAVE_USERS_IN_PROGRESS: 'setSaveUsersInProgress',
    SET_SAVE_USERS_ERROR: 'setSaveUsersFailure',
    SET_SAVE_USERS_COMPLETE: 'setSaveUsers',

    SET_UPDATE_USERS_IN_PROGRESS: 'setUpdateUsersInProgress',
    SET_UPDATE_USERS_ERROR: 'setUpdateUsersFailure',
    SET_UPDATE_USERS_COMPLETE: 'setUpdateUsers',
    
    SET_EXPORT_USERS_IN_PROGRESS: 'setExportUsersInProgress',
    SET_EXPORT_USERS_ERROR: 'setExportUsersFailure',
    SET_EXPORT_USERS_COMPLETE: 'setExportUsers',

    SEND_MAIL_INVITE_IN_PROGRESS: 'sendMailInviteInProgress',
    SEND_MAIL_INVITE_ERROR: 'sendMailInviteFailure',
    SEND_MAIL_INVITE_COMPLETE: 'sendMailInviteComplete',

};