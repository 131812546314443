import axiosCall from "../api/httpClient.js";
import config from "../config/config.api";
const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);
const httpClientFileDownload = axiosCall(process.env.VUE_APP_BASE_URL, 'blob');

// universe
async function getUniverseData(params) {
  return await httpClient
    .get(config.REPORTING_universes, {
      params: {
        pageNo: params.page,
        pageSize: params.pageSize,
        searchColumn: params.searchColumn,
        searchType: params.searchType,
        searchOwner: params.searchOwner,
        searchStatus: params.searchStatus,
        orderBy: params.orderBy,
        sortType: params.sortType,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getEntityData(params) {
  return await httpClient
    .get(config.ADD_INVESTMENT_ENTITY_DROPDOWNS, {
      params: {
        entitytypeid: params,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getSaveUniverse(data) {
  return await httpClient
    .post(config.REPORTING_saveUniverse, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getEditUniverseData(params) {
  return await httpClient
    .get(config.REPORTING_editGetUniverses, {
      params: {
        listId: params.listid,
        isPublic: params.isPublic
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function deleteUniverseData(params) {
  return await httpClient
    .delete(config.REPORTING_deleteUniverse, {
      params: {
        listIds: params
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function duplicateUniverseData(list,name) {
  return await httpClient.post(config.REPORTING_duplicateUniverse+"?"+'listId='+list+'&newName='+name).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true  
    return error.response
  })
}

async function getUploadEntity(data) {
  let header=data.Header
  return await httpClient
    .post(config.REPORTING_uploadEntity+"?Header="+header, data.Rows)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getOwnerUniverse() {
  return await httpClient
    .get(config.REPORTING_universeOwner)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}
async function shareUniverse(data) {
  return await httpClient
    .post(config.REPORTING_shareUniverse, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function publishUniverse(ids) {
  return await httpClient.post(config.REPORTING_publishUniverse+"?"+'universeIds='+ids).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true  
    return error.response
  })
  
}

async function shareUniverseInformation(ids) {
  return await httpClient
    .get(config.REPORTING_shareUniverseInformation+"?"+'listId='+ids).then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}


// template
async function getTemplateData(params) {
  return await httpClient
    .get(config.REPORTING_templates, {
      params: {
        page: params.page,
        pageSize: params.pageSize,
        searchTemplate: params.searchTemplate,
        searchOwner: params.searchOwner,
        orderByColumn: params.orderByColumn,
        isAscending: params.isAscending,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}
async function getTempDataPointListData(params) {
  return await httpClient
    .get(config.REPORTING_listDataPointTemplates, {
      params: {
        datasetId: params,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}
//Save create template
async function saveCreateTemplate(data) {
  return await httpClient
    .post(config.REPORTING_saveTemplate, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}
//Update Reporting template
async function updateReportingTemplate(data) {
  return await httpClient
    .put(config.REPORTING_saveTemplate, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function deleteTemplateData(data) {
  return await httpClient
    .delete(config.REPORTING_deleteTemplates, {
      params: {
        templateIds: data,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function shareTemplate(data) {
  return await httpClient
    .post(config.REPORTING_shareTemplate, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function publishTemplate(ids,publish) {
  return await httpClient.put(config.REPORTING_publishTemplate+"?"+'templateIds='+ids+'&publish='+publish).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true  
    return error.response
  })
  
}

async function shareTemplateInformation(params) {
  return await httpClient
    .get(config.REPORTING_shareTemplateInformation, {
      params: {
        templateId: params,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}


// reports
async function getReportData(params) {
  return await httpClient
    .get(config.REPORTING_reports, {
      params: {
        pageNo: params.page,
        pageSize: params.pageSize,
        searchTemplate: params.searchTemplate,
        searchUniverse: params.searchUniverse,
        searchOwner: params.searchOwner,
        searchStatus: params.searchStatus,
        startDate: params.startDate,
        endDate: params.endDate,
        orderBy: params.orderBy,
        sortType: params.sortType
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}
//Save run report
async function saveRunReport(templateId,universeId,data) {
  return await httpClient
    .post(config.REPORTING_runReport+"?"+'templateId='+templateId+'&universeId='+universeId,data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

//single download report
async function downloadReport(params) {
  return await httpClientFileDownload
    .get(config.REPORTING_singleDownloadReport,{
      params: {
        ReportId: params,
      },
    }).then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

//multiple download report
async function downloadReports(data) {
  return await httpClientFileDownload
    .post(config.REPORTING_multiDownloadReport, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

//multiple re run report
async function reRunReport(data) {
  return await httpClient.post(config.REPORTING_multiReRunReport+"?"+'reportIds='+data).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true  
    return error.response
  })
}

//report statuses
async function getStatuses() {
  return await httpClient
    .get(config.REPORTING_status)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

//report owner
async function getReportOwner() {
  return await httpClient
    .get(config.REPORTING_reportOwner)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function deleteReport(Guids) {
  return await httpClient.delete(config.REPORTING_deleteReport+"?"+'Guids='+Guids).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true  
    return error.response
  })
}

// services for data-set
async function getDataSetData(params) {
  return await httpClient
    .get(config.REPORTING_dataSets, {
      params: {
        Page: params.Page,
        pageSize: params.pageSize,
        searchDataSet: params.searchDataSet,
        searchOwner: params.searchOwner,
        searchStatus: params.searchStatus,
        orderByColumn: params.orderByColumn,
        isAscending: params.isAscending,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getSaveDataSet(data) {
  return await httpClient
    .post(config.REPORTING_saveDataSets, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getDataSetDataPointListData(params) {
  return await httpClient
    .get(config.REPORTING_dataset, {
      params: {
        datasetId: params,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getEditDataSet(data) {
  return await httpClient
    .put(config.REPORTING_editDataSet, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function deleteDataSet(params) {
  return await httpClient
    .delete(config.REPORTING_deleteDataSet, {
      params: {
        dataSetIds: params,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getOwnerDataSets() {
  return await httpClient
    .get(config.REPORTING_ownerList)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function shareDataSet(data) {
  return await httpClient
    .post(config.REPORTING_shareDataSet, data)
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function publishDataSet(ids,publish) {
  return await httpClient.put(config.REPORTING_publishDataSet+"?"+'dataSetIds='+ids+'&publish='+publish).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true  
    return error.response
  })
  
}


async function shareDataSetInformation(params) {
  return await httpClient
    .get(config.REPORTING_shareDataSetInformation, {
      params: {
        dataSetId: params,
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function duplicateTemplateData(params) {
  return await httpClient
    .post(config.REPORTING_duplicateTemplates, params)
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

async function getEditTemplateList(data) {
  return await httpClient
    .get(config.REPORTING_getTemplates, {
      params: {
        templateId : data,
      },
    })
    .then(async (response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

//universe additional search criteria 
async function additionalSearchApply(page, pageSize, search, data) {
  return await httpClient
    .post(config.REPORTING_additionalSearchApply+"?page="+page +"&pageSize="+pageSize+ "&searchEntity="+search, data)
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

//Universe static Entity list with pangination
async function getEntityListData(params) {
  return await httpClient
    .get(config.Bulk_Edit_getEntityList, {
      params: {
        entityTypeId: params.entityTypeId,
        page: params.page,
        pageSize: params.pageSize,
        search:params.search,
        status: 'approved'
      },
    })
    .then((response) => {
      response["isError"] = false;
      return response;
    })
    .catch((error) => {
      error.response["isError"] = true;
      return error.response;
    });
}

export {
  getUniverseData,
  getSaveUniverse,
  getEntityData,
  getTemplateData,
  getReportData,
  saveRunReport,
  saveCreateTemplate,
  updateReportingTemplate,
  getTempDataPointListData,
  getDataSetData,
  getSaveDataSet,
  downloadReport,
  downloadReports,
  getEditUniverseData,
  deleteUniverseData,
  duplicateUniverseData,
  getDataSetDataPointListData,
  getEditDataSet,
  deleteDataSet,
  getOwnerDataSets,
  shareDataSet,
  publishDataSet,
  shareDataSetInformation,
  getOwnerUniverse,
  deleteTemplateData,
  duplicateTemplateData,
  getEditTemplateList,
  getUploadEntity,
  deleteReport,
  additionalSearchApply,
  getStatuses,
  reRunReport,
  getReportOwner,
  shareUniverse,
  publishUniverse,
  shareUniverseInformation,
  shareTemplate,
  publishTemplate,
  shareTemplateInformation,
  getEntityListData
};
