// import items from '../../assignedItems.json';
import { getAssignedItems,getAssignedTaskDetail,getWorkflowComments,getCompletedTaskDetail} from '../../services/assigned_to_you_service.js';

const namespaced= true
const state = {
  assignedItems:[],
  assignedTaskDetails:[],
  taskDetailsComments:[],
  pageSize: 10,
}

const getters = {
  getAssignedItems(state) {
    return state.assignedItems;
  },
  getAssignedTaskDetail(state){
    return state.assignedTaskDetails;
  },
  getTaskDetailComments(state){
    return state.taskDetailsComments;
  },
  getPageSize(state) {
    return state.pageSize;
  }
}

const mutations = {
  setAssignedItems(state, data) {
    state.assignedItems = data;
  },
  setAssignedTaskDetails(state,data){
    state.assignedTaskDetails = data
  },
  setTaskDetailsComments(state,data){
    state.taskDetailsComments = data
  },
  setPageSize(state, data){
    state.pageSize = data;
  }
}

const actions = {
  async setAssignedItems(context, data) {
    try {
      let response = await getAssignedItems(data);
      context.commit('setAssignedItems', response);
    } catch (error) {
      console.log(error);
    }
  },
  async getAssignedTaskDetailList({commit},data){
    try{
      let response=null
      console.log(data.taskType);
      if(data.taskType == "completed"){
        response = await getCompletedTaskDetail(data.taskId); 
      }else{
        response = await getAssignedTaskDetail(data.taskId); 
      }
           
      let taskDetails = response.data
        taskDetails.taskpayload = JSON.parse(taskDetails.taskpayload)
        taskDetails.objectDetail=JSON.parse(taskDetails.objectDetail)
      commit('setAssignedTaskDetails',response)

      return response
      // (ticket.taskpayload = JSON.parse(ticket.taskpayload))
    }catch(error){
      console.log(error);
    }
  },
  async getAssignedTaskDetailComments({commit},workflowTaskId){
    console.log(workflowTaskId);
    try{
      let response = await getWorkflowComments(workflowTaskId)
      console.log(response);
      commit('setTaskDetailsComments',response)
      return response
      // (ticket.taskpayload = JSON.parse(ticket.taskpayload))
    }catch(error){
      console.log(error);
    }
  }
}

export default {
  namespace:'assignedToYou',
  namespaced,
  state,
  getters,
  mutations,
  actions
}