export const DOCUMENTMANAGEMENT_ACTIONS = {
    FETCH_DOCUMENTMANAGEMENT_LIST: 'fetchDocumentManagementList',
    FETCH_DOWNLOAD_DOCUMENTMANAGEMENT: 'fetchDownloadDocumentManagement',
    FETCH_UPLOAD_DOCUMENTMANAGEMENT: 'fetchUploadDocumentManagement'
};

export const DOCUMENTMANAGEMENT_MUTATIONS = {

    SET_DOCUMENTMANAGEMENT_IN_PROGRESS: 'setDocumentManagementInProgress',
    SET_DOCUMENTMANAGEMENT_ERROR: 'setDocumentManagementFailure',
    SET_DOCUMENTMANAGEMENT_COMPLETE: 'setDocumentManagement',

    SET_DOWNLOAD_DOCUMENTMANAGEMENT_IN_PROGRESS: 'setDownloadDocumentManagementInProgress',
    SET_DOWNLOAD_DOCUMENTMANAGEMENT_ERROR: 'setDownloadDocumentManagementFailure',
    SET_DOWNLOAD_DOCUMENTMANAGEMENT_COMPLETE: 'setDownloadDocumentManagement',

    SET_UPLOAD_DOCUMENTMANAGEMENT_IN_PROGRESS: 'setUploadDocumentManagementInProgress',
    SET_UPLOAD_DOCUMENTMANAGEMENT_ERROR: 'setUploadDocumentManagementFailure',
    SET_UPLOAD_DOCUMENTMANAGEMENT_COMPLETE: 'setUploadDocumentManagement',


};
