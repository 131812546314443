export default function initialState() {
    return {
        universes: {
            __loading__: false,
            __error__: null
        },
        templates: {
            __loading__: false,
            __error__: null
        },
        templateOwnerList: {
            __loading__: false,
            __error__: null
        },
        dataSets: {
            __loading__: false,
            __error__: null
        },
        userList: {
            __loading__: false,
            __error__: null 
        },
        reportStatus: {
            __loading__: false,
            __error__: null 
        },
    };
}
