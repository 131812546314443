import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { REPORTING_MUTATIONS } from './constants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

export async function fetchUniversesList({ commit }, {
    page = 1, pageSize = 0, searchColumn, searchType,
    searchOwner, searchStatus, orderBy, sortType, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(REPORTING_MUTATIONS.SET_UNIVERSES_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.REPORTING_universes, {
                params: {
                    pageNo: page,
                    pageSize: pageSize,
                    searchColumn: searchColumn,
                    searchType: searchType,
                    searchOwner: searchOwner,
                    searchStatus: searchStatus,
                    orderBy: orderBy,
                    sortType: sortType,
                },
            });
        commit(REPORTING_MUTATIONS.SET_UNIVERSES_COMPLETE, response.data.universelist);
    } catch (error) {
        commit(REPORTING_MUTATIONS.SET_UNIVERSES_ERROR, error.response.data.message);
    }
}


export async function fetchTemplateList({ commit }, {
    page = 1, pageSize = 0, searchTemplate, searchOwner, searchStatus, orderByColumn = 'updateddate',
    isAscending, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(REPORTING_MUTATIONS.SET_TEMPLATES_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.REPORTING_templates, {
                params: {
                    page,
                    pageSize,
                    searchTemplate,
                    searchOwner,
                    searchStatus,
                    orderByColumn,
                    isAscending,
                },
            });
        commit(REPORTING_MUTATIONS.SET_TEMPLATES_COMPLETE, response.data);
    } catch (error) {
        commit(REPORTING_MUTATIONS.SET_TEMPLATES_ERROR, error.response.data.message);
    }
  }

  export async function fetchTemplateOwnerList({ commit }, {loadingProgress = false} = {}) {
    try {
        if (!loadingProgress) {
            commit(REPORTING_MUTATIONS.SET_TEMPLATES_OWNER_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.REPORTING_templateOwner);
        commit(REPORTING_MUTATIONS.SET_TEMPLATES_OWNER_COMPLETE, response.data);
    } catch (error) {
        commit(REPORTING_MUTATIONS.SET_TEMPLATES_OWNER_ERROR, error.response.data.message);
    }
  }

  export async function fetchDataSetsList({ commit }, {
    Page = 1, pageSize = 0, searchDataSet, searchOwner, searchStatus, orderByColumn,
    isAscending, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(REPORTING_MUTATIONS.SET_DATASETS_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.REPORTING_dataSets, {
                params: {
                    Page,
                    pageSize,
                    searchDataSet,
                    searchOwner,
                    searchStatus,
                    orderByColumn,
                    isAscending,
                },
            });
        commit(REPORTING_MUTATIONS.SET_DATASETS_COMPLETE, response.data.dataset);
    } catch (error) {
        commit(REPORTING_MUTATIONS.SET_DATASETS_ERROR, error.response.data.message);
    }
  }


  export async function fetchUserList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(REPORTING_MUTATIONS.SET_USERLISTS_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.REPORTING_getAllUsers);
        commit(REPORTING_MUTATIONS.SET_USERLISTS_COMPLETE, response);
    } catch (error) {
        commit(REPORTING_MUTATIONS.SET_USERLISTS_ERROR, error.response.data.message);
    }
  }

  export async function fetchReportStatus({ commit }, {
      loadingProgress = false, reportPendingIds = "", reportRunningIds = "" } = {}) {
    try {
        if (!loadingProgress) {
            commit(REPORTING_MUTATIONS.SET_REPORT_STATUS_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.REPORTING_sendGuide, {
                params: {reportPendingIds, reportRunningIds}
            });
        commit(REPORTING_MUTATIONS.SET_REPORT_STATUS_COMPLETE, response.data);
    } catch (error) {
        commit(REPORTING_MUTATIONS.SET_REPORT_STATUS_ERROR, error.response.data.message);
    }
  }
