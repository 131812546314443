export const REPORTING_ACTIONS = {
    FETCH_UNIVERSE_LIST: 'fetchUniversesList',
    FETCH_DATASETS_LIST: 'fetchDataSetsList',
    FETCH_TEMPLATE_LIST: 'fetchTemplateList',
    FETCH_TEMPLATE_OWNER_LIST: 'fetchTemplateOwnerList',
    FETCH_USER_LIST: 'fetchUserList',
    FETCH_REPORT_STATUS: 'fetchReportStatus',
};

export const REPORTING_MUTATIONS = {
    SET_UNIVERSES_IN_PROGRESS: 'setUniversesInProgress',
    SET_UNIVERSES_ERROR: 'setUniversesFailure',
    SET_UNIVERSES_COMPLETE: 'setUniverses',

    SET_TEMPLATES_IN_PROGRESS: 'setTemplatesInProgress',
    SET_TEMPLATES_ERROR: 'setTemplatesFailure',
    SET_TEMPLATES_COMPLETE: 'setTemplates',

    SET_TEMPLATES_OWNER_IN_PROGRESS: 'setTemplatesOwnerInProgress',
    SET_TEMPLATES_OWNER_ERROR: 'setTemplatesOwnerFailure',
    SET_TEMPLATES_OWNER_COMPLETE: 'setTemplatesOwner',

    SET_DATASETS_IN_PROGRESS: 'setDataSetsInProgress',
    SET_DATASETS_ERROR: 'setDataSetsFailure',
    SET_DATASETS_COMPLETE: 'setDataSets',

    SET_USERLISTS_IN_PROGRESS: 'setUserListsInProgress',
    SET_USERLISTS_ERROR: 'setUserListsFailure',
    SET_USERLISTS_COMPLETE: 'setUserLists',

    SET_REPORT_STATUS_IN_PROGRESS: 'setReportStatusInProgress',
    SET_REPORT_STATUS_ERROR: 'setReportStatusFailure',
    SET_REPORT_STATUS_COMPLETE: 'setReportStatus',
};
