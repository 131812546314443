import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { SCHEDULE_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);
const httpClientFileDownload = axiosCall(process.env.VUE_APP_BASE_URL, 'blob');

export async function fetchFilterPartnersList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_FILTER_PARTNERS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_filterPartner)
        commit(SCHEDULE_MUTATIONS.SET_FILTER_PARTNERS_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_FILTER_PARTNERS_ERROR, error.response.data.message);
    }
}

export async function fetchFilterDeliveryMethodsList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_FILTER_DELIVERYMETHODS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_filterDeliveryMethod)
        commit(SCHEDULE_MUTATIONS.SET_FILTER_DELIVERYMETHODS_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_FILTER_DELIVERYMETHODS_ERROR, error.response.data.message);
    }
}

export async function fetchDropDownDeliveryMethodsList({ commit }, {
    val ,loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_DROPDOWN_DELIVERYMETHODS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_dropDownDeliveryMethod
            ,{
            params: {
                VendorId :val
            },
        }
        )
        commit(SCHEDULE_MUTATIONS.SET_DROPDOWN_DELIVERYMETHODS_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_DROPDOWN_DELIVERYMETHODS_ERROR, error.response.data.message);
    }
}

export async function fetchScheduleList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_SCHEDULES_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DATADISSEMINATION_getSchedule, {
                params: {
                    page: data.page,
                    pageSize: data.pageSize,
                    date: data.date,
                    searchFeed: data.searchFeed,
                    searchPartner: data.searchPartner,
                    searchDelivery: data.searchDelivery,
                    searchStatus: data.searchStatus,
                    orderByColumn: data.orderByColumn,
                    isAscending: data.isAscending,
                },
            });
        commit(SCHEDULE_MUTATIONS.SET_SCHEDULES_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_SCHEDULES_ERROR, error.response.data.message);
    }
}

export async function fetchScheduleFileList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_SCHEDULEFILES_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DATADISSEMINATION_getScheduleFile, {
                params: {
                    ScheduledFeedId : data.ScheduledFeedId ,
                    methodTypeId : data.methodTypeId,
                    onTime: data.onTime,
                    onDelivered: data.onDelivered,
                    date : data.date,
                    
                },
            });
        commit(SCHEDULE_MUTATIONS.SET_SCHEDULEFILES_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_SCHEDULEFILES_ERROR, error.response.data.message);
    }
}


export async function fetchEditScheduleList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_EDITSCHEDULES_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DATADISSEMINATION_editSchedule, {
                params: {
                    ScheduledFeedId : data.ScheduledFeedId
                    
                },
            });
        commit(SCHEDULE_MUTATIONS.SET_EDITSCHEDULES_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_EDITSCHEDULES_ERROR, error.response.data.message);
    }
}

export async function fetchDropDownDataFeedsList({ commit }, {
     loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_DROPDOWN_DATAFEEDS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_dropDownDataFeed)
        commit(SCHEDULE_MUTATIONS.SET_DROPDOWN_DATAFEEDS_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_DROPDOWN_DATAFEEDS_ERROR, error.response.data.message);
    }
}

export async function fetchEnableScheduleList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_ENABLESCHEDULES_IN_PROGRESS);
        }
        const response = await httpClient
            .post(config.DATADISSEMINATION_enableSchedule,null, {
                params: {
                    ScheduledFeedId : data.ScheduledFeedId,
                    enable : data.enable,
                    
                },
            });
        commit(SCHEDULE_MUTATIONS.SET_ENABLESCHEDULES_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_ENABLESCHEDULES_ERROR, error.response.data.message);
    }
}

export async function fetchSaveScheduleList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_SAVESCHEDULES_IN_PROGRESS);
        }
        const response = await httpClient.post(config.DATADISSEMINATION_saveSchedule, data);
        commit(SCHEDULE_MUTATIONS.SET_SAVESCHEDULES_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_SAVESCHEDULES_ERROR, error.response.data.message);
    }
}

export async function fetchDeleteScheduleList({ commit }, {
    ScheduledFeedId, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_DELETESCHEDULES_IN_PROGRESS);
        }
        const response = await httpClient.delete(config.DATADISSEMINATION_deleteSchedule,{
            params: {
                ScheduledFeedId: ScheduledFeedId 
            },
        });
        commit(SCHEDULE_MUTATIONS.SET_DELETESCHEDULES_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_DELETESCHEDULES_ERROR, error.response.data.message);
    }
}

export async function runSchedule({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_RUNSCHEDULE_IN_PROGRESS);
        }
        const response = await httpClient
            .post(config.DATADISSEMINATION_runSchedule,null, {
                params: {
                    ScheduledFeedId : data,                    
                },
            });
        commit(SCHEDULE_MUTATIONS.SET_RUNSCHEDULE_COMPLETE, response.data);
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_RUNSCHEDULE_ERROR, error.response.data.message);
    }
}

export async function downloadSchedule({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(SCHEDULE_MUTATIONS.SET_DOWNLOADSCHEDULE_IN_PROGRESS);
        }
        if(data.isvelotemplate === true) {
            const response = await httpClientFileDownload
            .get(config.DATADISSEMINATION_downloadScheduleVelo, {
                params: {
                    name : data.filename 
                },
            });
            commit(SCHEDULE_MUTATIONS.SET_DOWNLOADSCHEDULE_COMPLETE, response);
        } else {
            const response = await httpClientFileDownload
            .get(config.DATADISSEMINATION_downloadSchedule, {
                params: {
                    fileId : data.fileid
                },
            });
            commit(SCHEDULE_MUTATIONS.SET_DOWNLOADSCHEDULE_COMPLETE, response);
        }
    } catch (error) {
        commit(SCHEDULE_MUTATIONS.SET_DOWNLOADSCHEDULE_ERROR, error.response.data.message);
    }
}