// import items from '../../assignedItems.json';
import { getAllEnumDropDowns,getAllReferenceDropDowns} from '../../services/get_default_values.service.js';

const state = {
  enumIdList:[],
  referenceIdList:[],
  enumDropDowns:[],
  referenceDropDowns:[],
}

const getters = {
  getEnumDropDowns(state) {
    return state.enumDropDowns;
  },
  getReferenceDropDowns(state) {
    return state.referenceDropDowns;
  },
  getEnumIdList(state) {
    return state.enumIdList;
  },
  getReferenceIdList(state) {
    return state.referenceIdList;
  }
}

const mutations = {
  setEnumDropDowns(state, data) {
    state.enumDropDowns.push(data);
  },
  setReferenceDropDowns(state, data) {
    state.referenceDropDowns.push(data);
  },
  updateReferenceDropDowns(state,data){
    for(let i=0;i<state.referenceDropDowns.length;i++){
      if(state.referenceDropDowns[i].refrenceid == data.id){
        state.referenceDropDowns[i].values = data.values
      }
    }
  },
  setEnumIdList(state, data) {
     state.enumIdList=data;
  },
  setReferenceIdList(state, data) {
     state.referenceIdList=data;
  }
}

const actions = {
    async fetchEnumDropDownDetails({commit},data){
        try{
            const response = await getAllEnumDropDowns(data);
            for(let i=0;i<response.data.length;i++){
             commit('setEnumDropDowns',response.data[i])
            }
        }catch(error){
            //handle the error
        }
    },
    async fetchReferenceDropDownDetails({commit},data){
      try{
          const response = await getAllReferenceDropDowns(data);
          for(let i=0;i<response.data.length;i++){
           commit('setReferenceDropDowns',response.data[i])
          }
      }catch(error){
          //handle the error
      }
  },
}

export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}