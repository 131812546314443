import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}
// Filter Universe
export function setFilterUniversesInProgress(state) {
    state.filterUniverses = {
        __loading__: true,
        __error__: null
    };
}
export function setFilterUniverses(state, filterUniverses) {
    state.filterUniverses = {
        filterUniverses,
        __loading__: false,
        __error__: null
    };
}
export function setFilterUniversesFailure(state, error) {
    state.filterUniverses = {
        __loading__: false,
        __error__: error
    };
}

// Filter Template
export function setFilterTemplatesInProgress(state) {
    state.filterTemplates = {
        __loading__: true,
        __error__: null
    };
}
export function setFilterTemplates(state, filterTemplates) {
    state.filterTemplates = {
        filterTemplates,
        __loading__: false,
        __error__: null
    };
}
export function setFilterTemplatesFailure(state, error) {
    state.filterTemplates = {
        __loading__: false,
        __error__: error
    };
}

// DropDown Universe
export function setDropDownUniversesInProgress(state) {
    state.dropDownUniverses = {
        __loading__: true,
        __error__: null
    };
}
export function setDropDownUniverses(state, dropDownUniverses) {
    state.dropDownUniverses = {
        dropDownUniverses,
        __loading__: false,
        __error__: null
    };
}
export function setDropDownUniversesFailure(state, error) {
    state.dropDownUniverses = {
        __loading__: false,
        __error__: error
    };
}

// DropDown Template
export function setDropDownTemplatesInProgress(state) {
    state.dropDownTemplates = {
        __loading__: true,
        __error__: null
    };
}
export function setDropDownTemplates(state, dropDownTemplates) {
    state.dropDownTemplates = {
        dropDownTemplates,
        __loading__: false,
        __error__: null
    };
}
export function setDropDownTemplatesFailure(state, error) {
    state.dropDownTemplates = {
        __loading__: false,
        __error__: error
    };
}

// Data Feeds
export function setDataFeedsInProgress(state) {
    state.dataFeeds = {
        __loading__: true,
        __error__: null
    };
}
export function setDataFeeds(state, dataFeeds) {
    state.dataFeeds = {
        dataFeeds,
        __loading__: false,
        __error__: null
    };
}
export function setDataFeedsFailure(state, error) {
    state.dataFeeds = {
        __loading__: false,
        __error__: error
    };
}

//Save Data Feeds
export function setSaveDataFeedsInProgress(state) {
    state.saveDataFeeds = {
        __loading__: true,
        __error__: null
    };
}
export function setSaveDataFeeds(state, saveDataFeeds) {
    state.saveDataFeeds = {
        saveDataFeeds,
        __loading__: false,
        __error__: null
    };
}
export function setSaveDataFeedsFailure(state, error) {
    state.saveDataFeeds = {
        __loading__: false,
        __error__: error
    };
}

//Update Data Feeds
export function setUpdateDataFeedsInProgress(state) {
    state.updateDataFeeds = {
        __loading__: true,
        __error__: null
    };
}
export function setUpdateDataFeeds(state, updateDataFeeds) {
    state.updateDataFeeds = {
        updateDataFeeds,
        __loading__: false,
        __error__: null
    };
}
export function setUpdateDataFeedsFailure(state, error) {
    state.updateDataFeeds = {
        __loading__: false,
        __error__: error
    };
}

//Delete Data Feeds
export function setDeleteDataFeedsInProgress(state) {
    state.deleteDataFeeds = {
        __loading__: true,
        __error__: null
    };
}
export function setDeleteDataFeeds(state, deleteDataFeeds) {
    state.deleteDataFeeds = {
        deleteDataFeeds,
        __loading__: false,
        __error__: null
    };
}
export function setDeleteDataFeedsFailure(state, error) {
    state.deleteDataFeeds = {
        __loading__: false,
        __error__: error
    };
}

//Get Update Data Feeds
export function setGetUpdateDataFeedsInProgress(state) {
    state.getUpdateDataFeeds = {
        __loading__: true,
        __error__: null
    };
}
export function setGetUpdateDataFeeds(state, getUpdateDataFeeds) {
    state.getUpdateDataFeeds = {
        getUpdateDataFeeds,
        __loading__: false,
        __error__: null
    };
}
export function setGetUpdateDataFeedsFailure(state, error) {
    state.getUpdateDataFeeds = {
        __loading__: false,
        __error__: error
    };
}

// Cron
export function setCronExpression(state, setCronExpression) {
    state.setCronExpression = setCronExpression
}
