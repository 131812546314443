import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { PARTNER_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);


export async function fetchPartnerList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(PARTNER_MUTATIONS.SET_PARTNERS_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DATADISSEMINATION_getPartner, {
                params: {
                    page: data.page,
                    pageSize: data.pageSize,
                    searchPartner: data.searchPartner,
                    orderByColumn: data.orderByColumn,
                    isAscending: data.isAscending,
                },
            });
        commit(PARTNER_MUTATIONS.SET_PARTNERS_COMPLETE, response.data);
    } catch (error) {
        commit(PARTNER_MUTATIONS.SET_PARTNERS_ERROR, error.response.data.message);
    }
}

// delete partner
export async function fetchDeletePartnerList({ commit }, {
    partnerIds, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(PARTNER_MUTATIONS.SET_DELETEPARTNERS_IN_PROGRESS);
        }
        const response = await httpClient.delete(config.DATADISSEMINATION_deletePartner,{
            params: {
                partnerIds: partnerIds 
            },
        });
        commit(PARTNER_MUTATIONS.SET_DELETEPARTNERS_COMPLETE, response.data);
    } catch (error) {
        commit(PARTNER_MUTATIONS.SET_DELETEPARTNERS_ERROR, error.response.data.message);
    }
}

// dropdowm delivery-method
export async function fetchDropDownDeliveryTypesList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(PARTNER_MUTATIONS.SET_DROPDOWN_DELIVERYTYPES_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_dropDownDeliveryTypes)
        commit(PARTNER_MUTATIONS.SET_DROPDOWN_DELIVERYTYPES_COMPLETE, response.data);
    } catch (error) {
        commit(PARTNER_MUTATIONS.SET_DROPDOWN_DELIVERYTYPES_ERROR, error.response.data.message);
    }
}

// save partner
export async function fetchSavePartnerList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(PARTNER_MUTATIONS.SET_SAVEPARTNERS_IN_PROGRESS);
        }
        const response = await httpClient.post(config.DATADISSEMINATION_savePartner, data);
        commit(PARTNER_MUTATIONS.SET_SAVEPARTNERS_COMPLETE, response.data);
    } catch (error) {
        commit(PARTNER_MUTATIONS.SET_SAVEPARTNERS_ERROR, error.response.data.message);
    }
}

// edit partner
export async function fetchEditPartnerList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(PARTNER_MUTATIONS.SET_EDITPARTNERS_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DATADISSEMINATION_editPartner, {
                params: {
                    partnerId : data.partnerId
                    
                },
            });
        commit(PARTNER_MUTATIONS.SET_EDITPARTNERS_COMPLETE, response.data);
    } catch (error) {
        commit(PARTNER_MUTATIONS.SET_EDITPARTNERS_ERROR, error.response.data.message);
    }
}


// update partner
export async function fetchUpdatePartnerList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(PARTNER_MUTATIONS.SET_UPDATEPARTNERS_IN_PROGRESS);
        }
        const response = await httpClient.post(config.DATADISSEMINATION_updatePartner, data);
        commit(PARTNER_MUTATIONS.SET_UPDATEPARTNERS_COMPLETE, response.data);
    } catch (error) {
        commit(PARTNER_MUTATIONS.SET_UPDATEPARTNERS_ERROR, error.response.data.message);
    }
}

// dropdowm FTP server
export async function fetchDropDownFTPServerList({ commit }, {
    loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(PARTNER_MUTATIONS.SET_DROPDOWN_FTPSERVERS_IN_PROGRESS);
        }
        const response = await httpClient
        .get(config.DATADISSEMINATION_ftpServer)
        commit(PARTNER_MUTATIONS.SET_DROPDOWN_FTPSERVERS_COMPLETE, response.data);
    } catch (error) {
        commit(PARTNER_MUTATIONS.SET_DROPDOWN_FTPSERVERS_ERROR, error.response.data.message);
    }
}
