export const DASHBOARD_ACTIONS = {
    FETCH_FUND_UNIVERSE_MATRIX_LIST: 'fetchFundUniverseMatrixList',
    FETCH_WORKFLOW_HEALTH_LIST: 'fetchWorkflowHealthList',
    FETCH_EVENT_LIST: 'fetchEventList',
};

export const DASHBOARD_MUTATIONS = {

    SET_FUND_UNIVERSE_MATRIX_IN_PROGRESS: 'setFundUniverseMatrixInProgress',
    SET_FUND_UNIVERSE_MATRIX_ERROR: 'setFundUniverseMatrixFailure',
    SET_FUND_UNIVERSE_MATRIX_COMPLETE: 'setFundUniverseMatrix',

    SET_WORKFLOW_HEALTH_IN_PROGRESS: 'setWorkflowHealthInProgress',
    SET_WORKFLOW_HEALTH_ERROR: 'setWorkflowHealthFailure',
    SET_WORKFLOW_HEALTH_COMPLETE: 'setWorkflowHealth',
    
    SET_EVENT_IN_PROGRESS: 'setEventInProgress',
    SET_EVENT_ERROR: 'setEventFailure',
    SET_EVENT_COMPLETE: 'setEvent',
};


