import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { DOCUMENTMANAGEMENT_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);
const httpClientFileDownload = axiosCall(process.env.VUE_APP_BASE_URL, 'blob');

export async function fetchDocumentManagementList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_DOCUMENTMANAGEMENT_IN_PROGRESS);
        }
        const response = await httpClient
            .post(config.DOCUMENTMANAGEMENT_getDocumentManagementList,data);
        commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_DOCUMENTMANAGEMENT_COMPLETE, response.data);
    } catch (error) {
        commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_DOCUMENTMANAGEMENT_ERROR, error.response.data.message);
    }
}

export async function fetchDownloadDocumentManagement({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_DOWNLOAD_DOCUMENTMANAGEMENT_IN_PROGRESS);
        }
        const response = await httpClientFileDownload
            .get(config.DOCUMENTMANAGEMENT_downloadDocumentManagement + data);
        commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_DOWNLOAD_DOCUMENTMANAGEMENT_COMPLETE, response);
    } catch (error) {
        commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_DOWNLOAD_DOCUMENTMANAGEMENT_ERROR, error.response.data.message);
    }
}

export async function fetchUploadDocumentManagement({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_UPLOAD_DOCUMENTMANAGEMENT_IN_PROGRESS);
        }
        const response = await httpClient
            .post(config.DOCUMENTMANAGEMENT_uploadDocumentManagement, data);
        commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_UPLOAD_DOCUMENTMANAGEMENT_COMPLETE, response.data);
    } catch (error) {
        commit(DOCUMENTMANAGEMENT_MUTATIONS.SET_UPLOAD_DOCUMENTMANAGEMENT_ERROR, error.response.data.message);
    }
}

