export default function initialState() {
    return {
        partners: {
            __loading__: false,
            __error__: null
        },
        deletePartners: {
            __loading__: false,
            __error__: null
        },
        dropDownDeliveryTypes: {
            __loading__: false,
            __error__: null
        },
        savePartners: {
            __loading__: false,
            __error__: null
        },
        editPartners: {
            __loading__: false,
            __error__: null
        },
        updatePartners: {
            __loading__: false,
            __error__: null
        },
        dropDownFTPServers: {
            __loading__: false,
            __error__: null
        },
    };
}
