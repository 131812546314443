import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { ACTIVITYHISTORY_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

export async function fetchActivityHistoryList({ commit }, {
   data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(ACTIVITYHISTORY_MUTATIONS.SET_ACTIVITY_HISTORY_LIST_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.ACTIVITY_HISTORY_getActivityHistoryList,{
                params: {
                    page: data.Page,
                    pageSize: data.PageSize,
                    isAscending: data.IsAscending,
                    searchDatapoint:data.searchDatapoint,
                    searchEntityType:data.searchEntityType,
                    fromDate:data.fromDate,
                    toDate:data.toDate,
                    searchEntity:data.searchEntity,
                    orderByColumn:data.orderByColumn,
                    UpdatedBy: data.UpdatedBy,
                    
                },

            })
        commit(ACTIVITYHISTORY_MUTATIONS.SET_ACTIVITY_HISTORY_LIST_COMPLETE, response.data);
    } catch (error) {
        console.log(error.response);
        commit(ACTIVITYHISTORY_MUTATIONS.SET_ACTIVITY_HISTORY_LIST_ERROR, error.response);
    }
}
