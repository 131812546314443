import axiosCall from "@/api/httpClient.js";
import { elements } from '../../config/default.json';
import config from '../../config/config.api';
const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);
/* eslint-disable */

export default {
    state: {
        token: null,
        userPermissionModel: {},
        userP: null
    },
    getters: {
        isAuthenticated(stateData) {
            return stateData.token !== null;
        }
    },
    actions: {
        generateToken({ rootState: { config }, dispatch }) {
            return getToken(config, dispatch);
        },
        setToken({commit}, payload) {
            commit("setToken", payload);
        },
        logout({ rootState: { config }, commit}) {
            return deleteToken(config, commit);
        },
        setPermissions({commit}) {
            const data = [];
            try {
                const userPermissionModel = JSON.parse(window.sessionStorage.getItem("permissions"));
                console.log(userPermissionModel.userPermissions, 45);
                userPermissionModel.userPermissions.forEach(item => {
                    item.rights.forEach(element => {
                        data.push([item.module, element]);
                    });
                });
            } catch (e) {
                // console.error(e);
            }
            const permissionData = [];
            let entity;
            data.forEach(permission => {
                entity = elements[permission[0]];
                if (entity && entity[permission[1]]) {
                    entity[permission[1]].map(item => permissionData.push(item));
                } else if (permission[1] === "*") {
                    if (entity) {
                        for(const method in entity) {
                            entity[method].map(item => permissionData.push(item));
                        }
                    } else {
                        permissionData.push(permission[1])
                    }
                }
            });
            // console.log(permissionData, 'permissondata')
            commit("setModulePermissions", permissionData);
            return;
        }
    },
    mutations: {
        setToken (state, payload) {
            state.token = payload.token;
            state.userPermissionModel = payload.userPermissionModel;
            state.userP = payload.userPermissionModel;
            window.sessionStorage.setItem("token", payload.token);
            window.sessionStorage.setItem("expiry", payload.userPermissionModel.expiresOn);
            window.sessionStorage.setItem("permissions", JSON.stringify(payload.userPermissionModel));
        },
        removeToken (state) {
            state.token = null;
            state.userPermissionModel = null;
            sessionStorage.clear();
        },
        setModulePermissions (state, payload) {
            state.permissions = payload;
        }
    }
}

async function deleteToken ({VUE_APP_BASE_URL, LOGOUT_URL}, commit) {
    await httpClient.post(VUE_APP_BASE_URL + config.LOGOUT);
    commit("removeToken", LOGOUT_URL);
    window.location.href = LOGOUT_URL;
}

async function getToken ({VUE_APP_BASE_URL, LOGIN_URL}, dispatch) {
    try {
        const response = await httpClient.post(VUE_APP_BASE_URL + config.AUTHENTICATE);
        const {
            userAccessToken,
            userPermissionModel
        } = response.data;
        dispatch('setToken', {token: userAccessToken, userPermissionModel});
    } catch(err) {
        if (err.response.data.statusCode === 403) {
            window.location.href = 'unauthorized.html'
         } else {
            window.location.href = LOGIN_URL;
        }
    }
}