const request = function (url, options) {
    const token = "Bearer " + window.sessionStorage.getItem("token");
    const requestOptions = {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            "Authorization": token
        }
    };
    return fetch(url, Object.assign(requestOptions, options))
        .then(response => response.text(response))
        .then(data => (data ? JSON.parse(data) : data));
}

export default request;