export default function initialState() {
    return {
        completedTaskDetails: {
            __loading__: false,
            __error__: null
        },
        completedTaskWorkFlows:{
            __loading__: false,
            __error__: null
        },
        completedTaskWorkFlowSteps:{
            __loading__: false,
            __error__: null
        },
        filterWorkflow: {
            __loading__: false,
            __error__: null
        },
    };
}
