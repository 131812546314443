
import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';
import initialize from './initial-state';

export default {
    actions,
    getters,
    mutations,
    state: initialize()
};