import config from "@/config/config.api";
import { TASK_MUTATIONS } from './contants';
import axiosCall from "@/api/httpClient.js";
const httpClientCore = axiosCall(process.env.VUE_APP_BASE_URL);


export async function fetchCompletedTaskList({ commit }, {
   data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(TASK_MUTATIONS.SET_COMPLETEDTASK_LIST_IN_PROGRESS);
        }
        const response = await httpClientCore
            .post(config.COMPELETED_TASK_getcompletedTaskList,data)
        commit(TASK_MUTATIONS.SET_COMPLETEDTASK_LIST_COMPLETE, response.data);
    } catch (error) {
        console.log(error.response);
        commit(TASK_MUTATIONS.SET_COMPLETEDTASK_LIST_ERROR, error.response);
    }
}
export async function fetchCompletedWorkFlow({ commit }, { loadingProgress = false
 } = {}) {
     try {
         if (!loadingProgress) {
             commit(TASK_MUTATIONS.SET_COMPLETEDTASK_WORKFLOW_IN_PROGRESS);
         }
         const response = await httpClientCore
             .get(config.COMPELETED_TASK_getWorkFlows)
         commit(TASK_MUTATIONS.SET_COMPLETEDTASK_WORKFLOW_COMPLETE, response.data);
     } catch (error) {
         commit(TASK_MUTATIONS.SET_COMPLETEDTASK_WORKFLOW_ERROR, error.response.data.message);
     }
 }

 export async function fetchCompletedWorkFlowSteps({ commit }, { loadingProgress = false
 } = {}) {
     try {
         if (!loadingProgress) {
             commit(TASK_MUTATIONS.SET_COMPLETEDTASK_WORKFLOW_STEPS_IN_PROGRESS);
         }
         const response = await httpClientCore
             .get(config.COMPELETED_TASK_getWorkFlowsSteps)
         commit(TASK_MUTATIONS.SET_COMPLETEDTASK_WORKFLOW_STEPS_COMPLETE, response.data);
     } catch (error) {
         commit(TASK_MUTATIONS.SET_COMPLETEDTASK_WORKFLOW_STEPS_ERROR, error.response.data.message);
     }
 }


export async function fetchFilterWorkflowList({ commit }, {
    loadingProgress = false
} = {}) {
   try {
       if (!loadingProgress) {
           commit(TASK_MUTATIONS.SET_FILTER_WORKFLOW_IN_PROGRESS);
       }
       const response = await httpClientCore
           .get(config.ASSIGNED_TO_YOU_getWorkflowFilterList);
       commit(TASK_MUTATIONS.SET_FILTER_WORKFLOW_COMPLETE, response.data);
   } catch (error) {
       commit(TASK_MUTATIONS.SET_FILTER_WORKFLOW_ERROR, error.response.data.message);
   }
}

