export default function initialState() {
    return {
        documentManagement: {
            __loading__: false,
            __error__: null
        },
        downloadDocumentManagement: {
            __loading__: false,
            __error__: null
        },
        uploadDocumentManagement: {
            __loading__: false,
            __error__: null
        },
    };
}
