import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}

// partners list
export function setPartnersInProgress(state) {
    state.partners = {
        __loading__: true,
        __error__: null
    };
}
export function setPartners(state, partners) {
    state.partners = {
        partners,
        __loading__: false,
        __error__: null
    };
}
export function setPartnersFailure(state, error) {
    state.partners = {
        __loading__: false,
        __error__: error
    };
}


// delete partner 
export function setDeletePartnersInProgress(state) {
    state.deletePartners = {
        __loading__: true,
        __error__: null
    };
}
export function setDeletePartners(state, deletePartners) {
    state.deletePartners = {
        deletePartners,
        __loading__: false,
        __error__: null
    };
}
export function setDeletePartnersFailure(state, error) {
    state.deletePartners = {
        __loading__: false,
        __error__: error
    };
}


// Dropdown DeliveryTypes
export function setDropDownDeliveryTypesInProgress(state) {
    state.dropDownDeliveryTypes = {
        __loading__: true,
        __error__: null
    };
}
export function setDropDownDeliveryTypes(state, dropDownDeliveryTypes) {
    state.dropDownDeliveryTypes = {
        dropDownDeliveryTypes,
        __loading__: false,
        __error__: null
    };
}
export function setDropDownDeliveryTypesFailure(state, error) {
    state.dropDownDeliveryTypes = {
        __loading__: false,
        __error__: error
    };
}

// save Partner 
export function setSavePartnersInProgress(state) {
    state.savePartners = {
        __loading__: true,
        __error__: null
    };
}
export function setSavePartners(state, savePartners) {
    state.savePartners = {
        savePartners,
        __loading__: false,
        __error__: null
    };
}
export function setSavePartnersFailure(state, error) {
    state.savePartners = {
        __loading__: false,
        __error__: error
    };
}


// edit Partner 
export function setEditPartnersInProgress(state) {
    state.editPartners = {
        __loading__: true,
        __error__: null
    };
}
export function setEditPartners(state, editPartners) {
    state.editPartners = {
        editPartners,
        __loading__: false,
        __error__: null
    };
}
export function setEditPartnersFailure(state, error) {
    state.editPartners = {
        __loading__: false,
        __error__: error
    };
}

// update Partner
export function setUpdatePartnersInProgress(state) {
    state.updatePartners = {
        __loading__: true,
        __error__: null
    };
}
export function setUpdatePartners(state, updatePartners) {
    state.updatePartners = {
        updatePartners,
        __loading__: false,
        __error__: null
    };
}
export function setUpdatePartnersFailure(state, error) {
    state.updatePartners = {
        __loading__: false,
        __error__: error
    };
}


// Dropdown FTP Servers
export function setDropDownFTPServersInProgress(state) {
    state.dropDownFTPServers = {
        __loading__: true,
        __error__: null
    };
}
export function setDropDownFTPServers(state, dropDownFTPServers) {
    state.dropDownFTPServers = {
        dropDownFTPServers,
        __loading__: false,
        __error__: null
    };
}
export function setDropDownFTPServersFailure(state, error) {
    state.dropDownFTPServers = {
        __loading__: false,
        __error__: error
    };
}

