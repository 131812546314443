import axios from 'axios';

function axiosCall(baseUrl, respType) {
    const httpClient = axios.create({
        baseURL: baseUrl ? baseUrl : process.env.VUE_APP_BASE_URL_LOCAL,
        responseType: respType || 'json',
        //timeout: 1000, // indicates, 1000ms ie. 1 second
        withCredentials: true,
        headers: {
            "Content-Type": "application/json",
        }
    });

    const getAuthToken = () => "Bearer " + window.sessionStorage.getItem('token');
    if (getAuthToken() && getAuthToken() != "undefined") {
        const authInterceptor = (config) => {
            config.headers['Authorization'] = getAuthToken();
            return config;
        }
        httpClient.interceptors.request.use(authInterceptor);
        httpClient.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if(error.response.status === 401) {
                window.sessionStorage.clear();
                window.location.href = '/';
        }
            return Promise.reject(error);
        });
    }
    return httpClient;
}

export default axiosCall;