export const DATA_MODEL_ACTIONS = {
    FETCH_DATA_MODEL_DATAPOINT_LIST: 'fetchDataModelDataPointsList',
    FETCH_DATA_MODEL_LANGUAGES_LIST: 'fetchDataModelLanguagesList',
    FETCH_DATA_MODEL_DATA_TYPES_LIST: 'fetchDataModelDataTypesList',
    FETCH_DATA_MODEL_DATAPOINT_DETAILS: 'fetchDataModelDataPointDetails',
    FETCH_DATA_MODEL_AVAILABLE_GROUPS:'fetchDataModelAvailableGroups',
    FETCH_DATA_MODEL_DATAPOINT_FROM_SELECTED_GROUP:'fetchDataModelDataPointsFromSelectedGroup',
    FETCH_DATA_MODEL_ENUM_TYPES_LIST: 'fetchDataModelEnumTypesList',
    FETCH_DATA_MODEL_REFERENCE_TYPES_LIST: 'fetchDataModelReferenceTypesList',
    FETCH_DATA_MODEL_ENUM_VALUES_LIST: 'fetchDataModelEnumValuesList',
    FETCH_DATA_MODEL_REFERENCE_VALUES_LIST: 'fetchDataModelReferenceValuesList',
    FETCH_DATA_MODEL_VALIDATIONS_LIST: 'fetchDataModelValidationsList',
    SAVE_DATAPOINT: 'saveDataPoint',
    DELETE_DATAPOINT: 'deleteDataPoint',
    FETCH_DATAMODEL_DATAPOINTS_ON_SEARCH:'fetchDataModelDatapointsOnSearch'
};

export const DATA_MODEL_MUTTATIONS = {
    SET_DATA_MODEL_DATAPOINTS_LIST_IN_PROGRESS: 'setDataModelDatapointsListInProgress',
    SET_DATA_MODEL_DATAPOINTS_LIST_ERROR: 'setDataModelDatapointsListFailure',
    SET_DATA_MODEL_DATAPOINTS_LIST_COMPLETE: 'setDataModelDatapointsList',

    SET_DATA_MODEL_LANGUAGES_IN_PROGRESS: 'setDataModelLanguagesListInProgress',
    SET_DATA_MODEL_LANGUAGES_LIST_ERROR: 'setDataModelLanguagesListFailure',
    SET_DATA_MODEL_LANGUAGES_LIST_COMPLETE: 'setDataModelLanguagesList',

    SET_DATA_MODEL_DATA_TYPES_IN_PROGRESS: 'setDataModelDataTypesListInProgress',
    SET_DATA_MODEL_DATA_TYPES_LIST_ERROR: 'setDataModelDataTypesListFailure',
    SET_DATA_MODEL_DATA_TYPES_LIST_COMPLETE: 'setDataModelDataTypesList',

    SET_DATA_MODEL_DATAPOINT_DETAILS_IN_PROGRESS: 'setDataModelDatapointDetailsInProgress',
    SET_DATA_MODEL_DATAPOINT_DETAILS_ERROR: 'setDataModelDatapointDetailsFailure',
    SET_DATA_MODEL_DATAPOINT_DETAILS_COMPLETE: 'setDataModelDatapointDetails',

    SET_DATA_MODEL_AVAILABLE_GROUPS_DETAILS_IN_PROGRESS: 'setDataModelAvailableGroupsDetailsInProgress',
    SET_DATA_MODEL_AVAILABLE_GROUPS_DETAILS_ERROR: 'setDataModelAvailableGroupsDetailsFailure',
    SET_DATA_MODEL_AVAILABLE_GROUPS_DETAILS_COMPLETE: 'setDataModelAvailableGroupsDetails',

    SET_DATA_MODEL_DATAPOINTS_FROM_SELECTED_GROUP_DETAILS_IN_PROGRESS: 'setDataModelDataPointsFromSelectedGroupDetailsInProgress',
    SET_DATA_MODEL_DATAPOINTS_FROM_SELECTED_GROUP_DETAILS_ERROR: 'setDataModelDataPointsFromSelectedGroupFailure',
    SET_DATA_MODEL_DATAPOINTS_FROM_SELECTED_GROUP_DETAILS_COMPLETE: 'setDataModelDataPointsFromSelectedGroupDetails',
    
    SET_DATA_MODEL_ENUM_TYPES_IN_PROGRESS: 'setDataModelEnumTypesListInProgress',
    SET_DATA_MODEL_ENUM_TYPES_LIST_ERROR: 'setDataModelEnumTypesListFailure',
    SET_DATA_MODEL_ENUM_TYPES_LIST_COMPLETE: 'setDataModelEnumTypesList',

    SET_DATA_MODEL_REFERENCE_TYPES_IN_PROGRESS: 'setDataModelReferenceTypesListInProgress',
    SET_DATA_MODEL_REFERENCE_TYPES_LIST_ERROR: 'setDataModelReferenceTypesListFailure',
    SET_DATA_MODEL_REFERENCE_TYPES_LIST_COMPLETE: 'setDataModelReferenceTypesList',

    SET_DATA_MODEL_ENUM_VALUES_IN_PROGRESS: 'setDataModelEnumValuesListInProgress',
    SET_DATA_MODEL_ENUM_VALUES_LIST_ERROR: 'setDataModelEnumValuesListFailure',
    SET_DATA_MODEL_ENUM_VALUES_LIST_COMPLETE: 'setDataModelEnumValuesList',

    SET_DATA_MODEL_REFERENCE_VALUES_IN_PROGRESS: 'setDataModelReferenceValuesListInProgress',
    SET_DATA_MODEL_REFERENCE_VALUES_LIST_ERROR: 'setDataModelReferenceValuesListFailure',
    SET_DATA_MODEL_REFERENCE_VALUES_LIST_COMPLETE: 'setDataModelReferenceValuesList',

    SET_DATA_MODEL_VALIDATIONS_IN_PROGRESS: 'setDataModelValidationsListInProgress',
    SET_DATA_MODEL_VALIDATIONS_LIST_ERROR: 'setDataModelValidationsListFailure',
    SET_DATA_MODEL_VALIDATIONS_LIST_COMPLETE: 'setDataModelValidationsList',

    SET_SAVEDATAPOINTS_IN_PROGRESS: 'setDataModelSaveDataPointsInProgress',
    SET_SAVEDATAPOINTS_ERROR: 'setDataModelSaveDataPointsFailure',
    SET_SAVEDATAPOINTS_COMPLETE: 'setDataModelSaveDataPoints',

    SET_DELETEDATAPOINTS_IN_PROGRESS: 'setDataModelDeleteDataPointsInProgress',
    SET_DELETEDATAPOINTS_ERROR: 'setDataModelDeleteDataPointsFailure',
    SET_DELETEDATAPOINTS_COMPLETE: 'setDataModelDeleteDataPoints',

    SET_SEARCHDATAPOINTS_IN_PROGRESS: 'setDataModelSearchDataPointsInProgress',
    SET_SEARCHDATAPOINTS_ERROR: 'setDataModelSearchDataPointsFailure',
    SET_SEARCHDATAPOINTS_COMPLETE: 'setDataModelSearchDataPoints',
};
