export const ALERTS_ACTIONS = {
    FETCH_ALERT_LIST: 'fetchAlertList',
    FETCH_SAVE_ALERT: 'fetchSaveAlert',
    FETCH_EDIT_ALERT: 'fetchEditAlert',
    FETCH_DELETE_ALERT: 'fetchDeleteAlert',
    FETCH_UPDATE_ALERT: 'fetchUpdateAlert',
};

export const ALERTS_MUTATIONS = {
    SET_ALERTS_IN_PROGRESS: 'setAlertsInProgress',
    SET_ALERTS_ERROR: 'setAlertsFailure',
    SET_ALERTS_COMPLETE: 'setAlerts',

    SET_SAVE_ALERTS_IN_PROGRESS: 'setSaveAlertsInProgress',
    SET_SAVE_ALERTS_ERROR: 'setSaveAlertsFailure',
    SET_SAVE_ALERTS_COMPLETE: 'setSaveAlerts',

    SET_EDIT_ALERTS_IN_PROGRESS: 'setEditAlertsInProgress',
    SET_EDIT_ALERTS_ERROR: 'setEditAlertsFailure',
    SET_EDIT_ALERTS_COMPLETE: 'setEditAlerts',

    SET_DELETE_ALERTS_IN_PROGRESS: 'setDeleteAlertsInProgress',
    SET_DELETE_ALERTS_ERROR: 'setDeleteAlertsFailure',
    SET_DELETE_ALERTS_COMPLETE: 'setDeleteAlerts',

    SET_UPDATE_ALERTS_IN_PROGRESS: 'setUpdateAlertsInProgress',
    SET_UPDATE_ALERTS_ERROR: 'setUpdateAlertsFailure',
    SET_UPDATE_ALERTS_COMPLETE: 'setUpdateAlerts',
};