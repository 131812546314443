import axiosCall from '../api/httpClient.js';
import config from '../config/config.api';
const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);
const httpClientFileDownload = axiosCall(process.env.VUE_APP_BASE_URL, 'blob');

const getImportHistory = (pageNumber, pageSize, orderby, isascending) => httpClient.get(config.Bulk_Edit_getImportHistory, {
  params: {
    page: pageNumber,
    pageSize: pageSize,
    orderBy: orderby,
    isAscending: isascending
  }
});

const getFile = (fileId) => httpClientFileDownload.get(config.Bulk_Edit_getFile, {
  params: {
    fileId: fileId
  }
});

const getListOfTemplates = (showCustomTemplate) => httpClient.get(config.Bulk_Edit_getListOFTemplates, {
  params: {
    showCustomTemplate: showCustomTemplate
  }
});

const getTemplate = (templateId) => httpClientFileDownload.get(config.Bulk_Edit_getTemplate, {
  params: {
    templateId: templateId
  }
});

// data points modal
const getDatapoints = (entityTypeId) => httpClient.get(config.Bulk_Edit_getDatapoints, {
  params: {
    entityTypeId: entityTypeId
  }
});
// Get Datapoints
async function getDatapointValues(data) {
  return await httpClient.post(config.Bulk_Edit_datapoint_values, data).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true
    return error.response
  })
}

async function getEntityListData(pageNumber, pageSize, orderby, isascending,params) {
  return await httpClient.get(config.Bulk_Edit_getEntityList, {
    params: {
      entityId: params.entityId,
      page: pageNumber,
      pageSize: pageSize,
      orderBy: "EntityName",
      isAscending: isascending,
      entityTypeId:params.entityTypeId,
      search:params.search,
      status:params.status
    }
  }).then((response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true
    return error.response
  })
}
async function getChildEntityListData(params) {
  return await httpClient.get(config.Bulk_Edit_getEntityList, {
    params: {
      entityId: params.entityId,
      status:params.status
    }
  }).then((response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true
    return error.response
  })
}
// Download Template
async function BulkEditDownloadTemplate(data) {
  return await httpClientFileDownload.post(config.Bulk_Edit_downloadCustomTemplate,data).then(async (response) => {
    response["isError"] = false
    if(response.data !== undefined) {
      const url = window.URL.createObjectURL(new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }))
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', "CustomTemplate");
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
    }
    return response
  }).catch(async error => {
    error.response["isError"] = true
    
    return converBlobErrorToText(error)
  })
}
async function converBlobErrorToText(error){
  let resBlob = error.response.data 
    let resData = null

    let resText = await new Promise((resolve, reject) => {
      let reader = new FileReader()
      reader.addEventListener('abort', reject)
      reader.addEventListener('error', reject)
      reader.addEventListener('loadend', () => {
        resolve(reader.result)
      })
      reader.readAsText(resBlob)
    })
    resData = JSON.parse(resText)
    error.response.data = resData
    return error.response
}
// Save Template 
async function BulkEditSaveTemplate(data,params) {
  return await httpClient.post(config.Bulk_Edit_saveTemplate,data,{params: {overWriteIfExists:params.overWriteIfExists}}).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => { 
    error.response["isError"] = true
    return error.response
  })
}

// IMPORT DATA
async function BulkEditImport(data) {
  return await httpClient.post(config.Bulk_Edit_importData, data).then(async (response) => {
    response["isError"] = false
    return response
  }).catch(error => {
    error.response["isError"] = true
    return error.response
  })
}

async function groupBy(array, key,groupType){
  console.log(groupType); 
 const result = {}
 var ObjectToArrayResult = []
 var finalList=[]
 array.forEach(item => {
   if (!result[item[key]]){
     result[item[key]] = []
   }
   result[item[key]].push(item)
 })
 ObjectToArrayResult = await Object.keys(result).map((key) => result[key]);
 await ObjectToArrayResult.forEach(element => {
   if(element.length > 1){
     let values=[]
     let oldValues=[]
     for(let el=0;el<element.length;el++){
       if(element[el].datapointvalue){
         values.push(element[el].datapointvalue)
         oldValues.push(element[el].datapointvalue)
       }
     }
     element[0]["values"]=values
     element[0]["oldValues"]=oldValues
     finalList.push(element[0])
   }else if(element[0].datatypeenum == 7 || element[0].enumid != null){
     if(element[0].arraylength != 1){
       if(element[0].datapointvalue != null){
          let singleValue = []
            singleValue.push(element[0].datapointvalue)
             element[0]["values"]=singleValue
         let singleOldValue =[]
         singleOldValue.push(element[0].datapointvalue)
         element[0]["oldValues"]=singleOldValue
       }else{
         element[0]["values"] = null
         element[0]["oldValues"]=null
       }
        
         finalList.push(element[0])

         }else{
            finalList.push(element[0])
            }
   }
   else{
     finalList.push(element[0])
   }
 })
 
 return finalList
}
export {
  getImportHistory,
  getFile,
  getListOfTemplates,
  getTemplate,
  getDatapoints,
  getEntityListData,
  BulkEditSaveTemplate,
  getDatapointValues,
  groupBy,
  BulkEditImport,
  BulkEditDownloadTemplate,
  getChildEntityListData
}
