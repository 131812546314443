import Vue from 'vue';
import Vuex from 'vuex';
import entity from './modules/entity.stor.js';
import assignedToYou from './modules/assigned_to_you.stor.js';
import auth from './modules/auth.store.js';
import bulkEdit from './modules/bulk_edit.store.js';
import config from './modules/config.store.js';
import languages from './modules/language.stor';
import bulkOperations from './modules/bulk_operations.stor';
import dropDowns from './modules/drop_downs.stor';
import reporting from './modules/reporting.store.js';
import cache from './modules/cache/index';
// import shared from './modules/shared';
import reportingModule from './modules/reporting';
import dataDissemination from './modules/dataDissemination';
import schedule from './modules/schedule'
import partner from './modules/partner'
import alerts from './modules/alerts'
import tasks from './modules/tasks'
import editExternalCodes from './modules/editExternalCodes'
import userManagement from './modules/userManagement';
import documentManagement from './modules/documentManagement';
import dashboard from './modules/dashboard';
import activityHistory from './modules/activityHistory';
import dataModel from './modules/dataModel';
import { CACHE_ACTIONS } from './modules/cache/constants';
import vuexCache from './lib/vuex-cache';
Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
   modules: {
    entity,
    assignedToYou,
    auth,
    bulkEdit,
    config,
    languages,
    bulkOperations,
    dropDowns,
    dashboard,
    reporting,
    cache,
   //  shared,
    reportingModule,
    dataDissemination,
    schedule,
    partner,
    alerts,
    tasks,
    editExternalCodes,
    userManagement,
    documentManagement,
    activityHistory,
    dataModel,
    namespaced: true,
   },
   // strict: debug,
   plugins: [vuexCache]
});

store.dispatch(CACHE_ACTIONS.PERSIST_STORE_CACHE, store.cache);

export default store;