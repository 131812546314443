import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { EXTERNAL_CODES_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

export async function updateExternalCodes({ commit }, {
   data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(EXTERNAL_CODES_MUTATIONS.SET_UPDATE_EXTERNAL_CODES_IN_PROGRESS);
        }
        const response = await httpClient
            .post(config.EXTERNAL_CODES_getAndUpdate,data)
        commit(EXTERNAL_CODES_MUTATIONS.SET_UPDATE_EXTERNAL_CODES_COMPLETE, response.data);
    } catch (error) {
        commit(EXTERNAL_CODES_MUTATIONS.SET_UPDATE_EXTERNAL_CODES_ERROR, error.response);
    }
}
export async function fetchExternalCodesList({ commit }, {entityId, loadingProgress = false
 } = {}) {
     try {
         if (!loadingProgress) {
             commit(EXTERNAL_CODES_MUTATIONS.SET_EXTERNAL_CODES_LIST_IN_PROGRESS);
         }
         const response = await httpClient
             .get(config.EXTERNAL_CODES_getAndUpdate,{
                params: {
                    entityId:entityId,
                },

            })
         commit(EXTERNAL_CODES_MUTATIONS.SET_EXTERNAL_CODES_LIST_COMPLETE, response.data);
     } catch (error) {
         commit(EXTERNAL_CODES_MUTATIONS.SET_EXTERNAL_CODES_LIST_ERROR, error.response.data.message);
     }
 }

//  
//  

