
import axiosCall from '../api/httpClient';
import config from '../config/config.api';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);

async function checkUnmatchedItems (data){
    return await httpClient.post(config.Bulk_Upload_checkUnmatachedItems,data).then(async (response)=>{
        response["isError"]=false
        return response
    }).catch(error=>{
       error.response["isError"]=true
      return error.response
    })
  } 

  async function getExistingTemplates(showall){
    return await httpClient.get(config.Bulk_Upload_getTemplates,{ params:{
      showall:showall,
  }
}).then(async (response)=>{
      response["isError"]=false
      return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
  }
  async function getHeaders(data){
    return await httpClient.get(config.Bulk_Upload_getHeaders,{ params:{
      fileType :data.fileType,
      entitytypeId:data.entityTypeId
  }
}).then(async (response)=>{
      response["isError"]=false
      return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
  }
  async function importBulkData(data){
    return await httpClient.post(config.Bulk_Upload_importData,data).then(async (response)=>{
      response["isError"]=false
      return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
  }
  async function getStagingDetails(data){
    return await httpClient.get(config.Bulk_Upload_staging,{ 
      params:{
      stagingId:data.stagingId 
  }
}).then(async (response)=>{
      response["isError"]=false
      return response
  }).catch(error=>{
     error.response["isError"]=true
    return error.response
  })
  }
export {
    checkUnmatchedItems,
    getExistingTemplates,
    getHeaders,
    importBulkData,
    getStagingDetails
}