export const EXTERNAL_CODES_ACTIONS = {
    FETCH_EXTERNAL_CODES_LIST: 'fetchExternalCodesList',
    UPDATE_EXTERNAL_CODES:'updateExternalCodes',
};

export const EXTERNAL_CODES_MUTATIONS = {
    SET_EXTERNAL_CODES_LIST_IN_PROGRESS: 'setExternalCodesListInProgress',
    SET_EXTERNAL_CODES_LIST_ERROR: 'setExternalCodesListFailure',
    SET_EXTERNAL_CODES_LIST_COMPLETE: 'setExternalCodesList',
    SET_UPDATE_EXTERNAL_CODES_IN_PROGRESS: 'setupdateExternalCodesInProgress',
    SET_UPDATE_EXTERNAL_CODES_ERROR: 'setupdateExternalCodesFailure',
    SET_UPDATE_EXTERNAL_CODES_COMPLETE: 'setupdateExternalCodes',
};
