import initialize from './initial-state';

export function sharedReset(state) { // eslint-disable-line no-unused-vars
    Object.assign(state, initialize());
}

// Alert List
export function setAlertsInProgress(state) {
    state.alerts = {
        __loading__: true,
        __error__: null
    };
}
export function setAlerts(state, alerts) {
    state.alerts = {
        alerts,
        __loading__: false,
        __error__: null
    };
}
export function setAlertsFailure(state, error) {
    state.alerts = {
        __loading__: false,
        __error__: error
    };
}

// Save Alert
export function setSaveAlertsInProgress(state) {
    state.saveAlerts = {
        __loading__: true,
        __error__: null
    };
}
export function setSaveAlerts(state, saveAlerts) {
    state.saveAlerts = {
        saveAlerts,
        __loading__: false,
        __error__: null
    };
}
export function setSaveAlertsFailure(state, error) {
    state.saveAlerts = {
        __loading__: false,
        __error__: error
    };
}

// Edit Alert
export function setEditAlertsInProgress(state) {
    state.editAlerts = {
        __loading__: true,
        __error__: null
    };
}
export function setEditAlerts(state, editAlerts) {
    state.editAlerts = {
        editAlerts,
        __loading__: false,
        __error__: null
    };
}
export function setEditAlertsFailure(state, error) {
    state.editAlerts = {
        __loading__: false,
        __error__: error
    };
}

// Delete Alert
export function setDeleteAlertsInProgress(state) {
    state.deleteAlerts = {
        __loading__: true,
        __error__: null
    };
}
export function setDeleteAlerts(state, deleteAlerts) {
    state.deleteAlerts = {
        deleteAlerts,
        __loading__: false,
        __error__: null
    };
}
export function setDeleteAlertsFailure(state, error) {
    state.deleteAlerts = {
        __loading__: false,
        __error__: error
    };
}

// update alert
export function setUpdateAlertsInProgress(state) {
    state.updateAlerts = {
        __loading__: true,
        __error__: null
    };
}
export function setUpdateAlerts(state, updateAlerts) {
    state.updateAlerts = {
        updateAlerts,
        __loading__: false,
        __error__: null
    };
}
export function setUpdateAlertsFailure(state, error) {
    state.updateAlerts = {
        __loading__: false,
        __error__: error
    };
}


