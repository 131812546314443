import {checkUnmatchedItems,getExistingTemplates,getHeaders,importBulkData,getStagingDetails} from '../../services/bulk_operations_service.js';
const namespaced= true
const state = {
    unmatchedItems:[],
    existingTemplates:[],
    headerList:[],
    importedData:[],
    stagingData:null
}

const getters = {
  getUnmatchedItemDetails(state){
      return state.unmatchedItems;
  },
  getExistingTemplatesDetails(state){
      return state.existingTemplates;
  },
  getHeadersListDetails(state){
      return state.headerList
  },
  getImportedData(state){
    return state.importedData
},
getStagingData(state){
    return state.stagingData
}
}

const mutations = {
  setUnmatchedItemDetails(state,data){
      state.unmatchedItems = data
  },
  setExistingTemplateDetails(state,data){
      state.existingTemplates = data
  },
  setHeaderListDetails(state,data){
      state.headerList=data
  },
  setImportedData(state,data){
    state.importedData=data
},
setStagingData(state,data){
    state.stagingData=data
}
}

const actions = {
  async fetchUnmatchedItemDetails(context,data){
      try{
          let response = await checkUnmatchedItems(data)
          context.commit('setUnmatchedItemDetails', response);
      }catch(error){
          console.log(error);
      }
  },
  async fetchExistingTemplates(context,data){
    try{
        let response = await getExistingTemplates(data)
        context.commit('setExistingTemplateDetails', response);
    }catch(error){
        console.log(error);
    }
  },
  async fetchHederList({commit},data){
    try{
        let response = await getHeaders(data)
        commit('setHeaderListDetails', response);
    }catch(error){
        console.log(error);
    }
  },
  async importFileData({commit},{data,screenName}){
    try{
        let response
        // calling the api based on the screen name
        if(screenName == "RegMatrix"){
            console.log("***********REG MATRIX***********");
            // response = await importRegistraionMatrixData(data)
        }else{
         response = await importBulkData(data)
        }
        commit('setImportedData', response);
    }catch(error){
        console.log(error);
    }
  },
  async importBulkUploadData({commit},data){
    try{
        let response = await importBulkData(data)
        commit('setImportedData', response);
    }catch(error){
        console.log(error);
    }
  },
  async fetchStagingDetails({commit},data){
      try{
          let response = await getStagingDetails(data)
          commit('setStagingData', response);
      }catch(error){
        console.log(error);
    }

  }
}

export default {
  namespace:'bulkOperations',
  namespaced,
  state,
  getters,
  mutations,
  actions
}