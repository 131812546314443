export default function initialState() {
    return {
        filterPartners: {
            __loading__: false,
            __error__: null
        },
        filterDeliveryMethods: {
            __loading__: false,
            __error__: null
        },
        dropDownDeliveryMethods: {
            __loading__: false,
            __error__: null
        },
        schedules: {
            __loading__: false,
            __error__: null
        },
        scheduleFiles: {
            __loading__: false,
            __error__: null
        },
        editSchedules: {
            __loading__: false,
            __error__: null
        },
        dropDownDataFeeds: {
            __loading__: false,
            __error__: null
        },
        enableSchedules: {
            __loading__: false,
            __error__: null
        },
        saveSchedules: {
            __loading__: false,
            __error__: null
        },
        deleteSchedules: {
            __loading__: false,
            __error__: null
        },
        runSchedule: {
            __loading__: false,
            __error__: null
        },
        downloadSchedule: {
            __loading__: false,
            __error__: null
        },
    };
}
