import axiosCall from "@/api/httpClient.js";
import config from "@/config/config.api";
import { DASHBOARD_MUTATIONS } from './contants';

const httpClient = axiosCall(process.env.VUE_APP_BASE_URL);


export async function fetchFundUniverseMatrixList({ commit }, {
    data, loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DASHBOARD_MUTATIONS.SET_FUND_UNIVERSE_MATRIX_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DASHBOARD_fundUniverseMatrix, {
                params: {
                    homeStatsType : data,
                },
            });
        commit(DASHBOARD_MUTATIONS.SET_FUND_UNIVERSE_MATRIX_COMPLETE, response.data.result);
    } catch (error) {
        commit(DASHBOARD_MUTATIONS.SET_FUND_UNIVERSE_MATRIX_ERROR, error.response.data.message);
    }
}

export async function fetchWorkflowHealthList({ commit }, {
     loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DASHBOARD_MUTATIONS.SET_WORKFLOW_HEALTH_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DASHBOARD_workflowHealth);
        commit(DASHBOARD_MUTATIONS.SET_WORKFLOW_HEALTH_COMPLETE, response.data);
    } catch (error) {
        commit(DASHBOARD_MUTATIONS.SET_WORKFLOW_HEALTH_ERROR, error.response.data.message);
    }
}

export async function fetchEventList({ commit }, {
     loadingProgress = false
} = {}) {
    try {
        if (!loadingProgress) {
            commit(DASHBOARD_MUTATIONS.SET_EVENT_IN_PROGRESS);
        }
        const response = await httpClient
            .get(config.DASHBOARD_event);
        commit(DASHBOARD_MUTATIONS.SET_EVENT_COMPLETE, response.data.result);
    } catch (error) {
        commit(DASHBOARD_MUTATIONS.SET_EVENT_ERROR, error.response.data.message);
    }
}
