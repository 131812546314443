// import items from '../../assignedItems.json';
import { getLanguagesDetails } from '../../services/add_investment_service.js';
import { getLanguage } from '../../services/get_default_values.service.js';
const state = {
  languages:[], 
  selectedLanguage:'',
  languageCode:'',
  languageCodeText:''
}

const getters = {
  getLanguages(languageState) {
    return languageState.languages;
  },
  getSelectedLanguage(selectedLanguage){
    return selectedLanguage.selectedLanguage;
  },
  getLanguageCode(state){
    return state.languageCode
  },
  getLanguageCodeText(state){
    return state.languageCodeText
  }
}

const mutations = {
  setLanguages(languageState, data) {
    languageState.languages = data;
  },
  setSelectedLanguage(selectedLanguage,data){
    selectedLanguage.selectedLanguage = data;
  },
  setLanguageCode(state,data){
    state.languageCode=data
  },
  setLanguageCodeText(state,data){
    state.languageCodeText=data
  }
}

const actions = {
    async fetchLanguageDetails({commit}){
        try{
            const response = await getLanguagesDetails();
            commit('setLanguages',response.data)
        }catch(error){
            //handle the error
        }
    },
    async fetchLanguageCodeText({commit},code){
      try{
        const response = await getLanguage(code);
        commit('setLanguageCodeText',response.data)
        return response
    }catch(error){
        //handle the error
    }
    }
}

export default {
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}